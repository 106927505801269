import ApplicationStateInjector from 'core/application-state-injector';
import { GroupsApi } from 'core/api/groups';
import { CategoriesApi } from 'core/api/categories';
import { PermissionsApi } from 'core/api/permissions';

export class PermissionService extends ApplicationStateInjector {
  constructor() {
    super();
    this.groupsApi = new GroupsApi(this.system);
    this.categoriesApi = new CategoriesApi(this.system);
    this.permissionApi = new PermissionsApi(this.system);
  }

  fetchAllPermissions() {
    return this.permissionApi.fetchAll();
  }

  fetchAllGroups() {
    return this.groupsApi.fetchAll();
  }

  fetchOneGroup(groupId) {
    return this.groupsApi.fetchOne(groupId);
  }

  createGroup(group) {
    return this.groupsApi.createOne(group);
  }

  updateGroup(group) {
    return this.groupsApi.updateOne(group);
  }

  deleteGroup(groupId) {
    return this.groupsApi.deleteOne(groupId);
  }

  fetchCategories() {
    return this.categoriesApi.fetchCategories();
  }

  editCategorySettings(categorySettings) {
    return this.categoriesApi.editCategorySettings(categorySettings);
  }

  createCategory(category) {
    return this.categoriesApi.createCategory(category);
  }

  updateCategory(category) {
    return this.categoriesApi.updateCategory(category);
  }

  deleteCategory(id) {
    return this.categoriesApi.deleteCategory(id);
  }
}
