import ApplicationStateInjector from 'core/application-state-injector';
import { AuthApi } from 'core/api/auth';

export class AuthService extends ApplicationStateInjector {
  constructor() {
    super();
    this.authApi = new AuthApi(this.system);
  }

  login(userData) {
    return this.authApi.login(userData);
  }

  loginCompany(companyId) {
    return this.authApi.loginCompany(companyId);
  }

  sendResetEmail(email) {
    return this.authApi.sendResetEmail(email);
  }

  resetPassword(newPassword, code) {
    return this.authApi.resetPassword(newPassword, code);
  }

  logout() {
    return this.authApi.logout();
  }
}
