import React, { useEffect } from 'react';
import SearchBar from '../../components/Searchbar';
import Table from 'components/Tables/Home';
import { Filters } from 'components/Filters';
import {
  CardBody,
  CardContainer,
  CardHeader,
  CardText,
  TopTable,
  Separator,
  SubTitle,
} from './style';
import { AiOutlinePlus } from 'react-icons/ai';
import { Flex } from 'components/Flex';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { Routes } from 'common/enums/routes.enum';
import { useReduxState } from 'common/hooks/use-redux-state';
import { Modal } from 'antd';
import { getDocuments } from 'common/redux/documents/selectors';
import {
  getShowTutorial,
  getUserIsOperator,
} from 'common/redux/auth/selectors';
import { disableShowTutorial } from 'common/redux/auth/reducers';
import { useTour } from '@reactour/tour';
import steps from 'common/joyride/steps';
import ThemedButton from 'components/Themed/ThemedButton';
import { useWindowSize } from 'common/hooks/use-window-size';
import {
  getStorageUsage,
  getTotalDocuments,
} from 'common/redux/info/selectors';
import { getTemporalityDocuments } from 'components/Tables/Temporality';
import { TEMPORALITY } from 'components/Tables/Temporality/columns';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSearch } from 'common/redux/documents/thunks';

const Home = () => {
  const { isMobileScreen } = useWindowSize();
  const history = useHistory();
  const documents = useReduxState(getDocuments);
  const totalDocuments = useReduxState(getTotalDocuments);
  const userIsOperator = useReduxState(getUserIsOperator);
  const showTutorial = useReduxState(getShowTutorial);
  const storageUsage = useReduxState(getStorageUsage);
  const searchDocuments = useReduxAction(fetchSearch);
  const disableTutorial = useReduxAction(disableShowTutorial);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();

  const startTour = () => {
    setIsOpen(true);
    setCurrentStep(0);
    setSteps(steps);
  };
  useEffect(() => {
    searchDocuments();
  }, []);

  useEffect(() => {
    function info() {
      Modal.info({
        title:
          'Olá! Seja muito bem-vindo ao Tâmia - Sistema de gestão inteligente!',
        okText: 'Começar o Tour',
        cancelText: 'Cancelar',
        content: (
          <div style={{ height: '4rem', fontWeight: '500', marginTop: '30px' }}>
            <p>
              Vamos fazer um breve Tour pelo Tâmia que irá te ajudar com as
              configurações básicas
            </p>
          </div>
        ),
        onOk() {
          startTour();
        },
      });
    }

    if (showTutorial) {
      info();
      disableTutorial();
    }
  }, []);

  return (
    <>
      <SubTitle>Painel de Informações</SubTitle>
      <CardContainer>
        <>
          <CardBody>
            <CardHeader bg='#68971C'>{totalDocuments || '-'}</CardHeader>
            <CardText>Documentos totais no sistema</CardText>
          </CardBody>
          <CardBody>
            <CardHeader bg='#68a8fc'>
              {isNaN(storageUsage) ? 0 : storageUsage}%
            </CardHeader>
            <CardText>Espaço utilizado</CardText>
          </CardBody>
          <CardBody>
            <CardHeader bg='#f18447'>
              {
                getTemporalityDocuments(documents, TEMPORALITY.DIGITAL, false)
                  ?.length
              }
            </CardHeader>
            <CardText>
              Documentos eletrônicos <br />
              vencidos
            </CardText>
          </CardBody>
          <CardBody>
            <CardHeader bg='#F44646'>
              {
                getTemporalityDocuments(documents, TEMPORALITY.PHYSICAL, false)
                  ?.length
              }
            </CardHeader>
            <CardText>
              Documentos físicos <br />
              vencidos
            </CardText>
          </CardBody>
        </>
      </CardContainer>
      <Separator />
      <SubTitle>Pesquisa</SubTitle>
      <Flex justify='center' align='center' style={{ marginTop: '30px' }}>
        <SearchBar />
        <Filters filterKey={'advancedFilters'}/>
      </Flex>
      <div data-tour='home'>
        <TopTable>
          <p style={{ marginLeft: '17px' }}>
            Documentos encontrados: {documents.length}{' '}
          </p>
          <ThemedButton
            color='#ccc'
            style={{
              boxShadow: '1px 1px #ddd',
              margin: isMobileScreen ? '0 50px 15px 0' : '0 75px 15px 0',
              width: isMobileScreen ? '3.5rem' : '12rem',
            }}
            onClick={() => history.push(Routes.DOCUMENTS)}
            disabled={userIsOperator}
          >
            <AiOutlinePlus
              style={{
                marginRight: isMobileScreen ? 0 : 4,
                color: '#21211a',
              }}
              size={18}
            />
            {isMobileScreen ? '' : 'Novo documento'}
          </ThemedButton>
        </TopTable>
        <Flex justify='center' style={{ width: '97%', margin: 'auto' }}>
          <Table />
        </Flex>
      </div>
    </>
  );
};

export default Home;
