import React from 'react';
import ModalShowDocument from '../../Modals/ModalViewDocuments/index';
import { MdModeEdit } from 'react-icons/md';
import { ButtonGroup } from '../style';
import { Flex } from 'components/Flex';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { Circle } from './styles';

const toLocalDate = (date) => {
  if (!date) return '';
  const formated = new Date(date).toLocaleDateString('pt-BR');

  if (formated == 'Invalid Date') return '';
  return formated;
};

export const TEMPORALITY = {
  PHYSICAL: 'physical_discard_date',
  DIGITAL: 'digital_discard_date',
};

export const columns = (categoryNames, temporalityKey) => {
  const categoryColumns = categoryNames.map((category, idx) => ({
    title: category,
    align: 'left',
    key: category,
    render: (_, record) => <span>{record.categories?.[idx]?.name}</span>,
    width: 145,
    ellipsis: true,
  }));

  return [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'center',
    },
    {
      width: 70,
      align: 'center',
      title: 'Farol',
      dataIndex: temporalityKey,
      key: temporalityKey,
      render: (_, record) => {
        return <Circle bg={record.temporalityColor} />;
      },
    },

    {
      width: 118,
      align: 'left',
      title: 'Data de eliminação',
      dataIndex: temporalityKey,
      key: temporalityKey,
      key: 'documentStart',
      render: (text) => <span> {toLocalDate(text)}</span>,
    },
    ...categoryColumns,
    {
      title: 'Descrição',
      align: 'left',
      dataIndex: 'description',
      key: 'description',
      width: 215,
      ellipsis: true,
    },
    {
      width: 100,
      fixed: 'right',
      title: (
        <Flex justify='center' align='center'>
          Ação
          <ThemedTooltip
            placement='topRight'
            title='Exportar a lista para uma planilha'
          ></ThemedTooltip>
        </Flex>
      ),
      key: 'action',
      render: (_, record) => {
        return (
          <ButtonGroup justify='center'>
            <ModalShowDocument
              document={record}
              editDocument={true}
              Icon={MdModeEdit}
            />
          </ButtonGroup>
        );
      },
    },
  ];
};
