import React, { useState } from 'react';
import { Drawer, Button, Row } from 'antd';
import { ButtonSearchDetail, IconSearchDetail } from './style';
import { formsName } from 'common/enums/forms.enum';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { clearFilter } from 'common/redux/filters/reducers';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { useWindowSize } from 'common/hooks/use-window-size';
import ThemedButton from 'components/Themed/ThemedButton';
import { Flex } from 'components/Flex';
import { AdvancedSearch } from './advancedSearch';
import { AuditSearch } from './auditSearch';

const getComponent = (key) => {
  switch (key) {
    case 'auditFilters':
      return { component: <AuditSearch />, form: formsName.AUDIT_SEARCH_FORM };
    case 'advancedFilters':
      return {
        component: <AdvancedSearch />,
        form: formsName.ADVANCED_SEARCH_FORM,
      };
    default:
      return <AdvancedSearch />;
  }
};

export const Filters = ({ filterKey }) => {
  const clearForm = useReduxAction(clearFilter);
  const { isMobileScreen } = useWindowSize();
  const [isVisible, setVisible] = useState(false);
  const { component, form } = getComponent(filterKey);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onClear = () => {
    clearForm({ key: filterKey });
  };
  return (
    <Row style={{ alignItems: 'center' }}>
      <ThemedTooltip
        placement='top'
        title='Aqui você encontra filtros para refinar sua pesquisa'
      >
        <ButtonSearchDetail
          id='button-adv-filter'
          size={24}
          onClick={showDrawer}
        >
          <IconSearchDetail />
          <span>{isMobileScreen ? '' : 'Busca avançada'}</span>
        </ButtonSearchDetail>
      </ThemedTooltip>
      <Drawer
        title='Busca avançada'
        width={isMobileScreen ? '100vw' : '30rem'}
        onClose={onClose}
        open={isVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Flex justify='end'>
              <ThemedButton
                color='#f0fcd6'
                style={{ borderColor: '#9cdb37', marginRight: 8 }}
                onClick={onClear}
              >
                Limpar
              </ThemedButton>
              <ThemedButton
                color='#9cdb37'
                form={form}
                key='submit'
                htmlType='submit'
                onClick={onClose}
              >
                Filtrar
              </ThemedButton>
            </Flex>
          </div>
        }
      >
        {component}
      </Drawer>
    </Row>
  );
};
