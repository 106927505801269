import { createSlice } from '@reduxjs/toolkit';
import { fetchPlans } from './thunks';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    plans: [],
    hiredPlan: {},
    loading: false,
    error: null,
  },
  reducers: {
    updatePaymentInfo(state, action) {
      state.plans = action.payload.plans;
      state.hiredPlan = action.payload.hiredPlan;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plans = action.payload.plans;
        state.hiredPlan = action.payload.hiredPlan;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const paymentReducer = paymentSlice.reducer;
export const paymentActions = paymentSlice.actions;
