const s = (state) => state.filters;

export const getSearchAdvancedFilters = (state) =>
  s(state).advancedFilters || null;

export const getAuditFilters = (state) => s(state).auditFilters || null;

export const getSearchGlobalFilter = (state) => s(state).globalFilter || null;

export const getFiltersAmount = (filterKey) => (state) => {
  return Object.keys(s(state)[filterKey] || {}).filter(
    (key) => s(state)[filterKey][key] !== undefined
  ).length;
};
