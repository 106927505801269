import styled from 'styled-components';
import { Flex } from 'components/Flex';

export const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #242424;
  min-height: 100vh;
  overflow: hidden;

  h2 {
    color: white;
    font-size: 28px;
    margin-bottom: 0;
  }
  .ant-btn > span {
    color: #212121;
    font-weight: 600;
  }

  .custom-form {
    flex-direction: column;
    padding: 0 10rem;
    @media only screen and (max-width: 768px) {
      margin-bottom: 10rem;
      padding: 0 5rem;
    }
  }
`;
export const InputsContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #3a3a3a;
  flex-direction: column;
  padding: 60px 50px;
  width: 25rem;
`;
export const InputContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 30px;
  padding: 0 0.9rem;

  input {
    line-height: 1;
    font-weight: 500;
    font-size: 1rem;
    color: #212121;

    ::placeholder {
      font-size: 1rem;
    }
  }
`;

export const RecoverText = styled.a`
  margin: 0 auto 20px 10px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;

  &&:hover {
    transition: 0.2s;
    color: #f0f0f0;
  }
`;
export const RegisterText = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;

  &&:hover {
    transition: 0.2s;
    color: #f0f0f0;
  }
`;

export const Logo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  padding: 8px 20px 40px 20px;
`;
