import { BaseApi } from 'core/api';

export class GroupsApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`groups`);
  }
  async fetchAll() {
    return this.get();
  }

  async fetchOne(groupId) {
    this.setUrl(`${this.url}/${groupId}`);
    return this.get();
  }

  async createOne(group) {
    return this.post(group);
  }

  async updateOne(group) {
    return this.put(group);
  }

  async deleteOne(groupId) {
    this.setUrl(`${this.url}/${groupId}`);
    return this.delete();
  }
}
