import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f6faf5;
`;

export const Title = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 10px 1px 2px 1px;
  color: #21211a;
  font-weight: 700;
  font-size: 1.2rem;
`;
export const Separator = styled.div`
  width: 75%;
  border-bottom: 2px solid #9cdb37;
  margin-bottom: 10px;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentTable = styled.div`
  table,
  td,
  th {
    border-bottom: 1px solid #ddd;
  }
  #td-left {
    color: #696969;
    text-align: left;
    padding-left: 7px;
    font-weight: 500;
    width: 50%;
  }
  #td-right {
    font-weight: 700;
    text-align: left;

    white-space: nowrap;
    max-width: 28ch;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }

  table {
    border-collapse: collapse;
    width: 75%;
  }

  th,
  td {
    padding: 10px;
  }
  tr:hover {
    transition: 0.2s;
    background-color: #f0fcd6;
  }

  #first-table {
    margin-bottom: 3rem;
  }
`;
