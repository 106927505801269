import { ThemedSelect } from 'components/Themed/ThemedSelect';
import { useState } from 'react';
import styled from 'styled-components';

const DropdownItem = styled.p`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 3px;
  :hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
  p {
    color: #ff4800;
    margin: 0 4px 0 0;
    font-weight: 500;
  }
`;

export const TagsSelect = ({ value, onChange, ...rest }) => {
  const [text, setText] = useState('');
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (tags) => {
    if (!tags && !text) return;
    const newValue = tags ? tags : [...new Set([...inputValue, text])];
    setInputValue(newValue);
    onChange(newValue);
    setText('');
  };

  return (
    <ThemedSelect
      searchValue={text}
      onSearch={(e) => setText(e)}
      onInputKeyDown={(e) => e.key === 'Enter' && handleChange()}
      dropdownRender={() =>
        text && (
          <DropdownItem>
            <p> Clique aqui </p> para adicionar a variação "{text}"
          </DropdownItem>
        )
      }
      mode='tags'
      style={{ width: '100%' }}
      dropdownMatchSelectWidth={true}
      dropdownStyle={{
        visibility: text ? 'visible' : 'hidden',
      }}
      {...rest}
      value={inputValue}
      onChange={(value) => handleChange(value)}
    />
  );
};
