import React, { useEffect } from 'react';
import { Separator } from 'components/Default';
import { PathIndicator } from 'components/PathIndicator';
import { RepositoryCard } from './Cards/RepositoryCard';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { fetchCloudStatus } from 'common/redux/cloud/thunks';
import GoogleDriveIcon from 'components/Icons/GoogleDriveIcon';
import TamiaIcon from 'components/Icons/TamiaIcon';
import { BackupTypeSelect } from './BackupTypeSelect';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getHiredPlan } from 'common/redux/payments/selectors';
import { DocumentsService } from 'core/services/documents.service';
import { getRoleStatus } from 'common/redux/auth/selectors';

const CadastroRepositorio = () => {
  const fetchStatus = useReduxAction(fetchCloudStatus);
  const hiredPlan = useReduxState(getHiredPlan);
  const { isOwner } = useReduxState(getRoleStatus);

  useEffect(() => {
    fetchStatus();
  }, []);

  const handleExport = async () => {
    const documentService = new DocumentsService();
    const { data: url } = await documentService.exportDocuments();
    window.open(url);
  };

  const text = (
    <span>
      Nessa página são exibidos o repositório padrão do Tâmia e o repositório
      auxiliar caso seja configurado para backup.
    </span>
  );

  return (
    <>
      <PathIndicator
        path={['Configurações', 'Armazenamento']}
        helperText={text}
      />
      <Separator />
      <>
        <div style={{ marginLeft: '15px' }}>
          Seus documentos salvos no maior data center do mundo:
          <RepositoryCard
            type='tamia'
            title='Tâmia'
            icon={<TamiaIcon size={20} />}
            hideDisconnectButton
          />
          {hiredPlan?.modules?.backup && (
            <>
              Você também pode utilizar um backup extra em nuvem própria:{' '}
              <BackupTypeSelect />
              <RepositoryCard
                type='googleDrive'
                title='Google Drive'
                icon={<GoogleDriveIcon size={20} />}
              />
            </>
          )}
          {isOwner && (
            <a onClick={handleExport}>Exportar documentos do sistema</a>
          )}
          {/*
          TODO: Implement other repository types
          <RepositoryCard
            type='oneDrive'
            title='One Drive'
            icon={<ImOnedrive color='#094ab1' size={20} />}
            inDevelopment
          />
          <RepositoryCard
            type='dropbox'
            title='Dropbox'
            icon={<FaDropbox color='#0062ff' size={20} />}
            inDevelopment
          /> */}
        </div>
      </>
    </>
  );
};
export default CadastroRepositorio;
