import { Select } from './Select';

const optionsPositions = [
  {
    id: 'Analista',
    name: 'Analista',
  },
  {
    id: 'Assistente',
    name: 'Assistente',
  },
  {
    id: 'Coordenador',
    name: 'Coordenador',
  },
  {
    id: 'Diretor',
    name: 'Diretor',
  },
  {
    id: 'Estagiário',
    name: 'Estagiário',
  },
  {
    id: 'Gerente',
    name: 'Gerente',
  },
  {
    id: 'Presidente',
    name: 'Presidente',
  },
  {
    id: 'Sócio',
    name: 'Sócio',
  },
  {
    id: 'Supervisor',
    name: 'Supervisor',
  },
  {
    id: 'Outro',
    name: 'Outro',
  },
];
const optionsNumEmployees = [
  {
    id: '1 - 10',
    name: '1 - 10',
  },
  {
    id: '11 - 50',
    name: '11 - 50',
  },
  {
    id: '51 - 100',
    name: ' 51 - 100',
  },
  {
    id: '101 - 50',
    name: '101 - 500',
  },
  {
    id: '501 - 1000',
    name: '501 - 1000',
  },
  {
    id: '> 1001',
    name: '> 1001',
  },
];
const optionsReasonForSearch = [
  {
    id: 'Tenho muitos documentos para gerenciar',
    name: 'Tenho muitos documentos para gerenciar',
  },
  {
    id: 'Quero organizar a busca de meus documentos',
    name: 'Quero organizar a busca de meus documentos',
  },
  {
    id: 'Quero acessar meus documentos de qualquer lugar',
    name: ' Quero acessar meus documentos de qualquer lugar',
  },
  {
    id: 'Não tenho um motivo, conheci agora',
    name: 'Não tenho um motivo, conheci agora',
  },
  {
    id: 'Outros',
    name: 'Outros',
  },
];

export const RegisterPositionSelect = ({ ...rest }) => {
  return (
    <Select
      placeholder='Selecione um cargo'
      selectOptions={optionsPositions}
      style={{
        width: '100%',
      }}
      allowClear={false}
      {...rest}
    />
  );
};
export const RegisterNumEmployeesSelect = ({ ...rest }) => {
  return (
    <Select
      placeholder='Selecione a quantidade de funcionários'
      selectOptions={optionsNumEmployees}
      style={{
        width: '100%',
      }}
      allowClear={false}
      {...rest}
    />
  );
};

export const ReasonForSearchSelect = ({ ...rest }) => {
  return (
    <Select
      placeholder='Selecione o motivo'
      selectOptions={optionsReasonForSearch}
      style={{
        width: '100%',
      }}
      allowClear={false}
      {...rest}
    />
  );
};
