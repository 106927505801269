import React, { useEffect } from 'react';
import { Form } from 'antd';
import { formsName } from 'common/enums/forms.enum';
import { ThemedRangePicker } from 'components/Themed/ThemedDatePicker';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getAuditFilters } from 'common/redux/filters/selectors';
import { setAuditFilters } from 'common/redux/filters/reducers';
import { CreatedBySelect } from 'components/Input/CreatedBySelect';
import { fetchAuditData } from 'common/redux/info/thunks';
import { clearEmptyValues } from 'common/utilities/clearEmptyValues';

export const AuditSearch = () => {
  const [form] = Form.useForm();
  const auditFilters = useReduxState(getAuditFilters);
  const setFilter = useReduxAction(setAuditFilters);
  const fetchAudit = useReduxAction(fetchAuditData);

  const handleSubmit = () => {
    fetchAudit();
  };

  const updateFilterValues = (value) => {
    const formattedDates = {
      createdAt: value.createdAt && {
        startDate: value.createdAt[0],
        endDate: value.createdAt[1],
      },
    };
    clearEmptyValues(formattedDates);
    const filters = {
      ...auditFilters,
      ...value,
      ...formattedDates,
    };
    setFilter({ filters });
  };

  useEffect(() => {
    if (!auditFilters) form.resetFields();
    const filterValues = { ...auditFilters };
    if (auditFilters?.createdAt) {
      filterValues.createdAt = [
        auditFilters.createdAt.startDate,
        auditFilters.createdAt.endDate,
      ];
    }
    form.setFieldsValue(filterValues);
  }, [auditFilters]);

  return (
    <Form
      id={formsName.AUDIT_SEARCH_FORM}
      onFinish={handleSubmit}
      form={form}
      layout='vertical'
      initialValues={auditFilters}
      onValuesChange={updateFilterValues}
    >
      <Form.Item name='createdBy' label='Criado por'>
        <CreatedBySelect />
      </Form.Item>

      <Form.Item name='createdAt' label='Período'>
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item name='id' label='ID do documento'>
        <ThemedTextInput placeholder='Digite aqui' />
      </Form.Item>
      <Form.Item name='internal_id' label='Número interno'>
        <ThemedTextInput placeholder='Digite aqui' />
      </Form.Item>
    </Form>
  );
};
