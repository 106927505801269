import { DatePicker } from 'antd';
import styled from 'styled-components';

export const ThemedDatePicker = styled(DatePicker)`
  border-color: #9cdb37 !important;
  border-radius: 20px !important;
  width: 100%;
  height: 2.5rem !important;
`;

const { RangePicker } = ThemedDatePicker;

export const ThemedRangePicker = styled(RangePicker)`
  max-width: 560px;
  border-color: #9cdb37 !important;
  border-radius: 20px !important;
  height: 2.5rem !important;
`;
