import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modals",
  initialState: { openModal: null, modalProps: null },
  reducers: {
    setModalState(state, action) {
      const {name, modalProps} = action.payload
      state.openModal = name;
      state.modalProps = modalProps
    },
  },
});
export const actions = modalSlice.actions;

export const { setModalState } = modalSlice.actions;

export default modalSlice.reducer;
