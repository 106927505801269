import React from 'react';
import { PathIndicator } from 'components/PathIndicator';
// import { createMaskedString } from 'components/Input/MaskInput';
// import { MaskTypes } from 'common/enums/maskTypes.enum';
// import EditAccount from 'components/Modals/ProfileRegister/editAccount';
// import EditContact from 'components/Modals/ProfileRegister/editContact';
// import { Roles } from 'common/enums/roles.enum';
import { Container, Title, Separator, ContentTable, SubHeader } from './style';

//Redux
import { useReduxState } from 'common/hooks/use-redux-state';
import { getFormattedDateTime } from 'common/utilities/dateFormatter';
import { getSystemInfo } from 'common/redux/info/selectors';
import { getUserRole } from 'common/redux/auth/selectors';
import { maskText } from 'common/utilities/maskText';
import { getHiredPlan } from 'common/redux/payments/selectors';

const Profile = () => {
  const companyInfo = useReduxState(getSystemInfo);
  // const userRole = useReduxState(getUserRole);
  const hiredPlan = useReduxState(getHiredPlan);

  return (
    <>
      <PathIndicator
        path={['Configurações', 'Dados da conta']}
        helperText='Aqui são exibidos dados da conta do usuário/empresa que contratou o sistema. O usuário responsável pela conta poderá editar os dados cadastrados'
      />
      <Container>
        <SubHeader>
          <Title>Dados da conta</Title>
        </SubHeader>
        <Separator />
        <ContentTable>
          <table id='first-table'>
            <tr>
              <td id='td-left'>Cliente:</td>
              <td id='td-right'>{companyInfo?.company?.name || '-'}</td>
            </tr>
            <tr>
              <td id='td-left'> CPF / CNPJ:</td>
              <td id='td-right'>
                {maskText(companyInfo?.identificationDocument, '*') || '-'}
              </td>
            </tr>
            <tr>
              <td id='td-left'>Responsável pelo contrato:</td>
              <td id='td-right'>{companyInfo?.owner?.name || '-'}</td>
            </tr>
            <tr>
              <td id='td-left'>Email do responsável:</td>
              <td id='td-right'>{companyInfo?.owner?.email || '-'}</td>
            </tr>
            <tr>
              <td id='td-left'>Telefone do responsável:</td>
              <td id='td-right'>{companyInfo?.owner?.phone || '-'}</td>
            </tr>
            <tr>
              <td id='td-left'>Número da licença:</td>
              <td id='td-right'>{companyInfo?._id || '-'}</td>
            </tr>

            <tr>
              <td id='td-left'> Plano:</td>
              <td id='td-right'>{hiredPlan.name}</td>
            </tr>
            <tr>
              <td id='td-left'> Cliente desde:</td>
              <td id='td-right'>
                {getFormattedDateTime(companyInfo?.createdAt) || '-'}
              </td>
            </tr>
          </table>
        </ContentTable>
      </Container>
    </>
  );
};

export default Profile;
