import ApplicationStateInjector from "core/application-state-injector";
import { UserApi } from "core/api/users";

export class UserService extends ApplicationStateInjector {
  constructor() {
    super();
    this.userApi = new UserApi(this.system);
  }

  getUsers() {
    return this.userApi.getUsers();
  }

  registerUser(requestParams) {
    return this.userApi.registerUser(requestParams);
  }
  registerClient(requestParams) {
    return this.userApi.registerClient(requestParams);
  }

  editUser(requestParams, userId) {
    return this.userApi.editUser(requestParams, userId);
  }

  deleteUser(userId) {
    return this.userApi.deleteUser(userId);
  }

  getInviteInfo(inviteId, system) {
    return this.userApi.getInviteInfo(inviteId, system);
  }

  getInvites() {
    return this.userApi.getInvites();
  }

  deleteInvite(inviteId) {
    return this.userApi.deleteInvite(inviteId);
  }

  createInvite(requestParams) {
    return this.userApi.inviteUser(requestParams);
  }

  resendInvte(inviteId) {
    return this.userApi.resendInvite(inviteId);
  }

  askDemonstration(requestParams) {
    return this.userApi.askDemonstration(requestParams);
  }
  registerUserMailchimp(requestParams) {
    return this.userApi.registerUserNewsletter(requestParams);
  }
}
