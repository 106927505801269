import React, { useEffect } from 'react';
import { Form } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { useReduxState } from 'common/hooks/use-redux-state';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { Flex } from 'components/Flex';
import {
  getCategories,
  getCategorySettings,
} from 'common/redux/permissions/selectors';
import { Select } from 'components/Input/Select';
import ThemedButton from 'components/Themed/ThemedButton';
import { createGroup, updateGroup } from 'common/redux/permissions/thunks';

const formRules = {
  role: [
    {
      required: true,
      message: 'Por favor insira um perfil',
    },
  ],
  required: [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ],
  name: [
    {
      required: true,
      message: 'Por favor insira um nome',
    },
  ],
  email: [
    {
      required: true,
      message: 'Por favor insira um e-mail',
    },
    {
      type: 'email',
      message: 'O e-mail inserido não é válido',
    },
  ],
  password: [
    {
      required: true,
      message: 'Por favor insira uma senha',
    },
    {
      min: 6,
      message: 'Senha deve conter no mínimo 6 caracteres',
    },
  ],
};

const GroupsManager = ({ currentRow, setManagerData }) => {
  const isEditing = !!Object.keys(currentRow).length;
  const title = isEditing
    ? 'Editar controle de acesso'
    : 'Criar controle de acesso';

  const [form] = Form.useForm();
  const categorySettings = useReduxState(getCategorySettings);
  const categories = useReduxState(getCategories);

  const addGroup = useReduxAction(createGroup);
  const editGroup = useReduxAction(updateGroup);

  const handleClose = () => {
    setManagerData(null);
  };
  const handleCategoryPermissions = (categoryPermissions) =>
    categoryPermissions?.flat() || [];

  const handleSubmit = async (payload) => {
    payload.categories = handleCategoryPermissions(
      Object.values(payload?.categories)
    );

    isEditing
      ? await editGroup({ ...currentRow, ...payload })
      : await addGroup(payload);
    form.resetFields();
    handleClose();
  };

  const handlePermissions = () => {
    const categoriesPermissions = currentRow.categories;
    if (!categoriesPermissions) return;

    const permissions = {};
    Object.entries(categories).forEach((category) => {
      const [key, value] = category;
      permissions[key] = value
        .filter((value) => categoriesPermissions.includes(value._id))
        .map((category) => category._id);
    });
    return permissions;
  };

  useEffect(() => {
    const categoryPermissions = handlePermissions();
    form.setFieldsValue({ categories: categoryPermissions });
  }, [currentRow]);

  useEffect(() => {
    return () => handleClose();
  }, []);

  return (
    <Flex direction='column' style={{ height: '100%' }}>
      <h1>{title}</h1>
      <div style={{ marginBottom: 20 }}>
        <Form
          id={title + currentRow?._id}
          onFinish={handleSubmit}
          form={form}
          layout='vertical'
          initialValues={{ name: currentRow.name, password: '' }}
        >
          <Form.Item
            name='name'
            label='Nome'
            validateTrigger='onBlur'
            rules={formRules.name}
          >
            <ThemedTextInput placeholder={'Digite aqui'} />
          </Form.Item>
          <>
            <h2>Permissões da categoria</h2>

            {categorySettings.map((category) => (
              <Form.Item
                name={['categories', `${category._id}`]}
                label={category.name}
              >
                <Select
                  mode='multiple'
                  selectOptions={categories[category._id]}
                  onSelectAll={(values) =>
                    form.setFieldValue(
                      ['categories', `${category._id}`],
                      values
                    )
                  }
                />
              </Form.Item>
            ))}
          </>
        </Form>
      </div>
      <Flex style={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
        <ThemedButton
          color='transparent'
          style={{ color: '#ff4747', borderColor: '#ff4747' }}
          onClick={handleClose}
        >
          Cancelar
        </ThemedButton>
        <ThemedButton
          color='#9cdb37'
          form={title + currentRow?._id}
          key='submit'
          htmlType='submit'
          style={{ marginLeft: 10 }}
        >
          Salvar
        </ThemedButton>
      </Flex>
    </Flex>
  );
};

export default GroupsManager;
