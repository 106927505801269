import React, { useEffect } from 'react';
import { Form } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { RoleSelect } from 'components/Input/RoleSelect';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getSystemInfo } from 'common/redux/info/selectors';
import {
  getUserRole,
  getRoleStatus,
  getCurrentCompanyId,
} from 'common/redux/auth/selectors';
import { FormRow } from 'components/Layout/FormRow';
import { createToast } from 'common/utilities/createToast';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { setManagingUsers } from 'common/redux/users/reducers';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { Flex } from 'components/Flex';
import ThemedButton from 'components/Themed/ThemedButton';
import { GroupSelect } from 'components/Input/GroupSelect';

const formRules = {
  role: [
    {
      required: true,
      message: 'Por favor insira um perfil',
    },
  ],
  required: [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ],
  name: [
    {
      required: true,
      message: 'Por favor insira um nome',
    },
  ],
  email: [
    {
      required: true,
      message: 'Por favor insira um e-mail',
    },
    {
      type: 'email',
      message: 'O e-mail inserido não é válido',
    },
  ],
  password: [
    {
      required: true,
      message: 'Por favor insira uma senha',
    },
    {
      min: 6,
      message: 'Senha deve conter no mínimo 6 caracteres',
    },
  ],
};

const UserManager = ({
  currentRow,
  onCreate,
  onEdit,
  title,
  isCurrentUser,
}) => {
  const [form] = Form.useForm();
  const companyInfo = useReduxState(getSystemInfo);
  const isEditing = !!currentRow;

  const { isAdmin, isOperator } = useReduxState(getRoleStatus);
  const currentUserRole = useReduxState(getUserRole);
  const currentCompanyId = useReduxState(getCurrentCompanyId);
  const { groupId, roleId } =
    currentRow?.companies.find(
      (company) => company.companyId == currentCompanyId
    ) || {};

  const _setManagingUsers = useReduxAction(setManagingUsers);

  const handleClose = () => {
    _setManagingUsers(false);
  };

  const handleSubmit = async (payload) => {
    const values = form.getFieldsValue();
    const { password, password_confirm } = values;
    if (password !== password_confirm) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Senhas não conferem',
      });
      return;
    }
    if (isEditing) {
      const id = currentRow._id;
      await onEdit({ payload, id });
    } else {
      await onCreate({ ...payload, company: companyInfo.company.name });
    }
    form.resetFields();
    handleClose();
  };

  useEffect(() => {
    return () => handleClose();
  }, []);

  return (
    <Flex direction='column' style={{ height: '100%' }}>
      <h1>{title}</h1>
      <div style={{ marginBottom: 20 }}>
        <Form
          id={title + currentRow?._id}
          onFinish={handleSubmit}
          form={form}
          layout='vertical'
          initialValues={{
            ...currentRow,
            password: '',
            groupId: groupId?.toString(),
          }}
        >
          <Form.Item
            name='email'
            label='E-mail'
            validateTrigger='onBlur'
            rules={formRules.email}
          >
            <ThemedTextInput placeholder={'Digite aqui'} disabled={isEditing} />
          </Form.Item>

          {isEditing && (
            <Form.Item name='name' label='Nome' validateTrigger='onBlur'>
              <ThemedTextInput
                placeholder={'Digite aqui'}
                disabled={isOperator}
              />
            </Form.Item>
          )}
          {(!isEditing || roleId >= currentUserRole) && (
            <Form.Item
              name='roleId'
              label='Perfil'
              rules={formRules.role}
              initialValue={roleId?.toString()}
            >
              <RoleSelect disabled={isOperator} />
            </Form.Item>
          )}

          {isAdmin && (
            <Form.Item name='groupId' label='Controle de acesso' rules={formRules.required}>
              <GroupSelect placeholder={'Digite aqui'} disabled={isOperator} />
            </Form.Item>
          )}

          {isEditing && isCurrentUser && (
            <>
              <FormRow>
                <Form.Item
                  label='Nova senha'
                  name='password'
                  rules={formRules.required}
                >
                  <ThemedTextInput.Password placeholder='*********' />
                </Form.Item>

                <Form.Item
                  label='Confirmar senha'
                  name='password_confirm'
                  rules={formRules.required}
                >
                  <ThemedTextInput.Password placeholder='*********' />
                </Form.Item>
              </FormRow>
            </>
          )}
        </Form>
      </div>
      <Flex style={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
        <ThemedButton
          color='transparent'
          style={{ color: '#ff4747', borderColor: '#ff4747' }}
          onClick={handleClose}
        >
          Cancelar
        </ThemedButton>
        <ThemedButton
          color='#9cdb37'
          form={title + currentRow?._id}
          key='submit'
          htmlType='submit'
          style={{ marginLeft: 10 }}
        >
          {isEditing ? 'Salvar' : 'Convidar'}
        </ThemedButton>
      </Flex>
    </Flex>
  );
};

export default UserManager;
