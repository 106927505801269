import Pusher from 'pusher-js';

export class PusherConnection {
  static shared = new PusherConnection();
  pusher;

  constructor() {
    this.pusher = new Pusher('2b0eaa749996cc93086a', {
      cluster: 'us2',
    });
  }

  static getAccountChannelName(userId) {
    return `channel-account-${userId}`;
  }

  static getUserChannelName(userId) {
    return `channel-user-${userId}`;
  }

  subscribe(channel) {
    this.pusher.subscribe(channel);
  }

  unsubscribe(channel) {
    this.pusher.unsubscribe(channel);
  }

  bind(event, action) {
    this.pusher.bind(event, action);
  }

  event(channel, event, data) {
    const ISOtime = new Date().toISOString();
    const payload = {
      ...data,
      time: ISOtime,
    };
    const userChannel = this.pusher.channels.channels[channel];
    userChannel.trigger(event, payload);
  }
}
