import { createSlice } from '@reduxjs/toolkit';
import { attemptLogin, sendResetEmail } from './thunks';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: {
      userId: null,
      email: null,
      system: null,
      token: null,
      showTutorial: null,
      isLoading: false,
    },
    userCompanies: [],
  },
  reducers: {
    logout() {
      //Handled in rootReducer
    },
    disableShowTutorial(state) {
      state.currentUser.showTutorial = false;
    },
    setUser(state, action) {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptLogin.fulfilled, (state, action) => {
        const { companies } = action?.payload;
        state.loading = false;
        state.userCompanies = companies;
      })
      .addCase(attemptLogin.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(attemptLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendResetEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendResetEmail.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(sendResetEmail.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { logout, disableShowTutorial, setUser } = authSlice.actions;

export const actions = authSlice.actions;

export default authSlice.reducer;
