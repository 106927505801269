import { createSlice } from '@reduxjs/toolkit';
import {
  createCategory,
  editCategorySettings,
  fetchCategories,
  fetchGroups,
  fetchPermissions,
} from './thunks';

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    categories: {
      data: [],
      settings: [],
      loading: false,
    },
    groups: {
      data: [],
      loading: false,
    },
  },
  reducers: {
    setSelectedCategory(state, action) {
      state.categories.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Permissions Reducer

    builder
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        const { groups, categories } = action.payload;
        const { data, settings } = categories;

        state.categories.data = data;
        state.categories.settings = settings;
        state.groups.data = groups;
        state.loading = false;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
      });

    //Category Reducer
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        const { data, settings } = action.payload;
        state.categories.data = data;
        state.categories.settings = settings;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      .addCase(editCategorySettings.fulfilled, (state, action) => {
        state.loading = false;
        state.erros = null;
      })
      .addCase(editCategorySettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(editCategorySettings.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      });

    //Group Reducer
    builder
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups.data = action.payload.data;
        state.loading = false;
        state.erros = null;
      })
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      });
  },
});

export const { setSelectedCategory } = permissionsSlice.actions;

export const actions = permissionsSlice.actions;

export default permissionsSlice.reducer;
