import {
  getCurrentUserId,
  getCurrentCompanyId,
} from 'common/redux/auth/selectors';
import {
  getActiveClouds,
  getUserDriveToken,
} from 'common/redux/cloud/selectors';
import { ApplicationStore } from './application-store';

export default class ApplicationStateInjector {
  constructor() {
    this.appStore = ApplicationStore.shared.get();

    const state = this.appStore.getState();
    this.system = getCurrentCompanyId(state);
    this.userId = getCurrentUserId(state);
    this.driveToken = getUserDriveToken(state);
    this.activeClouds = getActiveClouds(state);
  }
}
