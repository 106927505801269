import ApplicationStateInjector from 'core/application-state-injector';
import { SystemInfoApi } from 'core/api/systemInfo';

export class SystemInfoService extends ApplicationStateInjector {
  constructor() {
    super();
    this.systemInfoApi = new SystemInfoApi(this.system);
  }

  getSystemInfo() {
    return this.systemInfoApi.getSystemInfo();
  }

  updateSystemInfo(payload) {
    return this.systemInfoApi.updateSystemInfo(payload);
  }

  fetchInitialData() {
    return this.systemInfoApi.fetchInitialData();
  }

  fetchAudit(params) {
    return this.systemInfoApi.fetchAudit(params)
  }
}
