import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const PageContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: #f6faf5;
  margin-top: 1rem;
  padding: 2rem;

  @media (max-width: 700px) {
    margin-top: 2rem;
    width: 100%;
  }
`;

export const RegisterContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 30rem;
  background-color: #eeeeee;
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 10%);
  padding: 40px;

  @media (max-width: 700px) {
    width: 20rem;
    padding: 30px;
  }

  img {
    width: 10rem;
    height: 2.5rem;
  }
`;

export const FormContainer = styled(Flex)`
  margin-top: 40px;
  justify-content: center;

  @media (max-width: 700px) {
    margin-top: 20px;
  }

  & > * {
    width: 100%;
  }
`;

export const Subtitles = styled.p`
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid #9cdb37;

  @media (max-width: 700px) {
    font-size: 0.81rem;
  }
`;
