import ApplicationStateInjector from 'core/application-state-injector';
import { DocumentsApi } from 'core/api/documents';

export class DocumentsService extends ApplicationStateInjector {
  constructor() {
    super();
    this.documentsApi = new DocumentsApi(this.system, this.userId);
  }

  getDocuments() {
    return this.documentsApi.getDocuments();
  }

  getDocument(documentId, key = '') {
    return this.documentsApi.getDocument(documentId, key);
  }

  updateDocument(params, documentId) {
    return this.documentsApi.updateDocument(params, documentId);
  }

  createDocument(payload) {
    return this.documentsApi.createDocument(payload);
  }

  createCloudDocument(payload) {
    return this.documentsApi.createCloudDocument(payload);
  }

  updateCloudDocument(payload) {
    return this.documentsApi.updateCloudDocument(payload);
  }

  deleteDocument(documentIds, key) {
    return this.documentsApi.deleteDocument(documentIds, key);
  }

  exportDocuments(documentIds) {
    return this.documentsApi.exportDocuments(documentIds)
  }
}
