import ApplicationStateInjector from "core/application-state-injector";
import { FeedbackApi } from "core/api/feedback";

export class FeedbackService extends ApplicationStateInjector {
  constructor() {
    super();
    this.feedbackApi = new FeedbackApi(this.system);
  }
  postFeedbackApi(requestParams) {
    return this.feedbackApi.postFeedback(requestParams);
  }

  requestContact(contactParams) {
    return this.feedbackApi.requestContact(contactParams)
  }
}
