import ImgIndex from '../Landing Page/assets/indexacao.jpg';
import ImgDocs from '../Landing Page/assets/docs_empresarias.jpg';
import ImgOrganizing from '../Landing Page/assets/dicas_de_organizacao.jpg';
import ImgDigitalStorage from '../Landing Page/assets/digital-filing-storage.jpg';
import ImgFiles from '../Landing Page/assets/gestao_arquivos.jpg';
import ImgOversight from '../Landing Page/assets/fiscalizacao.jpg';
import { SubTitle, Text, Emphasis } from './style';

export const posts = [
  {
    id: 1,
    title: 'Documentos empresariais: o que guardar e por quanto tempo?',
    date: '01/08/2023',
    author: 'Tâmia',
    content: (
      <>
        <Text>
          Onde a sua empresa guarda os documentos importantes? Como você lida
          com esses documentos? Sabe onde e por quanto tempo guardar, e em que
          situações descartar?
        </Text>
        <Text>
          Em meio a tantas necessidades da empresa, a gestão documental fica
          entre os últimos tópicos de discussões. Pouco se fala sobre a
          importância do armazenamento e como fazê-lo.
        </Text>
        <Text>
          Por isso, preparamos um artigo completo para responder as suas dúvidas
          e te apresentar soluções rápidas para o seu problema. Sempre seguindo
          as melhores práticas e orientações legais, claro. Vamos lá!
        </Text>
        <SubTitle>1. Documentos Legais</SubTitle>
        <Text>
          <Emphasis>1.1 Contratos</Emphasis>
          <Text>
            Guarde todos os contratos assinados pela empresa, incluindo
            contratos de prestação de serviços, contratos de locação, acordos de
            confidencialidade e contratos com fornecedores ou clientes.
            Recomenda-se manter os contratos durante todo o período de vigência
            e por mais 5 a 10 anos após seu término, a depender do seu objeto.
          </Text>
          <Emphasis>1.2 Estatutos Sociais </Emphasis>
          <Text>
            Mantenha uma cópia atualizada dos estatutos sociais da empresa, pois
            eles contêm informações sobre sua estrutura, direitos e obrigações.
            Esses documentos, assim como as Atas de Assembleias e Conselho de
            Administração, devem ser guardados por toda a vida útil da empresa.{' '}
          </Text>
          <Emphasis> 1.3 Licenças e Permissões</Emphasis> Arquive todas as
          licenças, permissões e autorizações obtidas pela empresa para operar
          legalmente. Esses documentos devem ser mantidos enquanto forem válidos
          e, se necessário, por um período adicional após sua expiração. E além
          de manter arquivado, eles devem sempre estar facilmente disponíveis
          para possíveis fiscalizações.
        </Text>
        <SubTitle>2. Documentos Financeiros</SubTitle>
        <Emphasis>2.1 Demonstrações Financeiras</Emphasis>
        <Text>
          Guarde todas as demonstrações financeiras, como balanços patrimoniais,
          demonstrações de resultados e fluxo de caixa, pelo menos pelos últimos
          5 anos. No caso dos Livro Diário e Livro Razão, a guarda indicada é
          permanente. Manter registros financeiros precisos é essencial para a
          prestação de contas e para tomar decisões estratégicas.
        </Text>
        <Emphasis> 2.2 Notas Fiscais e Recibos </Emphasis>
        <Text>
          Arquive todas as notas fiscais e recibos relacionados às transações da
          empresa, incluindo compras, vendas e despesas. Recomenda-se manter
          esses documentos por um período mínimo de 10 anos - exceto notas de
          ativos se enquadram nessa sugestão, pois para esses a guarda é até 5
          anos após a baixa ou depreciação/amortização - já que eles podem ser
          necessários para fins fiscais e auditorias.
        </Text>
        <SubTitle>3. Documentos Trabalhistas</SubTitle>
        <Emphasis>3.1 Contratos de Trabalho</Emphasis>{' '}
        <Text>
          Guarde os contratos de trabalho assinados por funcionários, juntamente
          com quaisquer alterações ou aditivos feitos ao longo do tempo. Esses
          documentos devem ser mantidos de forma permanente.{' '}
        </Text>
        <Emphasis>3.2 Registros de Pagamento </Emphasis>
        <Text>
          Nesses casos, a orientação é arquivar recibos de salário, férias e 13º
          por 10 anos. Já o comprovante de ticket-refeição e transporte guarde
          por 5 anos, e a folha de pagamento o prazo é de 30 anos.
        </Text>
        <SubTitle>4. Documentos de Propriedade Intelectual</SubTitle>
        <Text>
          <Emphasis>4.1 Registros de Propriedade Intelectual</Emphasis> Mantenha
          cópias dos registros de propriedade intelectual, como patentes, marcas
          registradas e direitos autorais, pelo tempo de proteção concedido pela
          legislação aplicável.
        </Text>
        <SubTitle>5. Documentos Jurídicos</SubTitle>
        <Text>
          <Emphasis>5.1 Correspondências e Pareceres Legais </Emphasis>Arquive
          correspondências e pareceres legais relevantes para a empresa. Esses
          documentos podem fornecer orientações importantes em situações futuras
          e devem ser mantidos pelo tempo necessário para a resolução de
          questões legais.
        </Text>
        <SubTitle>6. Documentos de Seguros</SubTitle>
        <Emphasis>6.1 Apólices de Seguro</Emphasis>
        <Text>
          {' '}
          Mantenha as apólices de seguro atualizadas, juntamente com a
          correspondência relacionada a sinistros e reclamações. Recomenda-se
          manter esses documentos pelo período de vigência da apólice e por mais
          1 ano após seu término.
        </Text>{' '}
        <Text>
          Manter a documentação empresarial organizada e acessível é essencial
          para o bom funcionamento de qualquer negócio. Além de cumprir as
          obrigações legais, a guarda adequada dos documentos empresariais
          garante a segurança jurídica e a transparência das operações.{' '}
        </Text>
        <Text>
          Se você não possui um sistema que te ajuda a fazer essa organização e
          manter os seus arquivos seguros, conheça o Tâmia. Com ele você poderá
          armazenar seus arquivos digitais com facilidade e encontrar com
          rapidez, além de registrar quando cada documento pode ser descartado.
          Clique{' '}
          <a
            style={{ color: 'green' }}
            href='/cadastro'
            target='_blank'
            rel='noopener noreferrer'
          >
            <u>aqui</u>
          </a>{' '}
          e saiba mais.
        </Text>{' '}
        <Text>
          Ao investir tempo e esforço na organização e manutenção dos documentos
          empresariais, você protegerá o seu negócio e criando uma base sólida
          para o seu crescimento futuro. Não subestime a importância da gestão
          de documentos, pois ela pode fazer toda a diferença quando for
          necessário consultar seus registros.{' '}
        </Text>
        <Text>
          <b>E lembre-se: </b>os prazos de guarda mencionados neste artigo são
          orientações gerais e podem variar de acordo com a legislação do país e
          o tipo de negócio. É sempre recomendável consultar um profissional
          contábil ou jurídico, assim como o arquivista da sua empresa
          responsável por manter as informações da tabela de temporalidade de
          documentos atualizadas.
        </Text>
      </>
    ),
    image: ImgDocs,
  },

  {
    id: 2,
    title:
      'O que considerar na hora de escolher um software de GED para a sua empresa ',
    date: '02/08/2023',
    author: 'Tâmia',
    content: (
      <>
        <Text>
          O Gerenciamento Eletrônico de Documentos (GED) é uma parte essencial
          para empresas que desejam se destacar no mercado e almejam eficiência
          e valorização diante do cenário tecnológico que estamos imersos hoje
          em dia. A escolha de um software de GED adequado pode impulsionar a
          produtividade, a colaboração e a eficiência dos colaboradores quando o
          assunto é organização, armazenamento, recuperação e controle eficiente
          de informações e arquivos dentro de uma empresa ou organização. <br />
          <br />
          Neste artigo nós vamos te ensinar quais são os 6 fatores
          imprescindíveis a se considerar na hora de escolher um software de GED
          para a sua empresa.
        </Text>

        <Emphasis>1. As necessidades da sua empresa</Emphasis>
        <Text>
          Antes de escolher um software de GED, é fundamental entender as
          necessidades específicas da sua empresa em relação ao gerenciamento de
          documentos. Considere o volume de arquivos que você lida diariamente,
          o tamanho da equipe que irá utilizar o software e as necessidades que
          os seus colaboradores irão ter ao gerenciar os arquivos. Essa
          avaliação inicial irá te ajudar a entender que as demandas do seu
          negócio e as melhorias que o software deve proporcionar, como
          facilitar o acesso aos arquivos.
        </Text>

        <Emphasis>2. As funcionalidades essenciais</Emphasis>

        <Text>
          Ao avaliar um software de GED, é importante garantir que ele ofereça
          as ferramentas necessárias para o gerenciamento eficiente dos
          documentos da sua empresa. Certifique-se de que o software possui
          recursos como armazenamento seguro de arquivos, indexação avançada,
          pesquisa intuitiva e controle de usuários. Além disso, verifique se o
          sistema oferece funcionalidades adicionais relevantes como o recurso
          de temporalidade, que permite que o sistema te mostre quais os
          documentos que já podem ser descartados. A escolha de um software com
          as funcionalidades adequadas permitirá que sua empresa tenha um
          gerenciamento de documentos eficaz e otimizado, poupando tempo e
          recursos financeiros.
        </Text>
        <Emphasis>3. A Interface e a usabilidade</Emphasis>
        <Text>
          Uma interface intuitiva e de fácil uso é um fator crucial ao escolher
          um software de GED. Um app amigável facilita a adaptação por parte dos
          usuários e reduz a curva de aprendizado. Certifique-se de que o
          software tenha uma interface clara, organizada e que permita uma
          navegação fluida entre os recursos. Além disso, verifique se o
          fornecedor oferece treinamento e suporte adequados para garantir que
          sua equipe esteja capacitada para utilizar o software de forma
          eficiente.
        </Text>

        <Emphasis>4. A segurança do sistema</Emphasis>

        <Text>
          A segurança dos documentos empresariais é uma preocupação bastante
          comum ao escolher um software de GED. É preciso investigar se o
          sistema oferece recursos robustos para manter seus arquivos seguros,
          como criptografia de dados, backup regular, controle de acesso aos
          documentos, gerenciamento de senhas e monitoramento de logs. Além
          disso, certifique-se de que o software esteja em conformidade com as
          regulamentações de privacidade e proteção de dados relevantes para o
          mercado que a sua empresa atua.
        </Text>

        <Emphasis>5. A Escalabilidade e a integração.</Emphasis>
        <Text>
          Ao escolher um software de GED, verifique se o software pode
          acompanhar o crescimento do seu negócio e suportar um aumento no
          volume de documentos e usuários. Não ter estrutura para comportar o
          crescimento da sua empresa pode ser frustrante, ao contrário do que se
          pensa, isso também pode acontecer no ambiente virtual. Então contar
          com uma plataforma que oferece a possibilidade de expansão do
          armazenamento, através da migração para pacotes com mais vantagens faz
          toda diferença para empresas que têm ambições.
        </Text>

        <Emphasis>6. O suporte e os treinamentos</Emphasis>

        <Text>
          Um suporte técnico confiável e recursos de treinamento adequados são
          fundamentais para o sucesso da implementação e adoção do software de
          GED na sua empresa. Verifique se o fornecedor oferece suporte técnico
          eficiente para ajudar a resolver quaisquer problemas ou dúvidas que
          possam surgir. Além disso, certifique-se de que o fornecedor ofereça
          recursos de treinamento, como documentação clara, tutoriais em vídeo e
          outros, que garantam que sua equipe estará preparada para utilizar o
          software e aproveitar todos os seus benefícios.
        </Text>

        <Text>
          Considerar esses 6 fatores irá te ajudar a escolher o melhor GED para
          você e para a sua empresa. Cada sistema possui funcionalidades
          diferentes e únicas, por isso, é importante que você saiba qual
          problema você deseja solucionar com a contratação de um software como
          esse e quais os benefícios que ele pode trazer para a sua equipe.{' '}
        </Text>
        <Text>
          Siga as nossas dicas e avalie se o Tâmia, nosso sistema inteligente de
          arquivos, é o melhor Gerenciamento Eletrônico de Documentos (GED) para
          o seu negócio. Será um prazer te apresentar as nossas funcionalidades
          e soluções. Clique aqui.{' '}
        </Text>
      </>
    ),
    image: ImgIndex,
  },

  {
    id: 3,
    title:
      'Digitalizar documentos físicos: como essa prática pode facilitar o acesso e a organização da sua empresa',
    date: '02/08/2023',
    author: 'Tâmia',
    content: (
      <>
        <Text>
          Imagine estar em 2023 e precisar procurar manualmente um documento de
          2005 para um processo jurídico ou uma auditoria fiscal, sem qualquer
          controle que facilite a localização. No entanto, simplesmente fazer a
          digitalização e transferir os arquivos do mundo físico para o digital
          não é suficiente. É crucial saber como gerenciar esses documentos para
          manter as informações acessíveis e organizadas para a sua equipe a
          qualquer momento. Felizmente, você não precisa enfrentar essa tarefa
          sozinho, pois existem ferramentas e empresas especializadas que podem
          ajudá-lo nesse processo.
        </Text>

        <Emphasis>1. Contratação de empresas de digitalização</Emphasis>

        <Text>
          Se sua empresa ainda possui um local para armazenamento de arquivos
          físicos, sabe que o volume de documentos geralmente é significativo.
          São caixas e mais caixas de papéis antigos e informações valiosas.
          Portanto, é recomendável contratar uma empresa especializada em
          digitalização para lidar com esse trabalho para você.
        </Text>
        <Text>
          Afinal, o serviço de digitalização não se resume apenas a escanear os
          documentos. É necessário preparar os papéis adequadamente, capturar e
          indexar cada arquivo devidamente. Essa tarefa geralmente é realizada
          por arquivistas.
        </Text>
        <Emphasis>
          2. Utilização de um sistema de gerenciamento de documentos
        </Emphasis>

        <Text>
          Ao chegar o momento de guardar esses arquivos digitalizados, você
          perceberá que o armazenamento em serviços de nuvens como o Google
          Drive pode não ser suficientes. É necessário utilizar um sistema
          dedicado ao gerenciamento eletrônico de documentos, também conhecidos
          GEDs. Com essas ferramentas, é possível armazenar os arquivos com
          segurança, criar processos eficientes de indexação para grandes
          conjuntos documentais, pesquisar documentos de forma intuitiva e ter
          controle total sobre os acessos, definindo permissões para cada
          usuário. Além disso, alguns sistemas, como o Tâmia, oferecem
          funcionalidades de gestão de prazos de guarda, permitindo identificar
          quais documentos podem ser descartados e em qual data específica.
        </Text>
        <Emphasis>3. Realização de treinamentos.</Emphasis>

        <Text>
          Treinar toda a equipe envolvida, desde os responsáveis pelo sistema
          até o usuário final, é essencial para manter a organização em dia. Não
          adianta investir tempo e recursos financeiros na digitalização dos
          documentos se os colaboradores não souberem como manter a organização
          e aproveitar todas as funcionalidades do sistema em benefício da
          empresa. Portanto, além de escanear os arquivos e organizá-los no
          sistema, solicitar um treinamento completo da ferramenta que você
          contratou é imprescindível, a fim de capacitar todos os usuários.
        </Text>
        <Text>
          Seguindo esses três passos, você garantirá uma gestão eficiente dos
          documentos da sua empresa, economizando tempo na busca por informações
          e recursos financeiros. Para obter mais informações sobre o Tâmia,
          nosso sistema de gerenciamento eletrônico de documentos, e para
          verificar se ele atende às necessidades da sua empresa, clique{' '}
          <a
            style={{ color: 'green' }}
            href='/cadastro'
            target='_blank'
            rel='noopener noreferrer'
          >
            <u>aqui</u>
          </a>
          .
        </Text>
      </>
    ),
    image: ImgOrganizing,
  },
];
