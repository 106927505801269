import styled from 'styled-components';
import { Button } from 'antd';

export const AntButton = styled(Button)`
  height: 42px;
  font-size: 0.9rem;
  margin: 10px;
`;

export const ButtonSave = styled.button`
  background-color: #035efc;
  color: #fff;
  width: 120px;
  height: 42px;
  font-size: 18px;
  margin-top: 16px;
  border: 0px;
  font-family: Roboto, sans-serif;

  &&:hover {
    transition: 0.2s;
    background-color: #004fd6;
  }
`;
export const ButtonCancel = styled.button`
  background-color: #fff;
  color: #035efc;
  width: 120px;
  height: 42px;
  font-size: 18px;
  margin-top: 16px;
  border: 1px solid #035efc;
  font-family: Roboto, sans-serif;

  &&:hover {
    transition: 0.2s;
    transition: 0.2s;
    color: #0041b0;
    border: 1px solid #0041b0;
  }
`;

export const ButtonConfigTable = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0fa94;
  color: #21211a;
  box-shadow:1px 1px #ccc;
  margin: 25px;
  border-radius: 30px;
  width: 120px;
  height: 40px;
  border: none;
  &&:hover {
    opacity:0.9;
    color: #21211a;
    transition: 0.3s;
  }
`;
export const ButtonNewUser = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009a25;
  color: #fff;
  margin: 25px 5px;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  border: none;
  &&:hover {
    background-color: #00631b;
    color: #fff;
    transition: 0.2s;
  }
`;
export const ButtonDownload = styled(Button)`
  min-width: 120px;
  height: 42px;
  font-size: 15px;

  margin: 10px;
`;
export const ButtonEdit = styled.button`
  background-color: #f3f4f5;
  color: #000;
  border: 1px solid #969696;
  padding: 9px;
  width: 115px;
  height: 35px;
  font-size: 13px;
  margin: 10px;
  justify-content: center;
  text-align: center;

  &&:hover {
    transition: 0.2s;
    background-color: #006aff;
    color: #fff;
  }
`;

export const ButtonReturn = styled.button`
  background-color: #f3f4f5;
  color: #000;
  border: 1px solid #969696;
  padding: 9px;
  width: 115px;
  height: 35px;
  font-size: 13px;
  margin: 10px;
  justify-content: center;
  text-align: center;

  &&:hover {
    transition: 0.2s;
    background-color: #006aff;
    color: #fff;
  }
`;
export const ButtonSearch = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  color: #212112;
  background-color: #9cdb37;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  width: 1.7rem;
  height: 1.7rem;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;

export const ButtonSearchDetail = styled.button`
  padding-top: 5px;
  background-color: #ff8229;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: 10px;
  font-size: 15px;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  border: 0px;

  &&:hover {
    transition: 0.3s;
    opacity: 0.9;
  }
`;

export const ButtonShow = styled(Button)`
  min-width: 120px;
  height: 42px;
  font-size: 15px;
  margin: 10px;
`;
export const ButtonNext = styled.button`
  background-color: #006aff;
  color: #fff;
  width: 100px;
  height: 50px;
  font-size: 20px;
  margin: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  font-family: Roboto, sans-serif;
  &&:hover {
    transition: 0.2s;
    opacity: 0.9;
  }
`;
export const ButtonBack = styled.button`
  background-color: #006919;
  color: #fff;
  width: 100px;
  height: 50px;
  font-size: 20px;
  margin: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  font-family: Roboto, sans-serif;
  &&:hover {
    transition: 0.2s;
    opacity: 0.9;
  }
`;
export const ButtonLogin = styled.button`
  background-color: #f86204;
  color: #fff;
  width: 100px;
  height: 50px;
  font-size: 20px;
  margin: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  font-family: Roboto, sans-serif;
  &&:hover {
    transition: 0.2s;
    opacity: 0.9;
  }
`;
export const ButtonDefault = styled.button`
  background-color: #c7c7c7;
  color: #1f1f1f;
  width: 100px;
  height: 30px;
  font-size: 15px;
  margin: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  font-family: Roboto, sans-serif;
  &&:hover {
    transition: 0.2s;
    opacity: 0.9;
  }
`;
