import React from 'react';
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai';
import { Form } from 'antd';
import { ThemedRegisterInput } from 'components/Themed/ThemedRegisterInput';
import { Routes } from 'common/enums/routes.enum';
import { Container, Text, Title, InputContainer, Submit } from './styles';
import { useLocation } from 'react-router-dom';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { createToast } from 'common/utilities/createToast';
import { resetPassword, sendResetEmail } from 'common/redux/auth/thunks';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { useHistory } from 'react-router-dom';
import { useReduxState } from 'common/hooks/use-redux-state';
import { isAuthLoading } from 'common/redux/auth/selectors';
import { emailRule } from 'common/utilities/rules';
import ThemedButton from 'components/Themed/ThemedButton';

function PassRecover() {
  const history = useHistory();
  const notify = useReduxAction(createToast);
  const sendRecoveryEmail = useReduxAction(sendResetEmail);
  const recoverPassword = useReduxAction(resetPassword);
  const location = useLocation();
  const params = new URLSearchParams(decodeURIComponent(location.search));
  const code = params.get('code');
  const isLoading = useReduxState(isAuthLoading);

  const handleNewPassword = (values) => {
    const { password, passwordConfirm } = values;
    if (password !== passwordConfirm)
      notify({ type: toastTypes.ERROR, message: 'Senhas não conferem' });
    else {
      recoverPassword({ password, code });
    }
  };

  const handleEmailSend = (values) => {
    const { email } = values;
    sendRecoveryEmail(email);
  };

  return (
    <Container>
      {code ? (
        <>
          <Title>Digite sua nova senha</Title>
          <Form onFinish={handleNewPassword}>
            <InputContainer>
              <AiOutlineLock size={20} style={{ color: '#9cdb37' }} />
              <Form.Item name='password'>
                <ThemedRegisterInput
                  style={{ marginTop: '1.5rem' }}
                  type='password'
                  placeholder='Senha'
                />
              </Form.Item>
            </InputContainer>
            <InputContainer>
              <AiOutlineLock size={20} style={{ color: '#9cdb37' }} />
              <Form.Item name='passwordConfirm'>
                <ThemedRegisterInput
                  style={{ marginTop: '1.5rem' }}
                  type='password'
                  placeholder='Repetir senha'
                />
              </Form.Item>
            </InputContainer>
            <Submit type='submit' value='Atualizar' />
            <Text onClick={() => history.push(Routes.HOME)}>Voltar</Text>
          </Form>
        </>
      ) : (
        <>
          <Title>Recuperar senha</Title>
          <Form onFinish={handleEmailSend}>
            <InputContainer>
              <AiOutlineMail size={20} style={{ color: 'green' }} />
              <Form.Item name='email' noStyle rules={emailRule}>
                <ThemedRegisterInput
                  placeholder='Digite seu e-mail'
                />
              </Form.Item>
            </InputContainer>
            <ThemedButton
              weight='700'
              height='50px'
              width='100%'
              style={{
                fontSize: '20px',
                marginTop: '20px',
                borderRadius: '30px',
              }}
              color='#9cdb37'
              htmlType='submit'
              loading={isLoading}
            >
              Recuperar
            </ThemedButton>
            <Text onClick={() => history.push(Routes.HOME)}>Voltar</Text>
          </Form>
        </>
      )}
    </Container>
  );
}

export default PassRecover;
