import { getMergedCategories } from 'common/redux/permissions/selectors';
import { ApplicationStore } from 'core/application-store';

export const getCategoryData = (categoryIds = []) => {
  const appStore = ApplicationStore.shared.get();
  const state = appStore.getState();

  const categories = getMergedCategories(state);

  return categoryIds.map((categoryId) =>
    categories.find((category) => category._id == categoryId)
  );
};
