export const requiredRule = [
  {
    required: true,
    message: 'Por favor insira um valor',
  },
];

export const emailRule = [
  {
    required: true,
    message: 'Por favor insira um e-mail',
  },
  {
    type: 'email',
    message: 'O e-mail inserido não é válido',
  },
];

export const passwordRule = [
  {
    required: true,
    message: 'Por favor insira uma senha',
  },
  {
    min: 6,
    message: 'Senha deve conter no mínimo 6 caracteres',
  },
];
