import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { createToast } from 'common/utilities/createToast';
import { fetchUsers } from '../users/thunks';
import { CategoryTypes, GroupTypes, PermissionTypes } from './types';
import { PermissionService } from 'core/services/permissions.service';

export const fetchCategories = createAsyncThunk(
  CategoryTypes.FETCH_CATEGORIES,
  async (_, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.fetchCategories();

      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const editCategorySettings = createAsyncThunk(
  CategoryTypes.UPDATE_CATEGORY_SETTINGS,
  async (payload, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.editCategorySettings(payload);
      thunkApi.dispatch(fetchCategories());
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const createCategory = createAsyncThunk(
  CategoryTypes.CREATE_CATEGORY,
  async (payload, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.createCategory(payload);
      thunkApi.dispatch(fetchCategories());
      thunkApi.dispatch(fetchUsers());
      createToast({ type: toastTypes.SUCCESS, message: 'Criado com sucesso!' });

      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const updateCategory = createAsyncThunk(
  CategoryTypes.UPDATE_CATEGORY,
  async (payload, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.updateCategory(payload);
      thunkApi.dispatch(fetchCategories());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Atualizado com sucesso!',
      });
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const deleteCategory = createAsyncThunk(
  CategoryTypes.DELETE_CATEGORY,
  async (payload, thunkApi) => {
    const { id } = payload;

    try {
      const permissionService = new PermissionService();
      const response = await permissionService.deleteCategory(id);
      thunkApi.dispatch(fetchCategories());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Removido com sucesso!',
      });
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchGroups = createAsyncThunk(
  GroupTypes.FETCH_GROUPS,
  async (_, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.fetchAllGroups();
      return response.data;
    } catch (error) {
      console.log('~  error:', error);
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const deleteGroup = createAsyncThunk(
  GroupTypes.DELETE_GROUP,
  async (payload, thunkApi) => {
    const { id } = payload;

    try {
      const permissionService = new PermissionService();
      const response = await permissionService.deleteGroup(id);
      thunkApi.dispatch(fetchGroups());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Removido com sucesso!',
      });
      return response;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const createGroup = createAsyncThunk(
  GroupTypes.CREATE_GROUP,
  async (payload, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.createGroup(payload);
      thunkApi.dispatch(fetchGroups());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Criado com sucesso!',
      });
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const updateGroup = createAsyncThunk(
  GroupTypes.UPDATE_GROUP,
  async (payload, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.updateGroup(payload);
      thunkApi.dispatch(fetchGroups());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Atualizado com sucesso!',
      });
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  PermissionTypes.FETCH_PERMISSIONS,
  async (_, thunkApi) => {
    try {
      const permissionService = new PermissionService();
      const response = await permissionService.fetchAllPermissions();
      return response.data;
    } catch (error) {
      console.log('~  error:', error);
      createToast({ type: toastTypes.ERROR, message: error.message });
      thunkApi.rejectWithValue({ error });
    }
  }
);
