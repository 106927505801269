const s = (state) => state.cloud;

export const getUserDriveToken = (state) => s(state).drive?.token || null;

export const getUserDriveInfo = (state) => s(state).drive?.info || null;

export const getFileLinks = (state) => s(state).fileLinks || null;

export const getCloudStatus = (state) => s(state).status || null;

export const isCloudLoading = (state) => s(state).loading || false;

export const getActiveClouds = (state) => s(state).status?.activeClouds || [];
