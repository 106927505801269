import { toastTypes } from 'common/enums/toastTypes.enum';
import { toast } from 'react-toastify';
import React from 'react';

export const createToast = ({ type, message, options, withRef = false }) => {
  return toast[type](message, options);
};

export class Toast {
  static progressToastId;

  constructor() {}

  static createProgressToast(message = 'Em progresso...') {
    const progressToastId = createToast({
      type: toastTypes.LOADING,
      message,
      options: { progress: 0.01 },
    });

    Toast.progressToastId = progressToastId;

    return progressToastId;
  }

  static updateProgress(progress, message = '') {
    if (!Toast.progressToastId) {
      Toast.createProgressToast(message);
    }
    if (progress >= 1) {
      toast.done(Toast.progressToastId);
      Toast.progressToastId = null;
    }
    toast.update(Toast.progressToastId, { progress });
  }

  createToast({ type, message, options }) {
    return toast[type](message, options);
  }
}

export { toastTypes };
