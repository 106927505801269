import React from 'react';
import { VscFileSubmodule, VscHome, VscListSelection } from 'react-icons/vsc';
import { BsPeople, BsCloudUpload } from 'react-icons/bs';
import { AiOutlineAudit, AiOutlineGroup, AiOutlineSetting } from 'react-icons/ai';
import { RiAccountBoxLine } from 'react-icons/ri';
import { FiFilePlus } from 'react-icons/fi';
import { Routes } from 'common/enums/routes.enum';
import { useHistory } from 'react-router-dom';
import { Container, SidebarMenu } from './style.js';
import { useReduxState } from 'common/hooks/use-redux-state.js';
import { getUserRole } from 'common/redux/auth/selectors.js';
import { Roles } from 'common/enums/roles.enum.js';
import { getSidebarState } from 'common/redux/layout/selectors.js';
import { useReduxAction } from 'common/hooks/use-redux-action.js';
import { toggleSidebar } from 'common/redux/layout/reducers.js';
import {
  getCategorySettings,
  getSelectedCategory,
} from 'common/redux/permissions/selectors.js';
import { setSelectedCategory } from 'common/redux/permissions/reducer.js';
import { CgTimer } from 'react-icons/cg';
import { HiOutlineServer } from 'react-icons/hi';
import { getHiredPlan } from 'common/redux/payments/selectors.js';

export const Sidebar = ({ location, isSmallScreen, ...props }) => {
  const history = useHistory();
  const userRole = useReduxState(getUserRole);
  const selectedCategory = useReduxState(getSelectedCategory);

  const isSidebarOpen = useReduxState(getSidebarState);
  const _toggleSidebar = useReduxAction(toggleSidebar);
  const categorySettings = useReduxState(getCategorySettings);
  const _setSelectedCategory = useReduxAction(setSelectedCategory);
  const hiredPlan = useReduxState(getHiredPlan);

  const routeSuffix = location.pathname === Routes.CATEGORIES ? `?categoryId=${selectedCategory?._id}` : ''

  const parentsMap = {
    [Routes.DIGITAL_TEMPORALITY]: ['temporality'],
    [Routes.PHYSICAL_TEMPORALITY]: ['temporality'],
    lists: ['settings'],
    [Routes.USERS]: ['settings'],
    [Routes.STORAGE]: ['settings'],
    [Routes.GROUPS]: ['settings'],
    [Routes.CATEGORIES]: ['settings', 'lists'],
    [Routes.AUDIT]: ['settings']
  };

  const start = {
    label: 'Início',
    key: Routes.HOME,
    icon: <VscHome size={18} color='green' />,
  };

  const newDocument = {
    label: 'Novo documento',
    key: Routes.DOCUMENTS,
    icon: <FiFilePlus size={18} color='green' />,
  };

  const temporality = hiredPlan?.modules?.temporality && {
    label: 'Descarte',
    key: 'temporality',
    icon: <CgTimer size={20} color='green' />,
    children: [
      {
        label: 'Documento eletrônico',
        key: Routes.DIGITAL_TEMPORALITY,
        icon: <HiOutlineServer size={18} color='green' />,
      },
      {
        label: 'Documento físico',
        key: Routes.PHYSICAL_TEMPORALITY,
        icon: <VscFileSubmodule size={18} color='green' />,
      },
    ],
  };

  const settings = {
    label: 'Configurações',
    key: 'settings',
    icon: <AiOutlineSetting size={18} color='green' />,
    children: [
      {
        label: 'Categorias',
        key: 'lists',
        icon: <VscListSelection size={18} color='green' />,
        children: categorySettings.map((category) => ({
          label: category.name,
          key: Routes.CATEGORIES + `?categoryId=${category._id}`,
        })),
      },
      {
        label: 'Usuários',
        key: Routes.USERS,
        icon: <BsPeople size={18} color='green' />,
      },
      {
        label: 'Controle de acesso',
        key: Routes.GROUPS,
        icon: <AiOutlineGroup size={18} color='green' />,
      },
      {
        label: 'Armazenamento',
        key: Routes.STORAGE,
        icon: <BsCloudUpload size={18} color='green' />,
      },
      {
        label: 'Auditoria',
        key: Routes.AUDIT,
        icon: <AiOutlineAudit size={18} color='green' />,
      },
    ],
  };

  const items = [start];
  if (userRole <= Roles.MANAGER) {
    items.push(newDocument, temporality, settings);
  }

  const onClose = () => {
    isSmallScreen && _toggleSidebar();
  };

  const handleClick = ({ key }) => {
    const [url, query] = key.split('?');
    const params = new URLSearchParams(query);

    if (params?.has('categoryId')) {
      const category = categorySettings.find(
        (category) => category._id == params.get('categoryId')
      );
      _setSelectedCategory(category._id);
    }

    history.push(url);
    onClose();
  };

  return (
    <Container {...props}>
      <SidebarMenu
        defaultSelectedKeys={[Routes.HOME]}
        selectedKeys={[`${location.pathname}${routeSuffix}`]}
        defaultOpenKeys={parentsMap?.[location.pathname]}
        onClick={handleClick}
        mode='inline'
        items={items}
      />
    </Container>
  );
};
