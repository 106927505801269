import { useReduxAction } from "common/hooks/use-redux-action";
import { useReduxState } from "common/hooks/use-redux-state";
import { setModalState } from "common/redux/modals/reducers";
import { getModal } from "common/redux/modals/selectors";
import { ModalTypes } from "common/enums/modals.enum";
import CategoriesModal from "./Lists";
import EditCategoryModal from "./Lists/editCategory";

const modals = {
  [ModalTypes.CATEGORIES]: CategoriesModal,
  [ModalTypes.EDIT_CATEGORY]: EditCategoryModal
};

export const ModalRoot = () => {
  const setCurrentModal = useReduxAction(setModalState);
  const modal = useReduxState(getModal);

  const onClose = () => {
    setCurrentModal({ name: null });
  };

  const VisibleModal = modals[modal.openModal];
  if (!VisibleModal) return null;
  return (
    <VisibleModal onCancel={onClose} {...modal.modalProps} visible={true} />
  );
};
