import { Select } from 'components/Input/Select';

const options = [
  {
    id: 'googleDrive',
    name: 'Google Drive',
  },
  {
    id: 'oneDrive',
    name: 'One Drive (Em construção)',
    disabled: true,
  },
  { id: 'dropbox', name: 'Dropbox (Em construção)', disabled: true },
];

export const BackupTypeSelect = (props) => {
  return (
    <Select
      defaultValue='googleDrive'
      placeholder='Selecione...'
      selectOptions={options}
      style={{
        borderRadius: '20px',
        boxShadow: '0px 5px 5px 0px rgb(0 0 0 / 15%)',
        width: 255,
        marginLeft: 10,
      }}
      allowClear={false}
      {...props}
    />
  );
};
