import React, { useState, useRef } from 'react';
import ImgHero3 from './assets/mockupTerceiraDobra.png';
import ImgBlog1 from './assets/docs_empresarias.jpg';
import ImgBlog2 from './assets/indexacao.jpg';
import ImgBlog3 from './assets/dicas_de_organizacao.jpg';
import TelaHome from './assets/imagemPrimeiraDobra.png';
import { Collapse } from 'antd';
import { faqData } from './FAQ';
import { Navigation } from './Navigation';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './style.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Footer } from './Footer';
import CookieConsent from 'react-cookie-consent';
import Modal from './ModalCookie';

const Dropdown = styled(Collapse)``;
const { Panel } = Dropdown;
const DropdownPanel = styled(Panel)`
  padding: 10px 0;
  border-bottom: 1px solid var(--tamia-hard-green) !important;

  p {
    font-weight: 100;
    font-size: 0.81rem;
  }

  .ant-collapse-arrow svg {
    color: var(--tamia-hard-green);
  }
  .ant-collapse-header {
    padding: 12px 40px 12px 0px !important;
  }
  .ant-collapse-content-box {
    padding: 0 10px 20px 0px !important;
  }

  @media (min-width: 1024px) {
    .ant-collapse-content-box {
      padding: 0 175px 20px 0px !important;
    }
  }
`;

export const LandingPage = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  function onScroll() {
    showNavOnScroll();
    showBackToTopButtonOnScroll();
  }

  window.addEventListener('scroll', onScroll);

  function showNavOnScroll() {
    const navigation = document.getElementById('navigation');

    if (scrollY > 0) {
      navigation?.classList.add('scroll');
    } else {
      navigation?.classList.remove('scroll');
    }
  }

  function showBackToTopButtonOnScroll() {
    const backToTopButton = document.getElementById('backToTopButton');

    if (scrollY > 550) {
      backToTopButton?.classList.add('show');
    } else {
      backToTopButton?.classList.remove('show');
    }
  }

  const aboutRef = useRef(null);
  const homeRef = useRef(null);
  const faqRef = useRef(null);
  const blogRef = useRef(null);

  const refs = {
    about: aboutRef,
    home: homeRef,
    faq: faqRef,
    blog: blogRef,
  };

  return (
    <>
      <div>
        <Navigation refs={refs} />
        <section id='home' ref={homeRef}>
          <div className='wrapper'>
            <div className='col-a'>
              <header>
                <hr className='hr-top' />
                <h1>
                  Reúna todos os seus arquivos em um só lugar, e mantenha a sua
                  empresa sempre organizada
                </h1>
                <hr className='hr-bottom' />
                <div className='content'>
                  <p>
                    Encontre os documentos da sua empresa com facilidade e
                    economize tempo e recursos financeiros
                  </p>
                  <Link
                    className='button'
                    onClick={() => {
                      history.push('/cadastro');
                      window.scrollTo(0, 0);
                    }}
                  >
                    QUERO TESTAR
                  </Link>
                </div>
              </header>
            </div>

            <img className='hero-img-1' src={TelaHome} alt='' />
          </div>
        </section>

        <section id='about' ref={aboutRef}>
          <h1>COM O TÂMIA VOCÊ SEMPRE ENCONTRA</h1>
          <div className='wrapper'>
            <div className='col-b'>
              <p>
                O Tâmia é um sistema de gerenciamento de arquivos desenvolvido
                para organizar informações e estar disponível a qualquer momento
                do dia para os seus usuários. <br />
                <br />
                Com ele, todos os documentos podem ser cadastrados de forma
                organizada e com termos que facilitem a localização futura.
                <br />
                <br />
                Aqui eles são armazenados com segurança e poderão ser acessados
                pelos usuários de acordo com a restrição do perfil de acesso de
                cada um.
              </p>
            </div>
            <div className='col-b'>
              <p>
                Além disso, você e os seus colaboradores conseguem acessar esses
                documentos em qualquer hora e lugar, sem depender de acesso
                remoto por rede. <br />
                <br />
                Desse modo, a produtividade da sua equipe não fica comprometida
                e o seu time não estará mais refém das mudanças internas,
                possibilitando assim, o crescimento do seu negócio.
                <br />
                <br />
                <h2>#tamiasempreencontra</h2>
              </p>
            </div>
          </div>
        </section>

        <section id='hero-3'>
          <h1>
            Conheça agora o sistema que vai levar a sua <br /> empresa para o
            próximo nível de organização
          </h1>
          <div class='wrapper'>
            <div class='col-a'>
              <img src={ImgHero3} alt='' />
            </div>
            <div class='col-b'>
              <p>
                Sem complicações, com
                <br />
                uma interface amigável e <br />
                intuitiva.
                <br />
                <br />
                Fácil de aprender, fácil
                <br /> de usar e fácil de achar.
              </p>
            </div>
          </div>
        </section>
        <section id='hero-5'>
          <div className='wrapper'>
            <div className='col-a'>
              <h1>
                Com apenas 3 passos você pode ter <br />o melhor sistema de
                gerenciamento de arquivos na sua empresa
              </h1>
              <p>É rápido como uma Tâmia!</p>
              <Link
                className='button'
                onClick={() => {
                  history.push('/cadastro');
                  window.scrollTo(0, 0);
                }}
              >
                SEJA TÂMIA
              </Link>
            </div>
            <div className='col-b'>
              <div id='custom-list'>
                <div class='custom-row'>
                  <span class='custom-number'>1</span>
                  <p>
                    Clique em SEJA TÂMIA e responda algumas perguntas básicas
                    sobre a sua empresa. Levará menos de 1 minuto e você poderá
                    escolher a melhor forma de contato!
                  </p>
                </div>
                <div class='custom-row'>
                  <span class='custom-number'>2</span>
                  <p>
                    Em seguida, você receberá informações sobre planos, preços e
                    benefícios. E isso também será rapidinho!
                  </p>
                </div>
                <div class='custom-row'>
                  <span class='custom-number'>3</span>
                  <p>
                    Encontre o melhor plano para a sua necessidade e comece a
                    organizar os documentos da sua empresa. É fácil usufruir de
                    todos os recursos do Tâmia!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='services'>
          <div className='wrapper'>
            <header>
              <h1>MOTIVOS PARA ESCOLHER O TÂMIA</h1>
            </header>
            <div id='cards' className='card-container'>
              <div className='card'>
                <h2 class='card-title'>Tudo em um só lugar</h2>
                <p>
                  Armazene, gerencie e organize <br />
                  seus arquivos digitais em um
                  <br />
                  único sistema, construindo
                  <br /> assim um arquivo central
                </p>
              </div>
              <div className='card'>
                <h2 class='card-title'>Acesso total</h2>
                <p>
                  Veja, baixe e analise seus <br />
                  documentos a partir de <br />
                  qualquer computador, tablet ou <br />
                  smartphone
                </p>
              </div>
              <div className='card'>
                <h2 class='card-title'>Garanta a segurança</h2>
                <p>
                  Seus documentos e dados <br />
                  estarão criptografados e <br />
                  armazenados com extrema <br />
                  segurança
                </p>
              </div>
              <div className='card'>
                <h2 class='card-title'>Controle de acesso</h2>
                <p>
                  Restrinja o acesso aos <br />
                  arquivos de acordo com a<br /> hierarquia de perfil de cada
                  <br /> usuário, e evite vazamentos
                </p>
              </div>
              <div className='card'>
                <h2 class='card-title'>Mais organização</h2>
                <p>
                  Crie categorias personalizadas <br />
                  para classificar e use a<br />
                  indexação para localizar com <br />
                  mais rapidez
                </p>
              </div>
              <div className='card'>
                <h2 class='card-title'>Máxima segurança</h2>
                <p>
                  Utilizamos a nuvem global
                  <br /> da AWS, com backup em <br />
                  servidores locais ao redor
                  <br /> do mundo
                </p>
              </div>
              <Link
                className='button'
                onClick={() => {
                  history.push('/cadastro');
                  window.scrollTo(0, 0);
                }}
              >
                QUERO O TÂMIA
              </Link>
            </div>
          </div>
        </section>

        <section id='blog' ref={blogRef}>
          <div class='wrapper'>
            <h3>Blog</h3>
            <h1>Arquivo Inteligente</h1>
            <h4>
              O seu portal de informações sobre arquivo empresarial e gestão de
              documentos
            </h4>
          </div>
          <div class='image-row'>
            <div class='image-container'>
              <img src={ImgBlog1} alt='' />
              <p>Documentos empresariais: o que guardar e por quanto tempo?</p>
              <a href='/post/1' target='_blank'>
                Leia mais
              </a>
            </div>
            <div class='image-container'>
              <img src={ImgBlog2} alt='' />
              <p>
                O que considerar na hora de escolher um software de GED para a
                sua empresa
              </p>
              <a href='/post/2' target='_blank'>
                Leia mais
              </a>
            </div>
            <div class='image-container'>
              <img src={ImgBlog3} alt='' />
              <p>
                Digitalizar documentos físicos: como essa prática pode facilitar
                o acesso e a organização da sua empresa
              </p>
              <a href='/post/3' target='_blank'>
                Leia mais
              </a>
            </div>
          </div>
        </section>

        <section id='faq' ref={faqRef}>
          <div className='wrapper'>
            <header>
              <h1>Perguntas frequentes</h1>
            </header>
            <Dropdown ghost accordion expandIconPosition='end'>
              {faqData.slice(0, 5).map((data, idx) => (
                <DropdownPanel
                  header={
                    <strong style={{ fontWeight: 500 }}>{data.header}</strong>
                  }
                  key={idx}
                >
                  <p>{data.body}</p>
                </DropdownPanel>
              ))}
            </Dropdown>
            <div className='more-faq-container'>
              <Link to='/faq' id='more-faq'>
                VER MAIS
              </Link>
            </div>
          </div>
        </section>

        <a id='backToTopButton' href='/#home'>
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='20' cy='20' r='20' fill='#9cdb37' />
            <path
              d='M20 27V13'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M13 20L20 13L27 20'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </a>
        <Footer />
        <CookieConsent
          location='bottom'
          buttonText='ENTENDI'
          cookieName='myAwesomeCookieName2'
          style={{ background: '#212112', padding: '10px 20px' }}
          buttonStyle={{
            color: '#4e503b',
            background: '#D0FA94',
            fontWeight: '700',
            fontSize: '13px',
            padding: '10px',
            borderRadius: '10px',
          }}
          expires={300}
        >
          <h2 style={{ color: '#fff' }}> Aviso sobre cookies</h2>Usamos cookies
          para garantir que oferecemos a melhor experiência em nosso site.
          Clique em "Entendi" para concordar.{' '}
          <a onClick={openModal}> Política de cookies</a>
        </CookieConsent>
        <Modal showModal={showModal} closeModal={closeModal} />
      </div>
    </>
  );
};
