import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.variable.min.css';

export const GlobalStyle = createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --reactour-accent:#9cdb37;

  .ant-btn-primary {
    border-radius:30px;
    color: #21211a;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
  .ant-btn-primary:hover {
    color: #21211a;
  }

  .ant-btn-default {
    border-radius:30px;
  }

  .anticon svg {
    color:#9cdb37;
  }

  .ant-btn>span{
    color:#212112;
  }

  .ant-btn-default:hover {
    color: #21211a;
    opacity:0.8;
  }

  .ant-input{
    border-color:#ccc;
  }
  .ant-input-focused{
    border-color:#ccc;
  }

  .reactour__popover {
    border-radius:10px;
  } 
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background: #f6faf5;
  -webkit-font-smoothing: antialiased;
}

html, body{
  font-family: 'Poppins', sans-serif;
  height: 100%;
  margin: 0 auto; 
}

body, input, button {
  font: 16px;
}
body, input, textarea, button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

input{ 
  font-size: 15px;
  padding: 10px;
  width: 350px;
  text-align: start;

  ::placeholder {
    color: #a2a2a2 !important;
    font-style: italic;
  }
}


h1, h2, h3, h4, h5, h6, strong {
  font-weight: 500;
}
[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

button{
  cursor: pointer;
}
`;
