import React from 'react';
import styled, { keyframes } from 'styled-components';
import Form from '../Blog/Form';
import Logo from '../Landing Page/assets/tamiaBlack.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #d0fa94;
  padding: 10px;
  height: 20rem;
  width: 30rem;
  border-radius: 10px;
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;

const CloseButton = styled.button`
  position: relative;
  left: 210px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #212121;

  @media (max-width: 500px) {
    left: 150px;
    top: 5px;
  }
`;

const ModalHeader = styled.h2`
  display: flex;
  font-size: 20px;

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

const Modal = ({ showModal, closeModal }) => {
  return (
    <>
      {showModal && (
        <ModalBackground>
          <ModalContainer show={showModal}>
            <CloseButton onClick={closeModal}>X</CloseButton>
            <ModalHeader>
              <img
                src={Logo}
                style={{
                  width: '3rem',
                  paddingBottom: '8px',
                  paddingRight: '10px',
                }}
              />{' '}
              Cadastre-se na Newsletter
            </ModalHeader>
            <Form />
          </ModalContainer>
        </ModalBackground>
      )}
    </>
  );
};

export default Modal;
