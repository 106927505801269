import React from 'react';
import ModalShowDocument from '../../Modals/ModalViewDocuments/index';
import ModalDelete from '../../Modals/ModalDocumentAlertDelete';
import {
  AiOutlineEye,
  AiOutlineFileExcel,
  AiOutlineFileJpg,
} from 'react-icons/ai';
import { ButtonGroup } from './style';
import { ExportReactCSV } from 'components/ExportReactCSV';
import { Flex } from 'components/Flex';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { IoCloudOfflineOutline, IoImage } from 'react-icons/io5';
import { Colors } from 'common/theme/colors';
import { CgAttachment } from 'react-icons/cg';
import TamiaTableIcon from 'components/Icons/TamiaTableHome';

const toLocalDate = (date) => {
  if (!date) return '';
  const formated = new Date(date).toLocaleDateString('pt-BR');

  if (formated == 'Invalid Date') return '';
  return formated;
};

export const columns = (categoryNames, csvData) => {
  const categoryColumns = categoryNames.map((category, idx) => ({
    title: category,
    align: 'center',
    key: category,
    render: (_, record) => <span>{record.categories?.[idx]?.name}</span>,
    width: 145,
    ellipsis: true,
  }));

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 43,
      align: 'center',
    },
    {
      title: 'Anexo',
      dataIndex: 'cloud',
      key: 'status',
      width: 60,
      ellipsis: true,
      align: 'center',
      render: (_, record) => {
        return record.files?.length ? (
          <TamiaTableIcon
            color={"#9CDB37"}
            size={30}
          />
        ) : (
          <IoCloudOfflineOutline size={18} />
        );
      },
    },
    ...categoryColumns,
    {
      title: 'Descrição',
      align: 'center',
      dataIndex: 'description',
      key: 'description',
      width: 215,
      ellipsis: true,
    },

    {
      width: 118,
      align: 'center',
      title: (
        <Flex justify='center' align='center'>
          <ThemedTooltip
            placement='top'
            title='Data de referência do período do documento (única ou inicial)'
            showInformationIcon
          >
            Data Início
          </ThemedTooltip>
        </Flex>
      ),
      dataIndex: 'period_start',
      key: 'documentStart',
      render: (text) => <span> {toLocalDate(text)}</span>,
    },
    {
      width: 105,
      align: 'center',
      title: (
        <Flex justify='center' align='center'>
          <ThemedTooltip
            placement='left'
            title='Data de referência do período final do documento (quando diferente da data
                    inicial)'
            showInformationIcon
            width='400px'
          >
            Data Fim
          </ThemedTooltip>
        </Flex>
      ),
      dataIndex: 'period_end',
      key: 'documentEnd',
      render: (text) => <span> {toLocalDate(text)}</span>,
    },

    {
      width: 100,
      fixed: 'right',
      title: (
        <Flex justify='center' align='center'>
          Ações
          <ThemedTooltip
            placement='topRight'
            title='Exportar a lista para uma planilha'
          >
            <ExportReactCSV
              csvData={csvData}
              fileName='relatorio_doc_tamia.csv'
            />
            {''}
          </ThemedTooltip>
        </Flex>
      ),
      key: 'action',
      render: (_, record) => {
        return (
          <ButtonGroup>
            <ModalShowDocument
              document={record}
              editDocument={true}
              Icon={AiOutlineEye}
            />
            <ModalDelete document={record} />
          </ButtonGroup>
        );
      },
    },
  ];
};
