import { BaseApi } from 'core/api';

export class CategoriesApi extends BaseApi {
  constructor(system, userId) {
    super(system, userId);
    this.system = system;
    this.userId = userId;
    this.setUrl(`categories`);
  }
  async fetchCategories() {
    return this.get();
  }

  async editCategorySettings(category) {
    this.setUrl(`${this.url}/settings/${category._id}`);
    return this.put(category);
  }
  async createCategory(category) {
    return this.post(category);
  }

  async updateCategory(category) {
    this.setUrl(`${this.url}`);
    return this.put(category);
  }

  async deleteCategory(id) {
    this.setUrl(`${this.url}/${id}`);
    return this.delete();
  }
}
