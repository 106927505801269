import React from "react";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";

export const ExportReactCSV = ({ csvData, fileName }) => {
  return (
    <CSVLink data={csvData} separator=";" filename={fileName}>
      <SiMicrosoftexcel
        style={{
          color: "#008035",
          cursor: "pointer",
          fontSize: "20px",
          marginLeft: "15px",
          marginBottom: "-5px",
        }}
      />
    </CSVLink>
  );
};
