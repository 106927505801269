import styled from 'styled-components';
import { Flex } from 'components/Flex';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #21211a;
  width: 100vw;
  height: 100vh;
`;

export const Text = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0fcd6;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;

  &&:hover {
    color: #9cdb37;
    transition: 0.3s;
  }
`;

export const InputContainer = styled(Flex)`
  max-width: 370px;
  width: 100%;
  background-color: #f0fcd6;
  border-color: #9cdb37;
  margin: 10px 0;
  height: 50px;
  border-radius: 30px;
  align-items: center;
  padding: 0 0.5rem;

  input {
    line-height: 1;
    font-weight: 500;
    font-size: 1.1rem;
    color: #777;

    ::placeholder {
      color: #aaa;
      font-weight: 500;
      font-size: 1rem;
    }
  }
`;
export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0fcd6;
  text-align: center;
  font-size: 35px;
  margin-bottom: 29px;
`;

export const Submit = styled.input`
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  max-width: 370px;
  width: 100%;
  background-color: #9cdb37;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 10px;
  color: #f2f2f2;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 2rem 0 1rem 0;
  transition: 0.5s;
  cursor: pointer;

  &&:hover {
    background-color: #9cdb37;
    transition: 0.2s;
  }
`;
