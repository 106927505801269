import React, { useEffect, useState } from 'react';
import { Text } from './style';
import ViewDocuments from 'pages/documents/view';
import { FiDownload } from 'react-icons/fi';
import { HiOutlineDocument } from 'react-icons/hi';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { Routes } from 'common/enums/routes.enum';
import { RiEditLine } from 'react-icons/ri';
import { ButtonSearch } from 'components/Buttons';
import { Drawer } from 'antd';
import { Flex } from 'components/Flex';
import { useReduxState } from 'common/hooks/use-redux-state';
import { useReduxAction } from 'common/hooks/use-redux-action';
import {
  getDocumentLinks,
  getDriveDocumentLinks,
} from 'common/redux/cloud/thunks';
import {
  getFileLinks,
  isCloudLoading,
} from 'common/redux/cloud/selectors';
import { getUserIsOperator } from 'common/redux/auth/selectors';
import { getCloudStatus } from 'common/redux/cloud/selectors';
import { useHistory } from 'react-router-dom';
import { generateIndex } from 'common/utilities/generateIndex';
import { clearDriveFileLinks } from 'common/redux/cloud/reducers';
import ThemedButton from 'components/Themed/ThemedButton';
import { useWindowSize } from 'common/hooks/use-window-size';
import { FooterContainer } from 'components/Layout/FooterContainer';

function ViewDocumentModal({ document, editDocument, Icon }) {
  const { isMobileScreen, isSmallScreen } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [shouldLoadDocument, setShouldLoadDocument] = useState(false);
  const cloudStatus = useReduxState(getCloudStatus);
  const userIsOperator = useReduxState(getUserIsOperator);
  const fetchFileLinks = useReduxAction(getDocumentLinks);
  const resetDriveLinks = useReduxAction(clearDriveFileLinks);
  const isLoading = useReduxState(isCloudLoading);
  const history = useHistory();
  const fileLinks = useReduxState(getFileLinks);

  const handleOpen = () => {
    const shouldLoadDocument = document?.files?.length;
    setShouldLoadDocument(shouldLoadDocument);
    setOpen(true);
  };

  useEffect(() => {
    // Checks document state in case of document update while modal is open
    if (open) {
      handleOpen();
    }
  }, [document?.cloud?.type]);

  const handleClose = () => {
    resetDriveLinks();
    setShouldLoadDocument(false);
    setOpen(false);
  };
  const redirect = () => {
    history.push({ pathname: Routes.DOCUMENTS, state: { document } });
  };

  useEffect(() => {
    if (shouldLoadDocument) {
      fetchFileLinks(document._id);
    }
  }, [shouldLoadDocument]);

  return (
    <div>
      <ButtonSearch
        onClick={handleOpen}
        style={{ backgroundColor: !editDocument && 'transparent' }}
      >
        <ThemedTooltip placement='topRight' title='Visualizar detalhes'>
          <Icon size={20} />
        </ThemedTooltip>
      </ButtonSearch>
      <Drawer
        width={isMobileScreen ? '100vw' : '42rem'}
        onClose={handleClose}
        open={open}
        bodyStyle={{ paddingBottom: 20 }}
        centered
        title={
          <Flex align='center' justify='space-around' direction='row'>
            <h5 style={{ display: 'flex', gap: '5px' }}>
              ID: <Text>{document.id}</Text>
            </h5>
            {!isSmallScreen && <h3> Detalhes do documento </h3>}
            <h5 style={{ display: 'flex', gap: '15px' }}>
              Código: <Text>{generateIndex(document)}</Text>
            </h5>
          </Flex>
        }
        footer={[
          <FooterContainer>
            <ThemedButton
              color='#f0fcd6'
              style={{ borderColor: '#9cdb37' }}
              disabled={!shouldLoadDocument}
              title={
                !shouldLoadDocument &&
                document.cloud?.type &&
                'Documento pertence a outro repositório'
              }
              loading={isLoading}
              href={fileLinks?.view}
              target='blank'
            >
              <HiOutlineDocument size={15} style={{ marginRight: 3 }} />
              Visualizar
            </ThemedButton>
            <a
              target='_blank'
              href={fileLinks?.download}
              download='filename.png'
            >
              <ThemedButton
                color='#d0fa94'
                style={{ borderColor: '#9cdb37' }}
                disabled={!shouldLoadDocument}
                title={
                  !shouldLoadDocument &&
                  document.cloud?.type &&
                  'Documento pertence a outro repositório'
                }
                loading={isLoading}
                target='blank'
              >
                <FiDownload size={15} style={{ marginRight: 5 }} />
                Download
              </ThemedButton>
            </a>
            <ThemedButton
              color='#9cdb37'
              onClick={redirect}
              disabled={userIsOperator}
            >
              <RiEditLine
                size={15}
                style={{ marginRight: '6px', marginTop: '2px' }}
              />
              Editar
            </ThemedButton>
          </FooterContainer>,
        ]}
      >
        <ViewDocuments document={document} />
      </Drawer>
    </div>
  );
}

export default ViewDocumentModal;
