import { ThemedSelect } from '../Themed/ThemedSelect';
import { matchSorter } from 'match-sorter';
import { Flex } from 'components/Flex';
import { Empty } from 'antd';
import ThemedButton from 'components/Themed/ThemedButton';

const { Option } = ThemedSelect;

export const Select = ({
  selectOptions,
  customEmptyMessage,
  customValueField = '_id',
  onSelectAll = null,
  value,
  ...rest
}) => {
  let validKeys = [];
  const allValues = selectOptions?.map((option) => option[customValueField]);
  return (
    <ThemedSelect
      showSearch
      allowClear
      placeholder={'Selecione...'}
      optionLabelProp='name'
      notFoundContent={
        customEmptyMessage ? <>{customEmptyMessage}</> : <Empty />
      }
      onSearch={(input) => {
        const searchResult = matchSorter(selectOptions, input, {
          keys: ['name', 'alias_name'],
        });
        validKeys = searchResult.map((res) => res.id.toString());
      }}
      filterOption={(_, option) => {
        return validKeys.includes(option.key);
      }}
      dropdownRender={(menu) => {
        return onSelectAll ? (
          <>
            {menu}
            {selectOptions && (
              <ThemedButton
                style={{ width: 55, height: 30, margin: '0 5px 5px auto' }}
                type='primary'
                onClick={() =>
                  onSelectAll(
                    value?.length === allValues.length ? [] : allValues
                  )
                }
              >
                Todos
              </ThemedButton>
            )}
          </>
        ) : (
          <>{menu}</>
        );
      }}
      value={value}
      {...rest}
    >
      {selectOptions?.map((option) => {
        return (
          <Option
            value={option?.[customValueField]}
            name={option.name}
            key={option.id}
            disabled={option.disabled}
          >
            <Flex direction='column'>
              <span style={{ fontWeight: 700 }}>{option.name}</span>
              <span style={{ fontSize: 13, marginLeft: 2 }}>
                {option.alias_name?.join('; ')}
              </span>
            </Flex>
          </Option>
        );
      })}
    </ThemedSelect>
  );
};
