export default [
  {
    selector: "[data-tour='home']",
    content:
      ' Os documentos recém cadastrados serão exibidos nesta tela. Aqui você também visualiza o resultado de uma pesquisa, adiciona novos documentos e em "Ações" encontra importantes funções.',
    position: 'right',
  },
  {
    selector: "[data-tour='listas']",
    content:
      'A categoria é separada por hierarquia, logo, você pode cadastrar o responsável pelo documento, para que as opções sejam apresentadas parametrizadas ao adicionar um novo documento. Aqui você também visualiza as opções já cadastradas, adiciona novas e em "Ações" encontra importantes funções. ',
    position: 'right',
  },
  {
    selector: "[data-tour='usuarios']",
    content:
      'Os usuários que tem acesso ao sistema serão exibidos nessa tabela de acordo com seu perfil. Os usuários com permissão poderão editar as permissão de visualização de outros usuários bem como enviar convite para que mais usuários possam utilizar o sistema. O limite de usuários depende do plano contratado.',
    position: 'right',
  },
  {
    selector: "[data-tour='FAQ']",
    content:
      'Caso tenha mais dúvidas de como usar o Tâmia, só pesquisar aqui. Tem vários tutoriais pra te ajudar',
    position: 'right',
  },
  {
    selector: "[data-tour='ajuda']",
    content:
      'Se desejar rever esse passo a passo, basta entrar aqui e selecionar a opção "Primeiros passos".',
    position: 'right',
  },
];
