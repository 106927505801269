import styled from 'styled-components';
import { Modal } from 'antd';

export const TextArea = styled.textarea`
  width: 100%;
  height: 200px;

  font-size: 14px;
  background: #eee;
  color: #121214;
  padding: 0.75em 1em;
  border-radius: 5px;
  resize: none;
  outline: 0px;
  border: 1px solid #ccc;
`;

export const Text = styled.h3`
  display: flex;
  border-radius: 10px;
  color: #000 !important;
  align-items: center;
  justify-content: start;
  margin-left: 1.2rem;
  font-weight: 600;
  font-size: 14px;
  color: #121214;
`;

export const AntModal = styled(Modal)`
  .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
  }
  .ant-btn-primary {
    border-radius: 30px;
    background-color: #9cdb37;
    border-color: #9cdb37;
    color: #21211a;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .ant-btn-primary:hover {
    opacity:0.8;
  }

  .ant-btn-default {
    border-radius: 30px;
    background-color: transparent;
    color:#ff4747;
    border-color: #ff4747;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .ant-btn-default:hover {
    opacity:0.8;
  }
  .ant-modal-header {
    margin: 0 20px;
    border-bottom: 2px solid #9cdb37;
  }
  .ant-modal-footer {
    margin-right: 0.7rem;
    border-top: none;
    padding: 1rem;
  }
  .ant-modal-body {
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 430px;
  }
`;
