const s = (state) => state.users;

export const getUsers = (state) => s(state).data || [];

export const getInvites = (state) => s(state).invites || [];

export const getIsManagingUsers = (state) => s(state).isManagingUsers || false;

export const getUserCreatedSuccess = (state) =>
  s(state).userCreatedSuccess || false;
