import { Tooltip } from 'antd';
import { useWindowSize } from 'common/hooks/use-window-size';
import { Flex } from 'components/Flex';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export const ThemedTooltip = ({
  width = '600px',
  showInformationIcon = false,
  informationIconMargin = 0,
  children,
  title,
  ...props
}) => {
  const { isTouchDevice } = useWindowSize(false);
  return (
    <Flex align="center" style={{ width: 'auto' }}>
      {showInformationIcon && children}
      <Tooltip
        overlayStyle={{
          maxWidth: width,
        }}
        overlayInnerStyle={{ borderRadius: '10px' }}
        arrowPointAtCenter
        title={title}
        trigger={isTouchDevice ? 'none' : 'hover'}
        {...props}
      >
        {showInformationIcon ? (
          <IoMdInformationCircleOutline
            style={{
              color: '#555',
              width: 23,
              marginLeft: informationIconMargin,
            }}
            size={18}
          />
        ) : (
          children
        )}
      </Tooltip>
    </Flex>
  );
};
