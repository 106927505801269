import { Toast } from 'common/utilities/createToast';
import { BaseApi } from 'core/api';
import { PusherConnection } from 'core/pusher/pusher';
window.Buffer = window.Buffer || require('buffer').Buffer;
const pusher = PusherConnection.shared;

export class DocumentsApi extends BaseApi {
  constructor(system, userId) {
    super(system, userId);
    this.system = system;
    this.userId = userId;
    this.setUrl(`documents`);
  }
  async getDocuments() {
    return await this.get();
  }
  async getDocument(documentId, key = '') {
    const query = key ? `?key=${key}` : ''
    this.setUrl(`${this.url}/${documentId}${query}`);
    return await this.get();
  }

  startProgressBar() {
    Toast.updateProgress(0, 'Preparando documento...');
  }

  async createCloudDocument(requestParams) {
    this.setUrl('documents');

    const { files, ...rest } = requestParams;
    const hasFiles = !!files?.length && !!files[0];
    let fileData = {};

    if (hasFiles) {
      const file = files[0];
      this.startProgressBar();
      fileData = {
        path: file.path,
        size: file.size,
        type: file.type,
      };
    }

    const { document_period } = requestParams;

    const payload = {
      ...rest,
      system: this.system,
      period_start: document_period?.[0] || null,
      period_end: document_period?.[1] || null,
      files: hasFiles ? [fileData] : null,
      userId: this.userId,
      newFile: !!hasFiles,
    };

    const formData = new FormData();

    formData.append('userId', this.userId);
    formData.append('document', JSON.stringify(payload));

    if (hasFiles) {
      formData.append('file', files[0]);
    }
    const headers = {
      'Content-Type': `multipart/form-data; Boundary=${formData._boundary}`,
    };
    return this.post(formData, {
      headers,
    });
  }

  async updateCloudDocument(requestParams) {
    this.setUrl('documents');

    const { files, ...rest } = requestParams;
    const hasFiles = !!files?.length && !!files[0];
    const newFile = files?.[0].lastModified;
    let fileData = {};

    if (hasFiles) {
      const file = files[0];
      if (newFile) {
        this.startProgressBar();
      }
      fileData = {
        path: file.path,
        size: file.size,
        type: file.type,
      };
    }

    const { document_period } = requestParams;

    const payload = {
      ...rest,
      system: this.system,
      period_start: document_period?.[0] || null,
      period_end: document_period?.[1] || null,
      files: hasFiles ? [fileData] : null,
      userId: this.userId,
      newFile,
    };

    const formData = new FormData();
    formData.append('userId', this.userId);
    formData.append('document', JSON.stringify(payload));

    if (hasFiles) {
      formData.append('file', files[0]);
    }
    const headers = {
      'Content-Type': `multipart/form-data; Boundary=${formData._boundary}`,
    };

    return this.put(formData, {
      headers,
    });
  }

  async createDocument(requestParams) {
    const { files, ...rest } = requestParams;
    const hasFiles = !!files?.length && !!files[0];
    let fileData = {};

    if (hasFiles) {
      const file = files[0];
      fileData = {
        path: file.path,
        size: file.size,
        type: file.type,
        storage: 'drive',
      };
    }

    const payload = {
      ...rest,
      owner: requestParams.owner,
      sector: requestParams.sector,
      doctype: requestParams.doctype,
      system: this.system,
      document_period: {
        start: requestParams.document_period?.[0],
        end: requestParams.document_period?.[1],
      },
      physical_storage: {
        stored: !!requestParams.location,
        location: requestParams.location,
      },
      discard_period: {
        physical: requestParams.physicalDiscardDate,
        digital: requestParams.digitalDiscardDate,
      },
      files: hasFiles ? [fileData] : null,
    };

    const { data: createdDocument } = await this.post(payload);
    if (hasFiles) {
      const formData = new FormData();
      formData.append('id', createdDocument.insertedId);
      formData.append('userId', this.userId);
      formData.append('file', files[0]);

      this.setUrl('cloud');
      const headers = {
        'Content-Type': `multipart/form-data; Boundary=${formData._boundary}`,
      };

      return this.post(formData, headers);
    }

    return createdDocument;
  }

  async updateDocument(requestParams, documentId) {
    this.setUrl(`${this.url}/${documentId}`);
    const { files } = requestParams;

    const hasFiles = !!files?.length && !!files[0];
    let fileData = {};

    if (hasFiles) {
      const file = files[0];
      fileData = {
        path: file.path,
        size: file.size,
        type: file.type,
        storage: 'drive',
      };
    }

    const payload = {
      ...requestParams,
      owner: requestParams.owner,
      sector: requestParams.sector,
      doctype: requestParams.doctype,
      system: this.system,
      document_period: {
        start: requestParams.document_period?.[0],
        end: requestParams.document_period?.[1],
      },
      physical_storage: {
        stored: !!requestParams.physicalLocation,
        location: requestParams.physicalLocation,
      },
      discard_period: {
        physical: requestParams.physicalDiscardDate,
        digital: requestParams.digitalDiscardDate,
      },
      files: hasFiles ? [fileData] : null,
    };
    const { data: updatedDocument } = await this.put(payload);

    if (files?.length && files[0]?.lastModified) {
      const formData = new FormData();
      formData.append('id', documentId);
      formData.append('userId', this.userId);
      formData.append('file', files[0]);

      this.setUrl('cloud');
      const headers = {
        'Content-Type': `multipart/form-data; Boundary=${formData._boundary}`,
      };

      return this.post(formData, headers);
    }

    return updatedDocument;
  }

  async deleteDocument(documentIds, key) {
    this.setUrl(`documents/?ids=${documentIds}&key=${key}`);
    return this.delete();
  }

  async exportDocuments(documentIds = []) {
    this.setUrl('documents/export');
    const payload = { documentIds };
    console.log(`~  payload: `, payload)
    return this.post(payload);
  }
}
