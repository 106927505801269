import React from 'react';
import { BsHandIndexThumb, BsInstagram } from 'react-icons/bs';
import Logo from '../../assets/images/tamia_header_black.png';

import { ContainerCards, NavLink, Container, ImageContainer } from './style';
import { RiLinkedinFill, RiYoutubeLine } from 'react-icons/ri';
import { FaBlog, FaFileContract, FaRegNewspaper } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';

export default () => {
  return (
    <>
      <Container>
        <ImageContainer>
          <img className='logo-img' src={Logo} alt='Tâmia' />
        </ImageContainer>
        <ContainerCards>
          <NavLink href='https://www.tamia.app/'>
            <BsHandIndexThumb style={{ marginLeft: '1rem' }} />
            <strong>Acesse nosso site</strong>
          </NavLink>
          <NavLink href='/cadastro'>
            <FaFileContract style={{ marginLeft: '1rem' }} />
            <strong>Contrate o Tâmia</strong>
          </NavLink>
          <NavLink href='https://wa.me/5521968996222'>
            <MdOutlineSupportAgent style={{ marginLeft: '1rem' }} />
            <strong>Fale com a gente</strong>
          </NavLink>
          <NavLink href='https://www.linkedin.com/company/t%C3%A2mia/'>
            <RiLinkedinFill style={{ marginLeft: '1rem' }} />
            <strong>Conecte-se com a gente no LinkedIn</strong>
          </NavLink>
          {/* <NavLink href='www.youtube.com.br'>
            <RiYoutubeLine style={{ marginLeft: '1rem' }} />
            <strong>Assine nosso canal no Youtube</strong>
          </NavLink> */}
          <NavLink href='/blog'>
            <FaBlog style={{ marginLeft: '1rem' }} />
            <strong>Leia nosso blog</strong>
          </NavLink>
          <NavLink href='https://www.instagram.com/tamia.app/'>
            <BsInstagram style={{ marginLeft: '1rem' }} />
            <strong>Siga a gente no Instagram</strong>
          </NavLink>
        </ContainerCards>
      </Container>
    </>
  );
};
