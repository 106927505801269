import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
  withCredentials: true,
});

export default API;

export class BaseApi {
  constructor(system, userId) {
    this.baseUrl = process.env.REACT_APP_BACKEND_URI;
    this.api = API;
    this.setRequestHeaders({ system, userId });
  }

  setUrl(url) {
    this.url = url;
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  setRequestHeaders(headers) {
    this.api.defaults.headers = { ...this.api.defaults.headers, ...headers };
  }

  handleError(error) {
    const response = error?.response;
    const message = response?.data?.message || response?.data?.error;

    if (Array.isArray(message)) throw new Error(message[0].msg);

    throw new Error(
      message || 'Ocorreu um erro inesperado ao realizar esta ação'
    );
  }

  async get(config) {
    try {
      return await this.api.get(this.baseUrl + this.url, config);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async post(params, config) {
    try {
      return await this.api.post(this.baseUrl + this.url, params, config);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async put(params, config) {
    try {
      return await this.api.put(this.baseUrl + this.url, params, config);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async delete(config) {
    try {
      return await this.api.delete(this.baseUrl + this.url, config);
    } catch (error) {
      return this.handleError(error);
    }
  }
}
