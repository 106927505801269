import styled from 'styled-components';
import { Flex } from 'components/Flex';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px;

  textarea {
    border: 1px solid #9cdb37;
    border-radius: 20px;
  }
  textarea::placeholder {
    font-style: italic;
  }

  textarea:hover {
    border: 1px solid #9cdb37;
  }

  @media screen and (max-width: 1198px) {
    flex-wrap: wrap;
  }
`;

export const ContentForm = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 15px;
  margin: 20px 5px 2px 5px;
`;

export const SubTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: start;
  color: #4c4c4c;
  margin: 10px;
`;

export const ContentTable = styled.div`
  table,
  td,
  th {
    border-bottom: 1px solid #ddd;
  }
  .td-left {
    text-align: left;
    padding-left: 7px;
    font-weight: 300;
  }
  .td-right {
    font-weight: 700;
    text-align: right;

    white-space: nowrap;
    max-width: 28ch;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 2px;
  }
`;

export const AddNewList = styled.button`
  padding: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #9cdb37;
  color: #21211a;
  margin: 10px 0 0 0;
  width: 100%;
  height: 2rem;
  border: none;
  font-weight: 600;
  &&:hover {
    background-color: #d0fa94;
    transition: 0.2s;
  }
`;

export const ContainerRightForm = styled(Flex)`
  flex: 2;
  flex-direction: column;
  padding: 20px 30px;
  margin: 2.5rem 9.5%;
  background-color: #ddfcb1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px 0px;
  max-width:40rem;

  @media screen and (max-width: 700px) {
    margin: 0;
  }
`;
export const Subtitles = styled.p`
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid #9cdb37;
`;
