import { createSlice } from '@reduxjs/toolkit';
import { fetchAuditData, fetchInitialData, updateSystemInfo } from './thunks';

const systemInfoSlice = createSlice({
  name: 'systemInfo',
  initialState: {
    _id: null,
    email: null,
    createdAt: null,
    repositories: null,
    roleId: null,
    updatedAt: null,
    accountOwner: null,
    payment: null,
    stats: { tamia: { amount: 0, usage: 0 }, drive: { amount: 0, usage: 0 } },
    audit: null,
  },
  reducers: {
    updateInfo(state, action) {
      return { ...action.payload };
    },

    updateStats(state, action) {
      const { usage, totalDocuments } = action.payload;
      state.stats[usage.location] = usage.stats;
      state.stats.totalDocuments = totalDocuments;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSystemInfo.fulfilled, (state, action) => {
        return { ...action.payload, loading: false };
      })
      .addCase(updateSystemInfo.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(updateSystemInfo.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchInitialData.fulfilled, (state, action) => {
        state.stats = action.payload.stats;
        state.payment = action.payload.payment;
        state.plan = undefined;
        state.loading = false;
      })
      .addCase(fetchInitialData.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(fetchInitialData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAuditData.fulfilled, (state, action) => {
        state.audit = action.payload;
        state.loading = false;
      })
      .addCase(fetchAuditData.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      .addCase(fetchAuditData.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { updateInfo, updateStats } = systemInfoSlice.actions;

export const actions = systemInfoSlice.actions;

export default systemInfoSlice.reducer;
