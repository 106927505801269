export const FileTypes = {
  CSV:'csv',
  DOC:'doc',
  JPEG:'jpeg', 
  JPG:'jpg',
  PDF:'pdf',
  PNG:'png',
  PPT:'ppt',
  SVG:'svg',
  XLS:'xls',
};
