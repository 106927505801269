export const UserTypes = {
  GET_USERS_REQUEST: "users/getUsers",

  CREATE_USER_REQUEST: "users/createUser",

  REGISTER_USER_REQUEST: "users/registerUser",

  UPDATE_USER_REQUEST: "users/updateUser",

  DELETE_USER_REQUEST: "users/deleteUser",

  GET_INVITES_REQUEST: "users/getInvites",
  CREATE_INVITES_REQUEST: "users/createInvites",
  DELETE_INVITES_REQUEST: "users/deleteInvites",
  RESEND_INVITES_REQUEST: "users/resendInvites",
  REGISTER_CLIENT_REQUEST:"mail/form",
  REGISTER_CLIENT_REQUEST_NEWSLETTER:"mail/newsletter"
};
