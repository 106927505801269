import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#9CDB37';
};

export const Container = styled.div`
  display: flex;
  margin: 2.5rem 9.5%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #ffffff;
  color: #636260;
  min-width: 290px;
  max-width: 40.5rem;
  cursor: pointer;
  text-align: center;
  border-width: 1px;
  border-radius: 0px;

  &:hover {
    color: #21211c;
    background-color: #f0fcd6;
    transition: 0.2s;
  }
`;

export const Content = styled.div`
  border: none;
  color: #212112;
  margin: 5px;
  padding: 5px;

  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: center;
    padding: 8px;
  }
`;

export const ContentTable = styled.div`
  table,
  td,
  th {
    border-bottom: 1px solid #ddd;
  }
  #td-left {
    color: #212112;
    text-align: left;
    font-weight: 500;
    width: 50%;
  }
  #td-right {
    color: #212112;
    font-weight: 700;
    text-align: left;
    white-space: nowrap;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 5px;
  }

  #first-table {
    margin-bottom: 0.5rem;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #212112;
`;

export const ContainerTable = styled.div`
  background-color: #fff;
  padding: 10px;
  max-width: 40rem;
  min-width: 17rem;
  margin: 0 9.5%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px 0px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: space-around;
  flex-direction: row;
  padding: 8px;
`;

export const ButtonClear = styled.button`
  color: #212112;
  background-color: #fff;
  border: 1px solid red;
  margin: 5px;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 20px;
  font-weight: 600;
  &:hover {
    color: red;
    transition: 0.3s;
  }
`;

export const SpanAttachment = styled.span`
  display: flex;
  margin: 5px;
  margin-top: 15px;
  justify-content: left;
  align-items: left;
  font-size: 12px;
`;
