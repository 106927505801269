import { createSlice } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
  name: 'filters',
  initialState: { globalFilter: null, advancedFilters: null, auditFilters: null },
  reducers: {
    setAdvancedFilters(state, action) {
      const { filters } = action.payload;
      const updatedFilters = {
        ...state.filters,
        ...filters,
      };
      state.advancedFilters = updatedFilters;
    },
    clearFilter(state, action) {
      const { key } = action.payload;
      state[key] = null;
    },
    setGlobalFilter(state, action) {
      const { filter } = action.payload;
      state.globalFilter = filter;
    },
    setAuditFilters(state, action) {
      const { filters } = action.payload;
      state.auditFilters = filters;
    },
  },
});
export const actions = filtersSlice.actions;

export const { setAdvancedFilters, clearFilter, setAuditFilters, setGlobalFilter } =
  filtersSlice.actions;

export default filtersSlice.reducer;
