import { Select } from 'antd';
import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const ThemedSelect = styled(Select)`
  .ant-select-selector {
    display: flex;
    font-style: normal;
    align-items: center;
    min-height: 2.5rem !important;
    border-radius: 20px !important;
    width: 100%;
    border: 1px solid #9cdb37 !important;
  }

  .ant-select-selection-placeholder {
    color: #a2a2a2;
    font-style: normal !important;
  }
  .ant-select-selection-item {
    border-radius: 20px !important;
    font-weight: 500;
    margin-left: 5px;
  }
`;
const { Option } = ThemedSelect;

export const ThemedOption = ({ option }) => {
  return (
    <Option value={option._id} name={option.name} key={option.id}>
      <Flex direction='column'>
        <span style={{ fontWeight: 700 }}>{option.name}</span>
        <span style={{ fontSize: 13, marginLeft: 2 }}>
          {option.alias_name?.join('; ')}
        </span>
      </Flex>
    </Option>
  );
};
