export const getHighestId = (objectList) => {
  let highestId = 0;
  objectList?.forEach((object) => {
    if (object.id > highestId) {
      highestId = object.id;
    }
  });

  return highestId + 1;
};
