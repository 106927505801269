export const toLocalDate = (date) => {
  if (!date) return '';
  const formated = new Date(date).toLocaleDateString('pt-BR');

  if (formated == 'Invalid Date') return '';
  return formated;
};

export const toLocalDateTime = (date) => {
  if (!date) return '';
  const formated = new Date(date).toLocaleString('pt-BR');

  if (formated == 'Invalid Date') return '';
  return formated;
};
