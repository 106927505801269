import { createAsyncThunk } from '@reduxjs/toolkit';
import { Roles } from 'common/enums/roles.enum';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { createToast } from 'common/utilities/createToast';
import { UserService } from 'core/services/user.service';
import { attemptLogin } from '../auth/thunks';
import { UserTypes } from './types';

export const fetchUsers = createAsyncThunk(
  UserTypes.GET_USERS_REQUEST,
  async () => {
    const userService = new UserService();
    const response = await userService.getUsers();
    return response.data;
  }
);

export const registerUser = createAsyncThunk(
  UserTypes.REGISTER_USER_REQUEST,
  async (params, thunkApi) => {
    const userService = new UserService();
    const { payload } = params;

    const extraParams = {
      roleId: Roles.OWNER,
      ...payload,
    };
    try {
      await userService.registerUser(extraParams);
      thunkApi.dispatch(
        attemptLogin({ user: payload.email, password: payload.password })
      );
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

//Form new client
export const registerClient = createAsyncThunk(
  UserTypes.REGISTER_CLIENT_REQUEST,
  async (params, thunkApi) => {
    const userService = new UserService();
    const { payload } = params;

    try {
      await userService.registerClient(payload);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Enviado com sucesso, em breve entraremos em contato',
      });
    } catch (error) {
      const errorMessage =
        'Houve um erro ao enviar o formulário. Por favor, verifique os dados e tente novamente mais tarde';
      createToast({ type: toastTypes.ERROR, message: errorMessage });
      return thunkApi.rejectWithValue({ error: errorMessage });
    }
  }
);

//Create user inside mailchimp
export const registerUserNewsletter = createAsyncThunk(
  UserTypes.REGISTER_CLIENT_REQUEST_NEWSLETTER,
  async (params, thunkApi) => {
    const userService = new UserService();
    const { payload } = params;
    try {
      await userService.registerUserMailchimp(payload);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Cadastrado com sucesso',
      });
    } catch (error) {
      console.log(error);
      const errorMessage =
        'Houve um erro ao enviar o formulário. Por favor, verifique os dados e tente novamente mais tarde';
      createToast({ type: toastTypes.ERROR, message: errorMessage });
      return thunkApi.rejectWithValue({ error: errorMessage });
    }
  }
);

//Create user inside the system
export const createUser = createAsyncThunk(
  UserTypes.CREATE_USER_REQUEST,
  async (params, thunkApi) => {
    const userService = new UserService();
    const { payload } = params;
    try {
      await userService.registerUser(payload);
      const updatedResponse = await thunkApi.dispatch(fetchUsers());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Adicionado com sucesso',
      });
      return updatedResponse.payload;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const updateUser = createAsyncThunk(
  UserTypes.UPDATE_USER_REQUEST,
  async (params, thunkApi) => {
    const { payload, id } = params;
    const userService = new UserService();
    try {
      await userService.editUser(payload, id);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Atualizado com sucesso',
        options: { autoClose: 2000 },
      });
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      return thunkApi.rejectWithValue({ error });
    }
    const updateResponse = await thunkApi.dispatch(fetchUsers());
    return updateResponse.payload;
  }
);

export const deleteUser = createAsyncThunk(
  UserTypes.DELETE_USER_REQUEST,
  async (ownerId, thunkApi) => {
    const userService = new UserService();
    await userService.deleteUser(ownerId);
    createToast({
      type: toastTypes.SUCCESS,
      message: 'Exclusão efetuada com sucesso!',
      options: { autoClose: 3000 },
    });

    const updateResponse = await thunkApi.dispatch(fetchUsers());
    return updateResponse.payload;
  }
);

export const fetchInvites = createAsyncThunk(
  UserTypes.GET_INVITES_REQUEST,
  async () => {
    const userService = new UserService();
    const response = await userService.getInvites();
    return response.data;
  }
);

export const createInvite = createAsyncThunk(
  UserTypes.CREATE_INVITES_REQUEST,
  async (params, thunkApi) => {
    const userService = new UserService();
    try {
      await userService.createInvite(params);
      const updatedResponse = await thunkApi.dispatch(fetchInvites());
      createToast({
        type: toastTypes.SUCCESS,
        message: `Convite enviado para o email ${params.email}`,
      });
      return updatedResponse.payload;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const deleteInvite = createAsyncThunk(
  UserTypes.DELETE_INVITES_REQUEST,
  async (params, thunkApi) => {
    console.log('~  params:', params);
    const userService = new UserService();
    const { inviteId } = params;

    await userService.deleteInvite(inviteId);
    createToast({
      type: toastTypes.SUCCESS,
      message: 'Exclusão efetuada com sucesso!',
      options: { autoClose: 3000 },
    });

    const updateResponse = await thunkApi.dispatch(fetchInvites());
    return updateResponse.payload;
  }
);

export const resendInvite = createAsyncThunk(
  UserTypes.RESEND_INVITES_REQUEST,
  async (params, thunkApi) => {
    const userService = new UserService();
    const { inviteId } = params;
    try {
      await userService.resendInvte(inviteId);
      createToast({
        type: toastTypes.SUCCESS,
        message: `Convite re-enviado`,
      });
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
      return thunkApi.rejectWithValue({ error });
    }
  }
);
