import styled from 'styled-components';

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212112;
  background-color: #9cdb37;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  width: 1.7rem;
  height: 1.7rem;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;
