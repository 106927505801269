export const CloudTypes = {
  AUTHENTICATE_CLOUD: "cloud/authenticateCloud",
  FETCH_DRIVE_DOCUMENT_LINKS: "cloud/fetchDriveDocumentLinks",
  DELETE_DRIVE_FILE: "cloud/deleteDriveFile",

  FETCH_CLOUD_STATUS: "cloud/fetchCloudStatus",

  DISCONNECT_CLOUD: "cloud/disconnect",

  FETCH_DOCUMENT_LINKS: "cloud/fetchDocumentLinks",

};
