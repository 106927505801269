import styled from 'styled-components';
import { Popover } from 'antd';

const StyledPopover = styled(Popover)``;

export const ThemedPopover = (props) => (
  <StyledPopover
    overlayStyle={{
      maxHeight: 300,
      maxWidth: 700,
    }}
    {...props}
  />
);
