import { GrFilter } from 'react-icons/gr';
import styled from 'styled-components';

export const ButtonSearchDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  margin: 5px;
  padding: 10px;
  height: 2.5rem;
  border-radius: 20px;
  background-color: #d0fa94;
  color: #21211a;
  box-shadow: 1px 1px #ddd;

  :hover {
    cursor: pointer;
    opacity: 70%;
    transition: 0.25s;
  }
  @media screen and (max-width: 768px) {
    width: 3.5rem;
    span {
      font-size: 0;
    }
  }
`;
export const IconSearchDetail = styled(GrFilter)`
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
`;
