import React from 'react';
import CadastroRepositorio from 'pages/Repositories';
import UserInfo from 'pages/usuarios';
import Documents from 'pages/documents';
import TableCategories from 'components/Tables/Categories';
import Terms from 'pages/termsUse';
import LearnMoreAbout from 'pages/Contact';
import Profile from 'pages/Profile';
import Faq from 'pages/SupportCenter';
import Blog from 'pages/Blog/Home';
import Posts from 'pages/Blog/PostDetail';
import PassRecover from 'pages/PassRecover';
//import Feedback from 'components/Modals/Feedback';
import Home from 'pages/home';
import { FaUserEdit } from 'react-icons/fa';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import { RiGitRepositoryCommitsLine } from 'react-icons/ri';
import { ImTable2 } from 'react-icons/im';
import { AiOutlineHome } from 'react-icons/ai';
import { Routes } from 'common/enums/routes.enum';
import GroupsTable from 'pages/Groups';
import TemporalityTable from 'components/Tables/Temporality';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getHiredPlan } from 'common/redux/payments/selectors';
import Audit from 'pages/Audit';

export const useRoutes = () => {
  const hiredPlan = useReduxState(getHiredPlan);

 const routes = [
    //main: Main props of the route that must be passed to Route component
    //All other props are optional to use whenever needed in the application

    {
      main: {
        path: Routes.HOME,
        component: Home,
        description: 'Home',
        isPrivate: true,
      },
      key: 'home',
      title: 'Início',
      icon: <AiOutlineHome size={22} />,
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.DOCUMENTS,
        component: Documents,
        description: 'Novo documento',
        isPrivate: true,
      },
      key: 'documents',
      title: 'Novo documento',
      icon: <HiOutlineDocumentAdd size={22} />,
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.STORAGE,
        component: CadastroRepositorio,
        description: 'Configurar Repositório',
        isPrivate: true,
      },
      key: 'storage',
      title: 'Repositórios',
      icon: <RiGitRepositoryCommitsLine size={22} />,
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.USERS,
        component: UserInfo,
        description: 'Lista de usuários',
        isPrivate: true,
      },
      key: 'users',
      title: 'Usuários',
      icon: <FaUserEdit size={22} />,
      allowCustomPermissions: true,
    },

    {
      main: {
        path: Routes.CATEGORIES,
        component: TableCategories,
        description: 'Configurar tabelas e campos',
        isPrivate: true,
      },
      key: 'categories',
      title: 'Tabelas',
      icon: <ImTable2 size={22} />,
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.TERMS_OF_SERVICE,
        component: Terms,
        description: 'Termos de uso',
        isPrivate: false,
      },
      title: 'Termos de Uso',
    },
    {
      main: {
        path: Routes.PROFILE,
        component: Profile,
        description: 'Dados da conta',
        isPrivate: true,
      },
      key: 'account-info',
      title: 'Dados da Conta',
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.PASS_RECOVER,
        component: PassRecover,
        description: 'Recuperar Senha',
        isPrivate: false,
      },
      title: 'Recuperar Senha',
    },
    {
      main: {
        path: Routes.FAQ,
        component: Faq,
        description: 'FAQ',
        isPrivate: true,
      },
      title: 'FAQ',
    },
    {
      main: {
        path: Routes.BLOG,
        component: Blog,
        description: 'Blog',
        isPrivate: false,
      },
      title: 'Blog',
    },
    {
      main: {
        path: '/post/:id',
        component: Posts,
        description: 'posts',
        isPrivate: false,
      },
      title: 'posts',
    },
    {
      main: {
        path: Routes.AUDIT,
        component: Audit,
        description: 'audit',
        isPrivate: true,
      },
      title: 'Auditoria',
    },
    {
      main: {
        path: Routes.LEARN_MORE,
        component: LearnMoreAbout,
        description: 'Saiba Mais',
        isPrivate: false,
      },
      title: 'Saiba Mais',
    },
    {
      main: {
        path: Routes.GROUPS,
        component: GroupsTable,
        description: 'Perfis',
        isPrivate: true,
      },
      key: 'roles',
      title: 'Perfis',
      allowCustomPermissions: true,
    },
    {
      main: {
        path: Routes.PHYSICAL_TEMPORALITY,
        component: TemporalityTable,
        description: 'Descarte Físico',
        isPrivate: true,
      },
      key: 'temporality/physical',
      title: 'Descarte Físico',
      allowCustomPermissions: true,
      hidden: !hiredPlan?.modules?.temporality,
    },
    {
      main: {
        path: Routes.DIGITAL_TEMPORALITY,
        component: TemporalityTable,
        description: 'Descarte Eletrônico',
        isPrivate: true,
      },
      key: 'temporality/digital',
      title: 'Descarte Eletrônico',
      allowCustomPermissions: true,
      hidden: !hiredPlan?.modules?.temporality,
    },
  ];

  return { routes };
};
