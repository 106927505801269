import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: center;

  button {
    width: 8rem;
    font-size: 0.9rem;
    margin: 10px 0.3rem;
  }

  @media screen and (max-width: 400px) {
    flex-wrap: wrap;
    button {
      width: 100%;
    }
  }
`;
