import { Select } from './Select';

const options = [
  {
    id: 'AllDocs',
    name: 'Todos os documentos',
  },
  {
    id: 'WithAttachment',
    name: 'Documentos com anexo',
  },
  {
    id: 'WithoutAttachment',
    name: 'Documentos sem anexo',
  },
];

export const DocumentDisplayComponent = ({ ...rest }) => {
  return (
    <Select
      defaultValue='AllDocs'
      placeholder='Selecione...'
      selectOptions={options}
      style={{
        width: '100%',
      }}
      allowClear={false}
      {...rest}
    />
  );
};
