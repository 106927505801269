import React from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../Landing Page/assets/tamiaBlack.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  background-color: #d0fa94;
  padding: 20px;
  height: 20rem;
  width: 30rem;
  white-space: pre-line;
  overflow-y: auto;
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.3s ease-in-out;

  @media (max-width: 500px) {
    margin: 10px;
  }
`;

const CloseButton = styled.button`
  position: relative;
  left: 210px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #212121;

  @media (max-width: 500px) {
    left: 150px;
    top: 5px;
  }
`;

const ModalHeader = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 10px;
  padding: 0;
`;
const ModalContent = styled.div`
  max-height: 10rem;
`;
const Button = styled.button`
  border: 2px solid #4e503b;
  color: #4e503b;
  background-color: #f0fcd6;
  padding: 0.45rem;
  font-size: 0.9rem;
  width: 6.25rem;
  margin: 0.62rem;
  min-width: 6.25rem;
  font-weight: 700;
  border-radius: 10px;
  &:hover {
    background-color: #9cdb37;
    transition: 300ms;
  }
`;

const ModalCookie = ({ showModal, closeModal }) => {
  return (
    <>
      {showModal && (
        <ModalBackground>
          <ModalContainer show={showModal}>
            <CloseButton onClick={closeModal}>X</CloseButton>
            <ModalHeader>
              <img
                src={Logo}
                style={{
                  width: '3rem',
                  paddingBottom: '8px',
                  paddingRight: '10px',
                }}
              />{' '}
              Sobre cookies
            </ModalHeader>
            <ModalContent>
              <h3>Definição de Cookies</h3>
              <Text>
                Cookies são pequenos arquivos de texto armazenados no
                dispositivo do usuário que coletam informações sobre as
                preferências e o comportamento do usuário ao interagir com o
                site, com a finalidade de oferecer uma melhor experiência de
                navegação.
              </Text>
              <h3>Consentimento para Uso de Cookies</h3>
              <Text>
                Ao acessar nosso site, o usuário concorda com o uso de cookies
                de acordo com as configurações do navegador. O usuário tem o
                direito de aceitar ou recusar cookies. No entanto, a recusa pode
                limitar o acesso a determinadas funcionalidades do site.
              </Text>
              <h3>
                Os cookies utilizados podem incluir, mas não se limitam a:
              </h3>
              <ul>
                <li>
                  Cookies essenciais: necessários para o funcionamento do site.
                </li>
                <li>
                  Cookies de desempenho: para análise e melhoria do desempenho
                  do site.
                </li>
                <li>
                  Cookies de funcionalidade: que permitem lembrar escolhas
                  feitas pelo usuário.
                </li>
              </ul>
              <h3>Finalidade dos Cookies</h3>
              <Text>
                Os cookies são utilizados para os seguintes propósitos: Melhorar
                a experiência de navegação do usuário, Personalizar conteúdos e
                publicidades, Analisar o tráfego e o uso do site.
              </Text>
              <h3>Controle e Gerenciamento de Cookies</h3>
              <Text>
                O usuário pode gerenciar as configurações de cookies, incluindo
                aceitar, recusar ou excluir cookies, por meio das configurações
                do navegador.
              </Text>
              <h3>Alterações na Política de Cookies</h3>
              <Text>
                Reservamo-nos o direito de modificar esta política de cookies
                para refletir alterações na legislação ou nas práticas de
                privacidade. Recomendamos que os usuários revisem periodicamente
                esta cláusula para estar cientes das atualizações.
              </Text>
              <h3>Consentimento do Usuário</h3>
              <Text>
                Ao continuar a utilizar nosso site após o aviso sobre cookies, o
                usuário consente com o uso de cookies de acordo com esta
                cláusula. Esta cláusula é uma declaração geral e informativa
                sobre o uso de cookies em um site. É importante que seja
                complementada com uma política de privacidade mais detalhada,
                descrevendo os tipos específicos de cookies utilizados, sua
                finalidade e como os usuários podem gerenciá-los. Além disso, é
                fundamental estar em conformidade com as leis de proteção de
                dados e privacidade vigentes no país ou região em que a empresa
                opera.
              </Text>
              <Button onClick={closeModal}>Fechar</Button>
            </ModalContent>
          </ModalContainer>
        </ModalBackground>
      )}
    </>
  );
};

export default ModalCookie;
