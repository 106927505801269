import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const FormRow = styled(Flex)`
  & > * {
    flex: 1;

    label {
      font-size: 0.9rem;
    }

    :not(:last-child) {
      margin-right: 20px;
    }
  }
`;
