import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { createToast } from 'common/utilities/createToast';
import { SystemInfoService } from 'core/services/systemInfo.service';
import { InfoTypes } from './types';
import { paymentActions } from '../payments/reducer';

export const updateSystemInfo = createAsyncThunk(
  InfoTypes.UPDATE_SYSTEM_INFO,
  async (payload, thunkApi) => {
    try {
      const systemInfoService = new SystemInfoService();
      const response = await systemInfoService.updateSystemInfo(payload);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Atualizado com sucesso',
        options: { autoClose: 2000 },
      });
      return response.data;
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Erro ao atualizar valores',
      });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchInitialData = createAsyncThunk(
  InfoTypes.FETCH_INITIAL_DATA,
  async (_, thunkApi) => {
    try {
      const systemInfoService = new SystemInfoService();
      const response = await systemInfoService.fetchInitialData();

      const { payment, stats } = response.data;
      thunkApi.dispatch(paymentActions.updatePaymentInfo(payment));
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchAuditData = createAsyncThunk(
  InfoTypes.FETCH_AUDIT_DATA,
  async (_, thunkApi) => {
    try {
      const systemInfoService = new SystemInfoService();
      const auditFilters = thunkApi.getState().filters?.auditFilters
      const { data } = await systemInfoService.fetchAudit(auditFilters);

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);
