import { bindPusherEvents, loadApis } from 'common/redux/auth/thunks';

export class ApplicationStore {
  static shared = new ApplicationStore();
  #store;

  constructor() {}

  _init() {
    const userData = this.getUserData();
    console.log(window.location.pathname)

    if (
      userData.companyId &&
      !['/', '/blog'].includes(window.location.pathname)
    ) {
      this.store.dispatch(bindPusherEvents(userData));
      this.store.dispatch(loadApis());
    }
  }

  set(store) {
    this.store = store;
    this._init();
  }

  get() {
    return this.store;
  }

  isReady() {
    return !!this.store;
  }

  getUserData() {
    if (this.isReady()) {
      const currentUser = this.store.getState().auth.currentUser;
      return {
        companyId: currentUser.selectedCompanyId,
        userId: currentUser._id,
      };
    }
  }
  getUserSystem() {
    if (this.isReady())
      return this.store.getState().auth.currentUser?.companyId;
  }
}
