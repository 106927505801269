import { BaseApi } from 'core/api';

export class PermissionsApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`permissions`);
  }
  async fetchAll() {
    return this.get();
  }
}
