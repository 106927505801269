import React, { useState } from 'react';
import {
  FormContainer,
  PageContainer,
  RegisterContainer,
  TextContainer,
} from './styles';
import { Checkbox, Form } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { MaskInput } from 'components/Input/MaskInput';
import { MaskTypes } from 'common/enums/maskTypes.enum';
import ThemedButton from 'components/Themed/ThemedButton';
import { ThemedCheckbox } from 'components/Themed/ThemedCheckbox';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { registerClient } from 'common/redux/users/thunks';
import { Link } from 'react-router-dom';
import { Footer } from 'pages/Landing Page/Footer';
import { Navigation } from 'pages/Landing Page/Navigation';

import {
  ReasonForSearchSelect,
  RegisterNumEmployeesSelect,
  RegisterPositionSelect,
} from 'components/Input/TrialRegisterSelect';
import { useReduxState } from 'common/hooks/use-redux-state';
import { isAuthLoading } from 'common/redux/auth/selectors';

const formRules = {
  required: [{ required: true, message: 'Campo obrigatório' }],
};

const TrialRegisterForm = () => {
  const [form] = Form.useForm();
  const createClient = useReduxAction(registerClient);
  const isLoading = useReduxState(isAuthLoading);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const { preferredContactMethod, ...otherValues } = values;

    const payload = {
      system: null,
      ...otherValues,
      preferredContactMethod: preferredContactMethod || [],
    };

    await createClient({ payload });
    setFormSubmitted(true);
  };

  return (
    <>
      <Navigation />
      <PageContainer>
        <TextContainer>
          <h1>
            Esse é o primeiro passo para você levar o Tâmia para sua empresa
          </h1>
          <h3>Responda as perguntas abaixo com atenção</h3>
          <p>
            Em breve nós entraremos em contato com você e enviaremos todas as
            informações sobre o melhor sistema de gerenciamento de arquivos{' '}
          </p>
        </TextContainer>
        <RegisterContainer>
          <FormContainer>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
              requiredMark={false}
            >
              <Form.Item
                name='name'
                label='Nome completo'
                rules={formRules.required}
              >
                <ThemedTextInput placeholder='Digite aqui' />
              </Form.Item>
              <Form.Item name='email' label='Email' rules={formRules.required}>
                <ThemedTextInput placeholder='exemplo@email.com' />
              </Form.Item>
              <Form.Item
                name='phone'
                rules={formRules.required}
                label='Telefone'
              >
                <MaskInput
                  placeholder={'Digite aqui'}
                  mask={MaskTypes.CEL_MASK}
                />
              </Form.Item>
              <Form.Item
                rules={formRules.required}
                name='company'
                label='Nome da Empresa'
              >
                <ThemedTextInput placeholder='Digite aqui' />
              </Form.Item>
              <Form.Item
                rules={formRules.required}
                name='position'
                label='Qual seu cargo?'
              >
                <RegisterPositionSelect />
              </Form.Item>
              <Form.Item
                rules={formRules.required}
                name='numEmployees'
                label='Qual o número de funcionários na sua empresa?'
              >
                <RegisterNumEmployeesSelect />
              </Form.Item>
              <Form.Item
                rules={formRules.required}
                name='reasonForSearch'
                label='Qual o motivo de você procurar um sistema de gerenciamento de arquivos?'
              >
                <ReasonForSearchSelect />
              </Form.Item>
              <Form.Item
                rules={formRules.required}
                name='preferredContactMethod'
                label='Como você prefere ser atendido e receber as informações sobre o Tâmia?'
              >
                <Checkbox.Group>
                  <ThemedCheckbox
                    style={{ marginLeft: '8px' }}
                    value='Quero que alguém me ligue'
                  >
                    Quero que alguém me ligue.
                  </ThemedCheckbox>
                  <ThemedCheckbox value='Me envie um e-mail'>
                    Me envie um e-mail.
                  </ThemedCheckbox>{' '}
                  <ThemedCheckbox value='Prefiro conversar pelo WhatsApp'>
                    Prefiro conversar pelo WhatsApp.
                  </ThemedCheckbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item>
                <ThemedButton
                  loading={isLoading}
                  color='#9cdb37'
                  htmlType='submit'
                  style={{ width: '100%' }}
                  disabled={formSubmitted}
                >
                  {formSubmitted ? 'Enviado' : 'Enviar'}
                </ThemedButton>
              </Form.Item>
            </Form>
          </FormContainer>
          <Link style={{ marginTop: 20, color: 'green' }} to='/'>
            ← Retornar
          </Link>
        </RegisterContainer>
      </PageContainer>
      <Footer />
    </>
  );
};

export default TrialRegisterForm;
