import { PaymentsApi } from 'core/api/payments';
import ApplicationStateInjector from 'core/application-state-injector';

export class PaymentsService extends ApplicationStateInjector {
  constructor(system, userId) {
    super();
    this.system = system;
    this.userId = userId;
    this.paymentsApi = new PaymentsApi(this.system);
  }

  getPlans() {
    return this.paymentsApi.getPlans();
  }
}
