import { Link } from 'react-router-dom';
import TamiaHeader from './assets/logo-Landing Page 1.png';
import { useIsInViewport } from './useIsInViewport';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiLinkedin } from 'react-icons/fi';
import Modal from './newsletter';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

function openMenu() {
  document.body.classList.add('menu-expanded');
}

function closeMenu() {
  document.body.classList.remove('menu-expanded');
}

export const Navigation = ({ refs }) => {
  const isHomeInViewport = useIsInViewport(refs?.home);
  const isAboutInViewport = useIsInViewport(refs?.about);
  const isContactInViewport = useIsInViewport(refs?.contact);
  const isBlogInViewport = useIsInViewport(refs?.blog);
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <nav id='navigation'>
      <div className='wrapper'>
        <div className='menu'>
          <Link className='logo' to='/'>
            <img id='logo-header' src={TamiaHeader} alt='' />
          </Link>
          <div className='nav-menu-center'>
            <ul>
              <li>
                <a onClick={closeMenu} href='/#home'>
                  Home
                </a>
              </li>
              <li>
                <a onClick={closeMenu} href='/#about'>
                  Sobre
                </a>
              </li>
              <li>
                <a className={isBlogInViewport ? 'active' : ''} href='/blog'>
                  Blog
                </a>
              </li>
              <li>
                <a onClick={openModal}>Newsletter</a>
              </li>
              <li>
                <a onClick={closeMenu} href='/#contato'>
                  Contato
                </a>
              </li>
            </ul>
          </div>
          <div className='button-container'>
            <a className='button' href='/login'>
              ENTRAR
            </a>
            <a className='button-contact' href='/cadastro' target='_blank'>
              SEJA TÂMIA
            </a>
          </div>

          <ul className='social-links'>
            <li>
              <a target='_blank' href='https://www.instagram.com/tamia.app/'>
                <FaInstagram size={20} />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/t%C3%A2mia/'
                target='_blank'
              >
                <FiLinkedin size={21} />
              </a>
            </li>
            <li>
              <a href='https://wa.me/5521968996222' target='_blank'>
                <FaWhatsapp size={22} />
              </a>
            </li>
          </ul>
        </div>

        <button
          aria-expanded='false'
          aria-label='Abrir menu'
          onClick={openMenu}
          className='open-menu'
        >
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 20H30'
              stroke='#9cdb37'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10 12H30'
              stroke='#9cdb37'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M18 28L30 28'
              stroke='#9cdb37'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
        <a
          aria-expanded='false'
          aria-label='Abrir menu'
          onClick={openMenu}
          className='open-menu'
          href=''
        >
          <img id='logo-header' src={TamiaHeader} alt='' />
        </a>
        <button
          aria-expanded='true'
          aria-label='Fechar menu'
          onClick={closeMenu}
          className='close-menu'
        >
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M30 10L10 30M10 10L30 30'
              stroke='#9cdb37'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </div>
      <Modal showModal={showModal} closeModal={closeModal} />
    </nav>
  );
};
