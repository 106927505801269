import styled, { css } from 'styled-components';
import { Menu } from 'antd';

export const SidebarMenu = styled(Menu)`
  min-width: 280px;
  height: 100%;
  margin-right: 5px;
  background-color: inherit;
  font-weight: 600;

  .ant-menu-title-content {
    font-size: 0.85rem;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-active,
  .ant-menu-item-open,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-expand-icon {
    color: #21211a !important;
    background: #f0fcd6 !important;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title {
    color: #21211a !important;
  }

  .ant-menu-submenu-arrow {
    color: #9cdb37 !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-selected:hover,
  .ant-menu-submenu:hover,
  .ant-menu-item-active:hover,
  .ant-menu-submenu-open:hover,
  .ant-menu-submenu-selected:hover,
  .ant-menu-item a:hover {
    color: #21211a !important;
    background: #f0fcd6 !important;
  }

  .ant-menu-submenu-title:hover {
    color: #21211a !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-submenu-selected::after {
    border-right-color: #9cdb37 !important;
  }
`;

export const Container = styled.div`
  .ant-menu-title-content {
    margin-left: 12px;
  }
  .ant-menu-item-selected {
    //color: #9cdb37 ;
  }
  .ant-menu-submenu-arrow-selected::after {
    // color: #9cdb37;
  }

  .ant-menu-submenu-selected {
    // color: #9cdb37;
  }
  .ant-menu-submenu-title {
    &:hover {
      // color: #9cdb37;
    }
  }

  .ant-menu-item {
    &:hover {
      color: #9cdb37;
    }
  }
`;
