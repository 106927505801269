import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getUserCompanies } from 'common/redux/auth/selectors';
import { loginCompany } from 'common/redux/auth/thunks';
import { Colors } from 'common/theme/colors';
import { Flex } from 'components/Flex';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(Flex)`
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.PRIMARY_COLOR};
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled(Flex)`
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  height: 40%;
  padding: 50px;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const CardContainerHeader = styled(Flex)`
  height: 20%;
  width: 100%;
  font-size: 18px;
  align-items:flex-start;
  justify-content:center;
  `;

const CardContainerBody = styled(Flex)`
  width: 100%;
  font-size: 20px;
  justify-content: center;
  align-items: center;
`;

const Card = styled(Flex)`
  width: 100%;
  height: 100px;
  font-size: 18px;
  border: 1px solid gray;
  background-color: #f0f0f0;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  transition: transform 0.3s;

  :hover {
    cursor: pointer;
    transform: scale(1);
  }
`;

export const CompanyLogin = () => {
  const userCompanies = useReduxState(getUserCompanies);
  const _loginCompany = useReduxAction(loginCompany);
  const history = useHistory();

  const setUserCompany = (companyId) => {
    _loginCompany(companyId);
  };

  useEffect(() => {
    if (userCompanies.length === 1) {
      setUserCompany(userCompanies[0].companyId);
    }
  }, []);

  return (
    userCompanies.length > 1 && (
      <Container>
        <CardContainer>
          <CardContainerHeader>Selecione uma empresa:</CardContainerHeader>
          <CardContainerBody>
            {userCompanies.map((company) => (
              <Card
                key={company.companyId}
                onClick={() => setUserCompany(company.companyId)}
              >
                {company.name}
              </Card>
            ))}
          </CardContainerBody>
        </CardContainer>
      </Container>
    )
  );
};
