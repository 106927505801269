import React, { useState } from 'react';
import {
  RecoverText,
  InputContainer,
  Logo,
  Container,
  RegisterText,
  InputsContainer,
} from './style';

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLock,
  AiOutlineUser,
} from 'react-icons/ai';

import logo from 'assets/images/logoTamiaGreen.png';
import { Form } from 'antd';
import { ThemedRegisterInput } from 'components/Themed/ThemedRegisterInput';
import { Routes } from 'common/enums/routes.enum';
import { createToast } from 'common/utilities/createToast';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { attemptLogin } from 'common/redux/auth/thunks';
import { isAuthLoading } from 'common/redux/auth/selectors';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/Flex';
import ThemedButton from 'components/Themed/ThemedButton';

const Login = () => {
  const history = useHistory();
  const signIn = useReduxAction(attemptLogin);
  const [loginForm] = Form.useForm();
  const isLoading = useReduxState(isAuthLoading);
  const [show, setShow] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const handleLogin = async (values) => {
    const { user, password } = values;
    if (!user) {
      createToast({ type: toastTypes.ERROR, message: 'Insira um usuário' });
    }

    if (!password)
      createToast({ type: toastTypes.ERROR, message: 'Insira uma senha' });

    if (user && password) signIn(values);
  };

  return (
    <>
      <Container>
        <Form onFinish={handleLogin} form={loginForm} className='custom-form'>
          <Flex
            direction='column'
            justify='center'
            align='center'
            style={{ maxWidth: 370 }}
          >
            <h2>Faça seu login</h2>
            <Logo src={logo} />
            <InputsContainer>
              <InputContainer>
                <AiOutlineUser size={20} style={{ color: '#212121' }} />
                <Form.Item name='user' noStyle>
                  <ThemedRegisterInput placeholder='seu@seuemail.com.br' />
                </Form.Item>
              </InputContainer>
              <InputContainer>
                <AiFillLock size={20} style={{ color: '#212121' }} />
                <Form.Item name='password' noStyle>
                  <ThemedRegisterInput
                    type={show ? 'text' : 'password'}
                    placeholder='●●●●●●●●●'
                  />
                </Form.Item>
                {show ? (
                  <AiFillEye
                    style={{
                      color: '#212121',

                      cursor: 'pointer',
                    }}
                    size={20}
                    onClick={handleClick}
                  />
                ) : (
                  <AiFillEyeInvisible
                    style={{
                      cursor: 'pointer',
                      color: '#212121',
                    }}
                    size={20}
                    onClick={handleClick}
                  />
                )}
              </InputContainer>
              <RecoverText onClick={() => history.push(Routes.PASS_RECOVER)}>
                Esqueci minha senha{' '}
              </RecoverText>
              <Flex justify='center'>
                <ThemedButton
                  width='100%'
                  color='#d0fa94'
                  htmlType='submit'
                  loading={isLoading}
                  height='50px'
                  size='18px'
                  weight='600'
                >
                  Entrar
                </ThemedButton>
              </Flex>
              <RegisterText onClick={() => history.push(Routes.TRIAL_REGISTER)}>
                Não tem uma conta?{' '}
                <p style={{ margin: '10px 5px', color: '#ffffff' }}>
                  Registre-se
                </p>
              </RegisterText>
            </InputsContainer>
          </Flex>
        </Form>
      </Container>
    </>
  );
};

export default Login;
