export const maskText = (inputText = '', maskSymbol = '*') => {
  const unmaskedText = inputText.replace(/[^\d]+/g, '');

  if (unmaskedText.length === 11) {
    const maskedCpf = `${maskSymbol.repeat(3)}${unmaskedText.slice(
      3,
      -2
    )}${maskSymbol.repeat(2)}`;
    return maskedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  if (unmaskedText.length === 14) {
    const maskedCnpj = `${maskSymbol.repeat(2)}${unmaskedText.slice(
      2,
      -4
    )}${maskSymbol.repeat(4)}`;
    return maskedCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
  return '';
};
