import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  :hover {
    cursor: pointer;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const EditCategories = styled.button`
  display: flex;
  justify-content: center;
  background-color: transparent;
  color: #21211a;
  border-radius: 30px;
  border: 1px solid #d0fa94;
  cursor: pointer;
  box-shadow:1px 1px #bbb;
  :hover {
    transition: 0.4s;
    background-color: #d0fa94;
  }

  span {
    // position: relative;
    text-decoration: none;
    margin: 0.5rem 0.7rem;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eaeaea;
  background-color: #ab0205;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  width: 1.7rem;
  height: 1.7rem;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;
