import React from 'react';
import { Form, Button, Switch } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { TagsSelect } from 'components/Input/TagsSelect';
import { MaskInput } from 'components/Input/MaskInput';
import { FaTrash } from 'react-icons/fa';
import { ThemedModal } from 'components/Themed/ThemedModal';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { ThemedNumberInput } from 'components/Themed/ThemedNumberInput';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { editCategorySettings } from 'common/redux/permissions/thunks';
import ThemedButton from 'components/Themed/ThemedButton';
import { Flex } from 'components/Flex';

const formRules = {
  name: [
    {
      required: true,
      message: 'Por favor insira um nome',
    },
  ],
};

const textTooltips = 'Você pode adicionar mais de uma variação';

const EditCategoryModal = ({ category, ...props }) => {
  const [form] = Form.useForm();
  const _editCategorySettings = useReduxAction(editCategorySettings);

  const handleClose = () => {
    props.onCancel();
    form.resetFields();
  };

  const handleSubmit = async (payload) => {
    _editCategorySettings({ ...category, ...payload });
    handleClose();
  };

  return (
    <ThemedModal
      footer={[
        <>
          <Flex justify='end'>
            <ThemedButton
              color='transparent'
              style={{ color: '#ff4747', borderColor: '#ff4747' }}
              onClick={handleClose}
            >
              Cancelar
            </ThemedButton>
            <ThemedButton
              color='#9cdb37'
              form={props.title + category._id}
              key='submit'
              htmlType='submit'
            >
              Salvar
            </ThemedButton>
          </Flex>
        </>,
      ]}
      {...props}
    >
      <div style={{ marginBottom: 20 }}>
        <Form
          id={props.title + category._id}
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...category,
            enableDocument: category.enableDocument !== false && true,
          }}
          layout='vertical'
          requiredMark={false}
        >
          <Form.Item name='name' label='Nome' rules={formRules.name}>
            <ThemedTextInput placeholder={'Digite aqui'} />
          </Form.Item>
          <Form.Item
            name='enableDocument'
            label='Incluir campo CPF/CNPJ'
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
        </Form>
      </div>
    </ThemedModal>
  );
};

export default EditCategoryModal;
