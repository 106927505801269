import React, { useEffect } from 'react';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getDocuments } from 'common/redux/documents/selectors';
import { getUserIsOperator } from 'common/redux/auth/selectors';
import { StyledTable } from '../style';
import { ThemedPopover } from 'components/Themed/ThemedPopover';
import { columns } from './columns';
import { getCategoryNames } from 'common/redux/permissions/selectors';
import { PathIndicator } from 'components/PathIndicator';
import { Circle, LegendContainer, LegendItem, LegendText } from './styles';
import { TEMPORALITY } from './columns';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Routes } from 'common/enums/routes.enum';
import { Flex } from 'components/Flex';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { fetchSearch } from 'common/redux/documents/thunks';

const CustomRow = ({ headerText, title, ...props }) => {
  return title && title.length > 15 ? (
    <ThemedPopover content={title} title={headerText}>
      <td {...props} />
    </ThemedPopover>
  ) : (
    <td {...props} />
  );
};

const getTemporalityColor = (documentDate) => {
  if (dayjs(documentDate).isBefore(dayjs())) {
    return 'red';
  } else {
    return 'yellow';
  }
};

export const getTemporalityDocuments = (
  documents,
  temporalityKey,
  sort = true
) => {
  const documentsWithColor = [];
  documents.forEach((document) => {
    const thirdyDaysFromNow = dayjs().add(30, 'day');
    const discardDate = document[temporalityKey];

    if (discardDate && dayjs(discardDate).isBefore(thirdyDaysFromNow)) {
      documentsWithColor.push({
        ...document,
        temporalityColor: getTemporalityColor(document[temporalityKey]),
      });
    }
  });

  if (sort) {
    return documentsWithColor.sort((a, b) =>
      dayjs(a[temporalityKey]).isAfter(dayjs(b[temporalityKey]))
    );
  }

  return documentsWithColor;
};

export default function TemporalityTable() {
  const documents = useReduxState(getDocuments);
  const categoryNames = useReduxState(getCategoryNames);
  const location = useLocation();
  
  const searchDocuments = useReduxAction(fetchSearch);
  
  const isDigital = location.pathname === Routes.DIGITAL_TEMPORALITY;
  const temporalityKey = isDigital ? TEMPORALITY.DIGITAL : TEMPORALITY.PHYSICAL;
  
  const sortedDocuments = getTemporalityDocuments(documents, temporalityKey);

  useEffect(() => {
    searchDocuments(true);
  }, []);

  return (
    <>
      <PathIndicator
        path={['Descarte', isDigital ? 'Eletrônico' : 'Físico']}
        helperText={
          'Controle de descarte dos documentos que estão para vencer em um prazo inferior a 30 dias ou que já estão vencidos'
        }
      />
      <LegendContainer>
        <LegendItem>
          <Circle bg='red' />
          <LegendText>Documentos vencidos.</LegendText>
        </LegendItem>
        <LegendItem>
          <Circle bg='yellow' />
          <LegendText>Documentos que vencem em menos de 30 dias.</LegendText>
        </LegendItem>
      </LegendContainer>
      <Flex justify='center' style={{ width: '97%', margin: 'auto' }}>
        <StyledTable
          style={{ padding: '15px' }}
          size='small'
          scroll={{ x: '100%' }}
          dataSource={sortedDocuments}
          columns={columns(categoryNames, temporalityKey)}
          components={{
            body: { cell: CustomRow },
          }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} documentos`,
            total: sortedDocuments.length,
          }}
        />
      </Flex>
    </>
  );
}
