const s = (state) => state.info;

export const getSystemInfo = (state) => s(state) || {};

export const getStorageUsage = (state) => {
  const storageLimit = s(state).payment?.hiredPlan?.limits?.storage;
  const usage = s(state).stats?.tamia?.usage;

  if (storageLimit && usage) {
    return ((usage / storageLimit) * 100).toFixed(2);
  }

  return '0';
};

export const getTotalDocuments = (state) => s(state)?.stats?.totalDocuments;

export const getAuditData = (state) => s(state)?.audit || [];
