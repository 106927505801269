import React from 'react';
import { DeleteButton } from './style';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';

import { useReduxAction } from 'common/hooks/use-redux-action';
import { deleteDocument } from 'common/redux/documents/thunks';
import { ConfirmDelete } from 'components/ConfirmDelete';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getUserIsOperator } from 'common/redux/auth/selectors';

export default function DeleteDocument({ document }) {
  const removeDocument = useReduxAction(deleteDocument);
  const userIsOperator = useReduxState(getUserIsOperator);

  const handleDelete = async () => {
    await removeDocument(document._id);
  };

  return (
    <div>
      <ConfirmDelete
        placement='topLeft'
        title={
          <>
            <span>Para confirmar a exclusão, digite o ID do documento </span>
            <br />
            ID: <strong> {document.id} </strong>
          </>
        }
        onConfirm={handleDelete}
        showWarning
        textConfirmation={document.id}
      >
        <ThemedTooltip placement='bottom' title='Excluir'>
          <DeleteButton variant='outlined' disabled={userIsOperator}>
            <FaRegTrashAlt size={14} />
          </DeleteButton>
        </ThemedTooltip>
      </ConfirmDelete>
    </div>
  );
}
