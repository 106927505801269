import { createAsyncThunk } from '@reduxjs/toolkit';
import { PusherEvents } from 'common/enums/pusherEvents.enum';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { binarySearch } from 'common/utilities/binarySearch';
import { createToast, Toast } from 'common/utilities/createToast';
import { getCategoryData } from 'common/utilities/getCategoryData';
import { PusherConnection } from 'core/pusher/pusher';
import { AuthService } from 'core/services/auth.service';
import { fetchCategories, fetchPermissions } from '../permissions/thunks';
import { fetchCloudStatus } from '../cloud/thunks';
import { addDocument, updateDocument } from '../documents/reducers';
import { fetchSearch } from '../documents/thunks';
import { updateInfo, updateStats } from '../info/reducers';
import { fetchInvites, fetchUsers } from '../users/thunks';
import { setUser } from './reducers';
import { AuthTypes } from './types';
import { fetchPlans } from '../payments/thunks';
import { fetchInitialData } from '../info/thunks';

export const bindPusherEvents = createAsyncThunk(
  AuthTypes.BIND_PUSHER_EVENTS,
  async (data, thunkApi) => {
    const { companyId, userId } = data;
    if (!companyId) return;

    //Pusher
    const pusher = PusherConnection.shared;
    pusher.subscribe(`channel-account-${companyId}`);
    pusher.subscribe(`channel-user-${userId}`);

    //Document
    pusher.bind(PusherEvents.DOCUMENT_ADD, (data) => {
      thunkApi.dispatch(
        addDocument({
          ...data.document,
          categories: getCategoryData(data.document.categories),
        })
      );
    });

    pusher.bind(PusherEvents.DOCUMENT_UPDATE, (data) => {
      const documents = thunkApi.getState().documents.data;
      const { matchIdx, matchExists } = binarySearch({
        values: documents,
        match: data.document.id,
        key: 'id',
        reverse: true,
      });

      if (matchExists) {
        thunkApi.dispatch(
          updateDocument({
            value: {
              ...data.document,
              categories: getCategoryData(data.document.categories),
            },
            matchIdx,
          })
        );
      }
    });

    pusher.bind(PusherEvents.DOCUMENT_UPLOAD_STATUS, (data) => {
      Toast.updateProgress(data.status, 'Subindo arquivos...');
      // console.log(`Progresso: ${math.round(data.status * 100)}%`);
    });

    pusher.bind(PusherEvents.INFO_UPDATE, (data) => {
      thunkApi.dispatch(updateStats(data));
    });
  }
);

export const loadApis = createAsyncThunk(
  AuthTypes.LOAD_APIS,
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchSearch());
    thunkApi.dispatch(fetchPermissions());
    thunkApi.dispatch(fetchUsers());
    thunkApi.dispatch(fetchInvites());
    thunkApi.dispatch(fetchCloudStatus());
    thunkApi.dispatch(fetchInitialData());
  }
);

export const attemptLogin = createAsyncThunk(
  AuthTypes.ATTEMPT_LOGIN,
  async (userData, thunkApi) => {
    try {
      const authService = new AuthService();
      const response = await authService.login(userData);

      const companies = response.data;

      return { companies };
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Usuário ou senha inválidos',
      });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const loginCompany = createAsyncThunk(
  AuthTypes.LOGIN_COMPANY,
  async (companyId, thunkApi) => {
    try {
      const authService = new AuthService();
      const { data } = await authService.loginCompany(companyId);
      const { userInfo, companyInfo } = data;

      thunkApi.dispatch(updateInfo(companyInfo));

      //Set logged user state
      thunkApi.dispatch(setUser(userInfo));

      // Setup APIs

      thunkApi.dispatch(bindPusherEvents({ companyId, userId: userInfo._id }));
      thunkApi.dispatch(loadApis());
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Erro ao selecionar empresa',
      });
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const sendResetEmail = createAsyncThunk(
  AuthTypes.SEND_RESET_EMAIL,
  async (email) => {
    try {
      const authService = new AuthService();
      await authService.sendResetEmail(email);
      createToast({
        type: toastTypes.SUCCESS,
        message: `Email de recuperação enviado para ${email}`,
      });
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Falha ao enviar email',
      });
    }
  }
);

export const resetPassword = createAsyncThunk(
  AuthTypes.RESET_PASSWORD,
  async (params) => {
    try {
      const { password, code } = params;
      const authService = new AuthService();
      await authService.resetPassword(password, code);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Senha atualizada',
      });
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Falha ao resetar senha',
      });
    }
  }
);
