import styled from 'styled-components';

export const Details = styled.div`
  background-color: #fff;
  flex-direction: column;

  p {
    font-weight: 400;
  }
  h4 {
    font-weight: 600;
    display: flex;
    margin: 10px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction:row;
  margin-top: 10px;
`;
export const Header = styled.div`
  background-color: #f3f4f5;
  margin: 10px;
  text-align: center;
`;

export const Container = styled.div`
  .ant-modal-header {
    background-color: #121214;
  }
`
  export const Text = styled.div`
    font-weight:600;
    display:flex;
  
`;
