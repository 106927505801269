import React, { useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import Route from 'routes/Route';
import Login from 'pages/Login';
import LearnMore from 'pages/Contact';
import Register from 'pages/Register';
import TrialRegister from 'pages/TrialRegister';
import Home from 'pages/home';
import { useRoutes } from 'common/hooks/use-routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TourProvider, components } from '@reactour/tour';
import { LandingPage } from 'pages/Landing Page/LandingPage';
import { FAQ } from 'pages/Landing Page/FAQ';
import Blog from 'pages/Blog/Home';
import PostDetailPage from 'pages/Blog/PostDetail';

import { CompanyLogin } from 'pages/Login/login-company';

function Badge({ children }) {
  return (
    <components.Badge
      styles={{
        badge: (base) => ({
          ...base,
          backgroundColor: '#9cdb37',
          color: '#21211a',
        }),
      }}
    >
      {children}
    </components.Badge>
  );
}

function Close({ onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        position: 'absolute',
        fontSize: '1rem',
        right: 10,
        top: 2,
        color: '#9cdb37',
        background: 'transparent',
        border: 'none',
      }}
    >
      x
    </button>
  );
}

const Routes = () => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const { routes } = useRoutes();

  const setCurrentStep = (step) => {
    switch (step) {
      case 0:
        history.push('/home');
        break;
      case 1:
        history.push('/classes');
        break;
      case 2:
        history.push('/usuarios');
        break;
      case 3:
        history.push('/perguntas-frequentes');
      default:
        break;
    }
    setStep(step);
  };

  return (
    <TourProvider
      currentStep={step}
      components={{ Badge, Close }}
      setCurrentStep={setCurrentStep}
    >
      <ToastContainer />
      <Switch>
        <Route path='/' isShared exact component={LandingPage} />
        <Route path='/faq' isShared exact component={FAQ} />
        <Route path='/blog' isShared exact component={Blog} />
        <Route path='/login' component={Login} title='Login' exact />
        <Route path='/post/:id' isShared exact component={PostDetailPage} />

        <Route
          path='/empresa'
          component={CompanyLogin}
          title='Selecionar Empresa'
          exact
          isPrivate
        />
        <Route path='/registro' component={Register} />
        <Route path='/cadastro' isShared component={TrialRegister} />
        <Route path='/home' component={Home} title='Home' isPrivate />
        <Route
          path='/saiba-mais'
          component={LearnMore}
          title='Saiba mais'
          isShared
        />
        {routes.map((route, idx) => {
          if (!route.hidden) {
            return <Route {...route.main} extra={route} key={idx} />;
          }
        })}
      </Switch>
    </TourProvider>
  );
};

export default Routes;
