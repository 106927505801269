import React from 'react';
import { useHistory } from 'react-router-dom';
import { getLocalRouteHref } from 'common/utilities/url.js';
import {
  Nav,
  NavBtn,
  NavLink,
  SignOutButton,
  Profile,
  DropdownItem,
} from './style.js';
import Logo from 'assets/images/logoTamia_sem_apoio.png';
import { FcFaq } from 'react-icons/fc';
import { Roles } from 'common/enums/roles.enum';
import { getSystemInfo } from 'common/redux/info/selectors.js';
import { Menu, Dropdown } from 'antd';
import {
  AiOutlineMenu,
  AiOutlineQuestionCircle,
  AiOutlineUser,
} from 'react-icons/ai';
import { TbMessageShare } from 'react-icons/tb';
import { RiAccountBoxLine } from 'react-icons/ri';
import { IoMdExit } from 'react-icons/io';
import { useReduxAction } from 'common/hooks/use-redux-action.js';
import { logout } from 'common/redux/auth/reducers.js';
import { useReduxState } from 'common/hooks/use-redux-state.js';
import { getUserEmail, getUserRole } from 'common/redux/auth/selectors.js';
import { Routes } from 'common/enums/routes.enum.js';
import { DownOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { AuthService } from 'core/services/auth.service.js';
import CallCenter from 'components/Modals/CallCenter';
import { Flex } from 'components/Flex/index.js';
import { useWindowSize } from 'common/hooks/use-window-size.js';
import { toggleSidebar } from 'common/redux/layout/reducers';
import styled from 'styled-components';
import { useTour } from '@reactour/tour';
import steps from 'common/joyride/steps';
import { IoFootstepsSharp } from 'react-icons/io5';
import { FaBlog } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { getHiredPlan } from 'common/redux/payments/selectors.js';

const MenuIcon = styled(AiOutlineMenu)`
  color: #21211a;
  margin: 1rem;
  cursor: pointer;
`;

const Header = () => {
  const history = useHistory();
  const userRole = useReduxState(getUserRole);
  const signOut = useReduxAction(logout);
  const username = useReduxState(getUserEmail);
  const toggleSidebarState = useReduxAction(toggleSidebar);
  const { isSmallScreen } = useWindowSize();
  const systemInfo = useReduxState(getSystemInfo);
  const hiredPlan = useReduxState(getHiredPlan);

  const { setIsOpen, setCurrentStep, setSteps } = useTour();

  const startTour = () => {
    setIsOpen(true);
    setCurrentStep(0);
    setSteps(steps);
  };

  const handleClick = ({ key }) => {
    history.push(key);
  };

  const handleSignout = async () => {
    const authService = new AuthService();
    try {
      await authService.logout();
    } catch (error) {
      console.log(error);
    }
    signOut();
  };
  const menuUserName = (
    <Menu>
      {userRole <= Roles.MANAGER && (
        <Menu.Item key={Routes.PROFILE} onClick={handleClick}>
          <Profile>
            <RiAccountBoxLine
              size={20}
              color='green'
              style={{ marginRight: '0.75rem', marginBottom: '-0.2rem' }}
            />
            Dados da conta
          </Profile>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <DropdownItem href='/blog' target='_blank'>
          <FaBlog
            size={20}
            color='green'
            style={{ marginRight: '0.75rem', marginBottom: '-0.2rem' }}
          />
          Blog Tâmia
        </DropdownItem>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='exit'>
        <SignOutButton onClick={handleSignout}>
          <IoMdExit
            size={20}
            style={{
              color: 'red',
              marginRight: '0.75rem',
              marginBottom: '-0.3rem',
            }}
          />{' '}
          Sair
        </SignOutButton>
      </Menu.Item>
    </Menu>
  );
  const info = () => {
    Modal.confirm({
      title: 'Vamos rever nosso guia do sistema?',
      okText: 'Começar o Tour',
      cancelText: 'Cancelar',
      content: (
        <div style={{ height: '4rem', fontWeight: '500', marginTop: '30px' }}>
          <p>
            Vamos fazer um breve Tour pelo Tâmia que irá te ajudar com as
            configurações básicas
          </p>
        </div>
      ),
      onOk() {
        startTour();
      },
    });
  };
  const menu = (
    <Menu>
      {userRole <= Roles.MANAGER && (
        <>
          <Menu.Item key='firstSteps'>
            <IoFootstepsSharp
              size={20}
              color='green'
              style={{ marginRight: '0.75rem', marginBottom: '-0.2rem' }}
            />
            <DropdownItem onClick={info}>Primeiros passos </DropdownItem>
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item key={Routes.FAQ} onClick={handleClick}>
        <FcFaq
          size={20}
          style={{ marginRight: '0.75rem', marginBottom: '-0.2rem' }}
        />
        <DropdownItem>Perguntas frequentes</DropdownItem>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item style={{ fontWeight: '500' }}>
        <CallCenter
          icon={
            <MdOutlineSupportAgent
              size={20}
              color='green'
              style={{ marginRight: '0.75rem', marginBottom: '-0.2rem' }}
            />
          }
          name='Mensagem ao suporte'
          title='Como podemos te ajudar?'
          placeholder='Escreva aqui sua solicitação ao suporte'
          okText='Enviar mensagem'
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Flex style={{ width: '100vw' }}>
      <Nav>
        {isSmallScreen && (
          <MenuIcon size={25} onClick={() => toggleSidebarState()} />
        )}

        <NavLink to='/home'>
          <img className='logo-img' src={Logo} alt='Tâmia' />
          <div className='header-container'>
            <p className='company-name'>{systemInfo?.company?.name || ' '} </p>
            <br />
            <p className='plan-type'>Plano: {hiredPlan.name}</p>
          </div>
        </NavLink>

        <NavBtn style={{ justifySelf: 'flex-end' }}>
          {isSmallScreen ? (
            <>
              <Dropdown
                style={{ color: 'black' }}
                overlay={menu}
                trigger={['click']}
              >
                <DropdownItem
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                >
                  <AiOutlineQuestionCircle
                    size={17}
                    style={{ marginBottom: '-3px' }}
                  />
                </DropdownItem>
              </Dropdown>
              <Dropdown overlay={menuUserName} trigger={['click']}>
                <DropdownItem
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                >
                  <AiOutlineUser size={17} style={{ marginBottom: '-3px' }} />
                </DropdownItem>
              </Dropdown>
            </>
          ) : (
            <>
              <div data-tour='ajuda'>
                <Dropdown overlay={menu} trigger={['click']}>
                  <DropdownItem
                    id='helper'
                    className='ant-dropdown-link'
                    onClick={(e) => e.preventDefault()}
                  >
                    Ajuda{' '}
                    <AiOutlineQuestionCircle
                      size={17}
                      style={{ marginBottom: '-3px' }}
                    />
                  </DropdownItem>
                </Dropdown>
              </div>

              <Dropdown overlay={menuUserName} trigger={['click']}>
                <DropdownItem
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                >
                  {username}
                  <DownOutlined color='black' style={{ marginLeft: '3px' }} />
                </DropdownItem>
              </Dropdown>
            </>
          )}
        </NavBtn>
      </Nav>
    </Flex>
  );
};

export default Header;
