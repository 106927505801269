import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { formsName } from 'common/enums/forms.enum';
import { ThemedRangePicker } from 'components/Themed/ThemedDatePicker';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { ThemedTextArea } from 'components/Themed/ThemedTextArea';
import { ThemedCheckbox } from 'components/Themed/ThemedCheckbox';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getSearchAdvancedFilters } from 'common/redux/filters/selectors';
import { setAdvancedFilters } from 'common/redux/filters/reducers';
import { fetchSearch } from 'common/redux/documents/thunks';
import { clearEmptyValues } from 'common/utilities/clearEmptyValues';
import { findById } from 'common/utilities/findById';
import {
  getCategories,
  getCategorySettings,
  getMergedCategories,
} from 'common/redux/permissions/selectors';
import { CategorySelect } from 'components/Input/CategorySelect';
import { FileTypeSelect } from 'components/Input/FileTypeSelect';
import { DocumentDisplayComponent } from 'components/Input/DocumentDisplaySelect';
import { CreatedBySelect } from 'components/Input/CreatedBySelect';

export const AdvancedSearch = () => {
  const [form] = Form.useForm();
  const [isStored, setIsStored] = useState(false);
  const advancedFilters = useReduxState(getSearchAdvancedFilters);
  const categorySettings = useReduxState(getCategorySettings);
  const saveAdvancedFilters = useReduxAction(setAdvancedFilters);
  const searchDocuments = useReduxAction(fetchSearch);
  const categories = useReduxState(getCategories);
  const mergedCategories = useReduxState(getMergedCategories);

  const handleSubmit = () => {
    searchDocuments();
  };

  const handleCategoryChange = () => {
    const categories = form.getFieldValue('categories');
    const codes = categories.map(
      (category) => findById(mergedCategories, category, '_id')?.code || 0
    );

    form.setFieldsValue({ index: codes.join('.') });
  };

  const handleIndexChange = (index) => {
    const categoryValues = Object.values(categories);
    const codes = index.split('.');
    const categoryIds = codes.map(
      (code, idx) => findById(categoryValues[idx], code, 'code')?._id
    );

    form.setFieldsValue({ categories: categoryIds });
  };

  const categoryItems = categorySettings.map((category, idx) => (
    <Form.Item key={idx} name={['categories', idx]} label={category.name}>
      <CategorySelect category={category} />
    </Form.Item>
  ));

  const updateFilterValues = (values) => {
    const {
      categories,
      created_at,
      document_period,
      physical_discard,
      digital_discard,
      physical_discarded_at,
      digital_discarded_at,
      index,
      ...rest
    } = values;
    if (categories) {
      handleCategoryChange();
    }
    const formattedDates = {
      created_at: created_at && {
        startDate: created_at[0],
        endDate: created_at[1],
      },
      document_period: document_period && {
        startDate: document_period[0],
        endDate: document_period[1],
      },
      physical_discard: physical_discard && {
        startDate: physical_discard[0],
        endDate: physical_discard[1],
      },
      digital_discard: digital_discard && {
        startDate: digital_discard[0],
        endDate: digital_discard[1],
      },
      physical_discarded_at: physical_discarded_at && {
        startDate: physical_discarded_at[0],
        endDate: physical_discarded_at[1],
      },
      digital_discarded_at: digital_discarded_at && {
        startDate: digital_discarded_at[0],
        endDate: digital_discarded_at[1],
      },
    };
    clearEmptyValues(formattedDates);

    const categoriesForm = form.getFieldValue('categories');
    const fileType = form.getFieldValue('fileType');

    const filters = {
      ...advancedFilters,
      ...rest,
      ...formattedDates,
      ...(categoriesForm && { categories: categoriesForm?.join('-') }),
    };
    saveAdvancedFilters({ filters });
  };

  useEffect(() => {
    if (!advancedFilters) form.resetFields();
  }, [advancedFilters]);

  return (
    <Form
      id={formsName.ADVANCED_SEARCH_FORM}
      onFinish={handleSubmit}
      form={form}
      layout='vertical'
      initialValues={advancedFilters}
      onValuesChange={updateFilterValues}
    >
      {categoryItems}
      <Form.Item name='internal_id' label='Número interno'>
        <ThemedTextInput placeholder='Digite aqui' />
      </Form.Item>
      <Form.Item name='index' label='Código' validateTrigger='onBlur'>
        <ThemedTextInput
          placeholder='Digite aqui'
          onChange={(e) => handleIndexChange(e.target.value)}
        />
      </Form.Item>
      <Form.Item name='description' label='Descrição'>
        <ThemedTextArea placeholder='Digite aqui' />
      </Form.Item>

      <Form.Item name='fileType' label='Tipo do arquivo'>
        <FileTypeSelect />
      </Form.Item>

      <Form.Item name='attachment' label='Exibir'>
        <DocumentDisplayComponent />
      </Form.Item>
      <Form.Item name='created_by' label='Usuário que cadastrou'>
        <CreatedBySelect />
      </Form.Item>

      <Form.Item name='document_period' label='Período documento'>
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item name='created_at' label='Data cadastro'>
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item
        name='location'
        label={
          <Form.Item name='stored' valuePropName='checked' noStyle>
            <ThemedCheckbox onClick={() => setIsStored(!isStored)}>
              Documento Armazenado Fisicamente
            </ThemedCheckbox>
          </Form.Item>
        }
      >
        <ThemedTextInput
          disabled={!isStored}
          placeholder='Localização física do Documento'
        />
      </Form.Item>
      <Form.Item
        name='physical_discard'
        label='Previsão de Descarte do Doc. Físico'
      >
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item
        name='digital_discard'
        label='Previsão de Descarte do Doc. Eletrônico'
      >
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item
        name='physical_discarded_at'
        label='Data de eliminação do Documento Físico'
      >
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item
        name='digital_discarded_at'
        label='Data de Eliminação do Documento Eletrônico'
      >
        <ThemedRangePicker format='DD/MM/YYYY' />
      </Form.Item>
    </Form>
  );
};
