import ApplicationStateInjector from 'core/application-state-injector';
import { CloudApi } from 'core/api/cloud';

export class CloudService extends ApplicationStateInjector {
  constructor() {
    super();
    this.cloudApi = new CloudApi(this.system, this.userId, this.activeClouds);
  }

  authenticateGoogleDrive() {
    return this.cloudApi.authenticateGoogleDrive();
  }

  getDriveDocumentLinks(documentId) {
    return this.cloudApi.getDriveDocumentLinks(documentId);
  }

  deleteDriveFile(documentId) {
    return this.cloudApi.deleteDriveFile(documentId);
  }

  getGoogleDriveStatus() {
    return this.cloudApi.getGoogleDriveStatus();
  }
  revokeGoogleDrive() {
    return this.cloudApi.revokeGoogleDrive();
  }

  getDocumentLinks(documentId) {
    return this.cloudApi.getDocumentLinks(documentId);
  }
}
