import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: #d0fa94;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem 2rem 0.5rem;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  .anticon svg {
    color: #212112;
  }

  .header-container {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    color: #212112;
    cursor: default;
    line-height: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;

    p {
      margin: 5px;
      font-weight: 500;
    }
    .plan-type {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  img {
    display: flex;
    margin: 0.5rem 2rem;
    width: 12rem;
    padding: 6px;
    @media screen and (max-width: 768px) {
      border: none;
    }
  }
`;

export const NavMenu = styled.div`
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 30px;

  @media screen and (max-width: 820px) {
    font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  color: #fff;

  p {
    @media screen and (max-width: 490px) {
      display: none;
    }
  }

  .ant-dropdown-link {
    color: #21211a;
    font-weight: 500;
    margin: 0 10px;

    &:hover {
      transition: 0.2s;
      opacity: 0.7;
    }
  }
`;

export const SignOutButton = styled.p`
  color: #2d2d2d;
  margin-bottom: 0rem;
  font-weight: 500;
  :hover {
    cursor: pointer;
    color: #ff2e1f;
    transition: 0.2s;
  }
`;
export const Profile = styled.a`
  font-weight: 500;
`;
export const DropdownItem = styled.a`
  font-weight: 500;
`;
