import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { Select } from './Select';
import { AddNewList } from 'pages/documents/styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { getHighestId } from 'common/utilities/getHighestId';
import { ModalTypes } from 'common/enums/modals.enum';
import { useModal } from 'common/hooks/use-modal';
import { getCategories } from 'common/redux/permissions/selectors';
import { createCategory } from 'common/redux/permissions/thunks';
import styled from 'styled-components';

export const ThemedSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 20px !important;
    border: 1px solid #9cdb37 !important;
    height: 2.5rem !important;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    font-style: italic !important;
  }
`;

export const CategorySelect = ({ customEmptyMessage, category, ...rest }) => {
  const [openModal] = useModal(ModalTypes.CATEGORIES);
  const addCategory = useReduxAction(createCategory);

  const categories = useReduxState(getCategories);
  const selectedCategory = categories[category._id];
  const suggestedId = getHighestId(selectedCategory);

  const allowedCategories = selectedCategory?.filter(
    (category) => category.hasPermission || true
  );

  const handleCreateCategory = (values) => {
    const payload = {
      category_type: category._id,
      ...values,
    };
    addCategory(payload);
  };

  const CreateNewCategory = () => {
    return (
      <AddNewList
        onClick={() =>
          openModal({
            modalProps: {
              title: `Adicionar ${category.name}`,
              onCreate: handleCreateCategory,
              suggestedId,
            },
          })
        }
      >
        <AiOutlinePlus /> Adicionar {'>'} {category.name}
      </AddNewList>
    );
  };
  return (
    <ThemedSelect
      selectOptions={allowedCategories}
      customEmptyMessage={customEmptyMessage}
      dropdownRender={(menu) => (
        <>
          {menu}
          <CreateNewCategory />
        </>
      )}
      {...rest}
    />
  );
};
