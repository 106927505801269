import { BaseApi } from 'core/api';

export class FeedbackApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`auth/feedback`);
  }
  async postFeedback(requestParams) {
    return this.post(requestParams);
  }

  async requestContact(contactParams) {
    this.setUrl(`users/contact`);
    return this.post(contactParams);
  }
}
