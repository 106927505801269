import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  width: 100%;
  font-weight: 500;

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    background-color: #3e8508;
    height: 2em;
    width: 1px;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: unset;
  }

  tr:nth-child(even) {
    background: #f0f0f0;
  }
  tr:nth-child(odd) {
    background: #fff;
  }

  .ant-table-cell {
    font-size: 0.8rem;
  }
  .ant-table-thead > tr > th {
    font-size: 0.85rem;
    font-weight: 600;
    background-color: #dcffc2;
  }
  tr.ant-table-row.ant-table-row-level-0:nth-child(even)
    .ant-table-cell-fix-right {
    background: #f0f0f0;
  }
  tr.ant-table-row.ant-table-row-level-0:nth-child(odd)
    .ant-table-cell-fix-right {
    background: #fff;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
