import { BaseApi } from 'core/api';

export class PaymentsApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`payment`);
  }

  async getPlans() {
    this.setUrl(`${this.url}`);
    return this.get();
  }
}
