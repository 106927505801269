import styled from 'styled-components';
import Img from '../Landing Page/assets/background_hero6.png';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-image: url(${Img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f0fcd6;
  overflow: hidden;


  img {
    width: 15rem;
    height: 4.5rem;
  }
  strong {
    font-weight: 700;
    margin: 0 2rem;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 30px;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 25rem;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavLink = styled.a`
  color: #21211a;
  background: linear-gradient(90deg, #f0fcd6 0%, #d0fa94 0%, #d0fa94 100%);
  display: flex;
  margin: 0.5rem;
  width: 30rem;
  padding: 7.5px;
  font-weight: 500;
  align-items: center;
  justify-content: start;
  border-radius: 30px;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  font-size: 1.2rem;
  border: 1px solid #21211a;

  :hover {
    color: #000;
    box-shadow: 1px 1px #212112;
    background-color: #d0fa94;
  }

  @media (max-width: 768px) {
    width: 22rem;
    padding: 8px;
    font-weight: 500;
    align-items: center;
    justify-content: start;
    border-radius: 30px;
    font-size: 0.8rem;
    margin: 10px;
  }
`;
