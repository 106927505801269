const notFound = { matchExists: false, match: null, matchIdx: null };

export const binarySearch = ({ values, match, key, reverse = false }) => {
  if (!values || !match) return notFound;
  let min = 0;
  let max = values.length;
  while (true) {
    const halfIdx = parseInt(min / 2 + max / 2);
    let guess = values[halfIdx];

    if (key) {
      guess = guess[key];
    }

    if (guess === match) {
      return { matchExists: true, match: values[halfIdx], matchIdx: halfIdx };
    }

    if (values.length === 1) {
      return notFound;
    }

    if (guess > match) {
      reverse ? (min = halfIdx) : (max = halfIdx);
    } else if (guess < match) {
      reverse ? (max = halfIdx) : (min = halfIdx);
    }
  }
};
