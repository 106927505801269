import React, { useEffect, useState } from 'react';
import RegisterUserModal from 'pages/usuarios/userManager';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import { ButtonConfigTable } from 'components/Buttons';
import {
  ButtonGroup,
  DeleteButton,
  Routes,
} from 'components/Tables/Categories/style';
import { Table } from 'antd';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa';
import { useReduxState } from 'common/hooks/use-redux-state';
import {
  getInvites,
  getIsManagingUsers,
  getUsers,
} from 'common/redux/users/selectors';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { PathIndicator } from 'components/PathIndicator';
import {
  createInvite,
  deleteInvite,
  deleteUser,
  fetchUsers,
  resendInvite,
  updateUser,
} from 'common/redux/users/thunks';
import { Roles, RolesText } from 'common/enums/roles.enum';
import { ConfirmDelete } from 'components/ConfirmDelete';
import {
  getCurrentCompanyId,
  getCurrentUserId,
  getUserRole,
} from 'common/redux/auth/selectors';
import { createMaskedString } from 'components/Input/MaskInput';
import { MaskTypes } from 'common/enums/maskTypes.enum';
import { EditButton, ResendButton } from './style';
import { StyledTable } from 'components/Tables/style';
import { maskText } from 'common/utilities/maskText';
import UserManager from 'pages/usuarios/userManager';
import { MdEdit } from 'react-icons/md';
import { setManagingUsers } from 'common/redux/users/reducers';
import { getHiredPlan } from 'common/redux/payments/selectors';

export default function SystemUsers() {
  const Column = Table;
  const text = <span>Usuários que tem acesso ao sistema </span>;
  const [tableData, setTableData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const users = useReduxState(getUsers);
  const invites = useReduxState(getInvites);
  const userRole = useReduxState(getUserRole);
  const currentUserId = useReduxState(getCurrentUserId);
  const companyId = useReduxState(getCurrentCompanyId);
  const isManagingUsers = useReduxState(getIsManagingUsers);
  const hiredPlan = useReduxState(getHiredPlan);

  const _fetchUsers = useReduxAction(fetchUsers);
  const _updateUser = useReduxAction(updateUser);
  const _deleteUser = useReduxAction(deleteUser);
  const _createInvite = useReduxAction(createInvite);
  const _deleteInvite = useReduxAction(deleteInvite);
  const _resendInvite = useReduxAction(resendInvite);
  const _setManagingUsers = useReduxAction(setManagingUsers);

  const overUserLimit =
    hiredPlan?.limits.users <= users?.length + invites?.length;

  const openUserManager = (row = null) => {
    setCurrentRow(row);
    _setManagingUsers(true);
  };

  const canDeleteRow = (record) =>
    record.roleId != Roles.OWNER &&
    record._id != currentUserId &&
    userRole !== Roles.USER;

  const handleDelete = async (id) => {
    await _deleteUser(id);
  };

  useEffect(() => {
    _fetchUsers();
  }, []);

  useEffect(() => {
    setTableData([
      ...invites.map((invite) => ({
        ...invite,
        isInvite: true,
        name: 'Convite Pendente',
      })),
      ...users,
    ]);
  }, [JSON.stringify(invites), JSON.stringify(users)]);

  return isManagingUsers ? (
    <UserManager
      currentRow={currentRow}
      onCreate={_createInvite}
      onEdit={_updateUser}
      title={currentRow ? 'Editar usuário' : 'Convidar usuário'}
    />
  ) : (
    <>
      <PathIndicator path={['Configurações', 'Usuários']} helperText={text} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {userRole <= Roles.ADMIN && (
          <ButtonConfigTable
            disabled={overUserLimit}
            title={
              overUserLimit ? 'Limite de usuários atingido' : 'Convidar usuário'
            }
            onClick={() => openUserManager(null)}
          >
            <AiOutlinePlus style={{ marginRight: '4px' }} size={20} /> Adicionar
          </ButtonConfigTable>
        )}
      </div>
      <div data-tour='usuarios'>
        <StyledTable
          style={{ padding: '15px' }}
          size='small'
          scroll={{ x: '100%' }}
          dataSource={tableData}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} Usuários encontrados`,
            total: tableData.length,
          }}
        >
          <Column title='Usuário' width={120} dataIndex='name' key='name' />
          <Column title='E-mail' width={120} dataIndex='email' key='email' />
          <Column
            title='Perfil'
            width={120}
            dataIndex='companies'
            key='role'
            render={(companies) => {
              const company = companies?.find((c) => c.companyId == companyId);
              return RolesText[company?.roleId];
            }}
          />
          <Column
            title='CPF/CNPJ'
            width={120}
            dataIndex='identificationDocument'
            key='identificationDocument'
            render={(text) => maskText(text, '*')}
          />
          {userRole <= Roles.ADMIN && (
            <Column
              width={50}
              title='Ações'
              key={'action' + Math.random()} //TODO: find better way to update table row
              render={(_, record) =>
                record.isInvite ? (
                  userRole <= Roles.ADMIN && (
                    <ButtonGroup>
                      <ThemedTooltip placement='bottom' title='Reenviar email'>
                        <ResendButton
                          size={19}
                          onClick={() =>
                            _resendInvite({ inviteId: record._id })
                          }
                        />
                      </ThemedTooltip>
                      <ConfirmDelete
                        onConfirm={() =>
                          _deleteInvite({ inviteId: record._id })
                        }
                        placement='topLeft'
                        title={`Deseja excluir o convite para o email "${record.email}"?`}
                      >
                        <DeleteButton variant='contained'>
                          <ThemedTooltip placement='bottom' title='Excluir'>
                            <FaTrashAlt size={17} />
                          </ThemedTooltip>
                        </DeleteButton>
                      </ConfirmDelete>
                    </ButtonGroup>
                  )
                ) : (
                  <ButtonGroup>
                    <ThemedTooltip
                      placement='topRight'
                      title='Atualizar usuário'
                    >
                      <EditButton>
                        <MdEdit
                          size={20}
                          onClick={() => openUserManager(record)}
                        />
                      </EditButton>
                    </ThemedTooltip>
                    {canDeleteRow(record) && (
                      <ConfirmDelete
                        placement='topLeft'
                        title={`Deseja excluir o usuário "${record.name}"?`}
                        onConfirm={() => handleDelete(record._id)}
                      >
                        <DeleteButton variant='contained'>
                          <ThemedTooltip placement='bottom' title='Excluir'>
                            <FaTrashAlt size={15} />
                          </ThemedTooltip>
                        </DeleteButton>
                      </ConfirmDelete>
                    )}
                  </ButtonGroup>
                )
              }
            />
          )}
        </StyledTable>
      </div>
    </>
  );
}
