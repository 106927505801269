import React, { useRef, useState } from 'react';
import { Navigation } from 'pages/Landing Page/Navigation';
import { Link } from 'react-router-dom';
import { posts } from './Post';
import 'antd/lib/pagination/style/css';
import { Pagination } from 'antd';
import {
  BlogCard,
  BlogContainer,
  BlogImage,
  BlogTitle,
  SubTitlePage,
  TitlePage,
} from './style';
import { Footer } from 'pages/Landing Page/Footer';

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 9;
  const blogRef = useRef(null);
  const refs = {
    blog: blogRef,
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = posts.slice(indexOfFirstCard, indexOfLastCard);
  return (
    <>
      <Navigation refs={refs} />
        <TitlePage>Arquivo inteligente</TitlePage>
        <SubTitlePage>
          O seu portal de informações sobre arquivo empresarial e gestão de
          documentos
        </SubTitlePage>
        <BlogContainer ref={blogRef}>
          {currentCards.map((post) => (
            <>
              <Link to={`/post/${post.id}`} key={post.id}>
                <BlogCard>
                  <BlogImage src={post.image} alt={post.title} />
                </BlogCard>
                <BlogTitle>{post.title}</BlogTitle>
              </Link>
            </>
          ))}
        </BlogContainer>
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
          defaultCurrent={currentPage}
          total={posts.length}
          pageSize={cardsPerPage}
          onChange={(page) => setCurrentPage(page)}
        />
      <Footer />
    </>
  );
};

export default Home;
