import { useState, useLayoutEffect } from 'react';

export const useWindowSize = (enabled = true, offset = 0) => {
  const [size, setSize] = useState([0, 0]);
  const [width, height] = size;

  useLayoutEffect(() => {
    if (enabled) {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      updateSize();

      window.addEventListener('resize', updateSize);

      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);
  return {
    windowSize: width,
    isMobileScreen: width <= 500 + offset,
    isSmallScreen: width <= 900 + offset,
    isTouchDevice: 'ontouchstart' in window,
    size,
  };
};
