const TamiaIcon = () => (
  <svg
  style={{width:"2rem", marginBottom:'0.4rem'}}
  version='1.1'
  id='Camada_1'
  x='0px'
  y='0px'
  viewBox='0 0 483.2 250'
  xmlns='http://www.w3.org/2000/svg'
>
  <g>
    <g>
      <path
        fill='#9cdb37'
        d='M437,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0c-26.2,0.4-49.1,14.3-62.2,35c-7.4,11.6-11.6,25.3-11.6,40
    c0,5.8,0.7,11.4,1.9,16.8c0.7,3.2,1.7,6.3,2.8,9.3c2.4,9.5,3.7,19.4,3.7,29.7c0,12.1-1.8,23.8-5.2,34.8
    c12.7-6.1,23.5-15.8,31-27.6c2.8-4.4,5.2-9.2,7-14.2l0,0c6.5-15.9,21.3-27.5,38.9-29.4l0,0c23.7-2.6,42.2-22.7,42.2-47.1
    C483.2,21.7,462.6,0.7,437,0z'
      />
      <path
        fill='#9cdb37'
        d='M340.9,166.5l0.3-0.4c5.2-10.2,8.2-21.8,8.2-34.1c0-41.4-33.7-75.1-75.1-75.1c-10.1,0-19.7,2-28.5,5.6
    l-0.3-0.1l-102.5,49.1l-7.7-26.4c9.5,2.5,20,0.5,27.9-6.5c9.7-8.6,12.5-22.1,8.1-33.5c-10.7-5.2-23.9-3.8-33.3,4.5
    c-6.5,5.7-9.9,13.6-10.1,21.6c-0.8-0.9-1.7-1.8-2.7-2.6c-9.2-7.7-21.6-8.9-31.8-4.3L29.4,92.9C20,98,13.6,108,13.6,119.4
    c0,13.9,9.4,25.6,22.1,29.1l92,20.3L92.5,250h32.1l58.6-68.2l30.6,6.6c-3.4-6.6-5.4-14-5.4-21.9c0-7.3,1.7-14.3,4.7-20.5
    c0.6,0,1.1-0.1,1.7-0.1c5.7,0,10.9,2.4,14.5,6.3l-0.1,0.1c-2.2,4.2-3.5,9-3.5,14.1c0,14.2,9.8,26.1,23.1,29.3l129.2,28.2l12,25.9
    h38.5l-21.7-32L340.9,166.5z M63.6,105.5c2.2-6.8,8.1-12.2,15.7-13.4c7.6-1.2,14.8,2.1,19,7.9c-2.2,6.8-8.1,12.2-15.7,13.4
    C75.1,114.6,67.9,111.3,63.6,105.5z'
      />
      <polygon fill='#9cdb37' points='59.2,168.6 0,250 31.2,250 101.9,178' />
      <polygon
        fill='#9cdb37'
        points='229,250 264,250 293.2,220.3 256.5,212.2 		'
      />
    </g>
  </g>
</svg>
  );
  
  export default TamiaIcon;
  