import styled from 'styled-components';
import { InputNumber } from 'antd';

export const ThemedNumberInput = styled(InputNumber)`
  display: flex;
  font-style: normal;
  align-items: center;
  height: 2.5rem;
  border-radius: 20px;
  width: 100%;
  border: 1px solid #9cdb37 !important;

  ::placeholder {
    color: #a2a2a2;
  }
`;
