import { Input, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';

export const ConfirmDelete = ({
  children,
  onConfirm,
  title,
  placement,
  textConfirmation,
  showWarning,
}) => {
  const [confirmationInput, setConfirmationInput] = useState();
  const handleDelete = async () => {
    onConfirm();
  };

  return (
    <Popconfirm
      title={
        <>
          {title}
          {textConfirmation && (
            <Input
              style={{ margin: '3px 0' }}
              value={confirmationInput}
              onChange={(input) => setConfirmationInput(input.target.value)}
              placeholder='Digite o valor informado'
            />
          )}
          {showWarning && (
            <span style={{ color: '#ee2222' }}>
              <i>Essa ação não poderá ser revertida</i>
            </span>
          )}
        </>
      }
      placement={placement}
      onConfirm={handleDelete}
      okText='Sim'
      onOpenChange={() => setConfirmationInput('')}
      okButtonProps={{
        disabled: !!textConfirmation && textConfirmation != confirmationInput,
      }}
    >
      {children}
    </Popconfirm>
  );
};
