import React from 'react';
import styled from 'styled-components';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { Flex } from './Flex';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';

const Container = styled(Flex)`
  margin: 10px;
  width: fit-content;
`;

const PathContainer = styled(Flex)`
  color: #4c4c4c;
  align-items: center;
  font-size: 15px;

  & > * {
    margin: 0 3px;
    :nth-last-child(2) {
      font-weight: bold;
    }

    :last-child {
      display: none;
    }
  }
`;

export const PathIndicator = ({ path, helperText, width, placement }) => {
  return (
    <Container>
      <ThemedTooltip
        placement={placement || 'bottom'}
        width={width}
        title={helperText}
        showInformationIcon
      >
        <PathContainer>
          {path?.map((route, idx) => (
            <React.Fragment key={idx}>
              <span id='info'>{route}</span><AiOutlineSwapRight />
            </React.Fragment>
          ))}
        </PathContainer>
      </ThemedTooltip>
    </Container>
  );
};
