export const Routes = {
  HOME: '/home',
  LOGIN: '/login',
  CONTACT: '/contato',
  DOCUMENTS: '/documentos',
  STORAGE: '/repositorios',
  PROFILE: '/perfil',
  USERS: '/usuarios',
  CATEGORIES: '/classes',
  FAQ: '/perguntas-frequentes',
  BLOG:'/blog',
  POSTS:'/post',
  VIDEOS: '/videos',
  TUTORIAL: '/tutorial',
  TERMS_OF_SERVICE: '/termos',
  SUPPORT: '/central-ajuda',
  PASS_RECOVER: '/recuperacao',
  DOCUMENTS_EDIT: '/documentos/editar',
  FEEDBACK: '/feedback',
  LEARN_MORE: '/saiba-mais',
  TRIAL_REGISTER: '/cadastro',
  GROUPS: '/controle-de-acesso',
  PHYSICAL_TEMPORALITY: '/descarte-fisico',
  DIGITAL_TEMPORALITY: '/descarte-eletronico',
  AUDIT: '/auditoria'
};
