import styled from 'styled-components';

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eaeaea;
  background-color: #ab0205;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  height: 1.7rem;
  width: 1.7rem;

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;
