import React, { useState } from 'react';
import It from './assets/instagram.png';
import Wpp from './assets/wpp.png';
import Letter from './assets/envelope.png';
import Modal from './newsletter';

export const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <footer id='contato'>
      <h1>CONTATO</h1>
      <div className='columns'>
        <div className='footer-column'>
          <h3 className='footer-title'>Siga a gente</h3>
          <p className='footer-text'>
            Já deu uma passada no nosso perfil no Instagram? Siga a gente e
            acompanhe as novidades.
          </p>
          <a href='https://www.instagram.com/tamia.app/' target='_blank'>
            <img
              src={It}
              alt='Instagram Tamia'
              style={{ width: '20px' }}
              class='social-logo'
            />
            @tamia.app
          </a>
        </div>
        <div class='footer-column'>
          <h3 class='footer-title'>Fale conosco</h3>
          <p class='footer-text'>
            Curtiu o que viu por aqui? Fale com um dos nossos consultores e faça
            um teste na sua empresa.
          </p>
          <a href='https://wa.me/5521968996222' target='_blank'>
            <img
              src={Wpp}
              alt='Instagram Tamia'
              style={{ width: '25px' }}
              class='social-logo'
            />
            Fale com o consultor
          </a>
        </div>
        <div class='footer-column'>
          <h3 class='footer-title'>Assine a Newsletter</h3>
          <p class='footer-text'>
            Inscreva-se e receba as novidades sobre o nosso sistema e o mundo da
            gestão inteligente de arquivos.
          </p>
          <a onClick={openModal}>
            <img
              src={Letter}
              style={{ width: '25px' }}
              alt='Instagram Tamia'
              class='social-logo'
            />
            Cadastre-se aqui
          </a>
        </div>
      </div>
      <Modal showModal={showModal} closeModal={closeModal} />
    </footer>
  );
};
