import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getDocuments } from 'common/redux/documents/selectors';
import { getUserIsOperator } from 'common/redux/auth/selectors';
import { generateIndex } from 'common/utilities/generateIndex';
import { StyledTable } from '../style';
import { ThemedPopover } from 'components/Themed/ThemedPopover';
import { columns } from './homeColumns';
import { getCategoryNames } from 'common/redux/permissions/selectors';
import { bytesToMb } from 'common/utilities/bytesToMb';

const toLocalDate = (date) => {
  if (!date) return '';
  const formated = new Date(date).toLocaleDateString('pt-BR');

  if (formated == 'Invalid Date') return '';
  return formated;
};

const CustomRow = ({ headerText, title, ...props }) => {
  return title && title.length > 15 ? (
    <ThemedPopover content={title} title={headerText}>
      <td {...props} />
    </ThemedPopover>
  ) : (
    <td {...props} />
  );
};

export default function TableHome() {
  const documents = useReduxState(getDocuments);
  const categoryNames = useReduxState(getCategoryNames);
  const [formattedDocs, setFormattedDocs] = useState([]);

  useEffect(() => {
    const formatted = documents.map((document) => ({
      uid: document._id,
      ID: document?.id,
      Código: generateIndex(document),
      'Data de cadastro': toLocalDate(
        document.created_at || document.audit?.created_at
      ),
      ...document.categories.reduce((current, next) => {
        current[next.category_name] = next.name;
        return current;
      }, {}),
      'Número Interno': document?.internal_id,
      Descrição: document.description,
      'Tipo do arquivo': document.files?.[0]?.type.split('/')[1],
      'Tamanho do arquivo': bytesToMb(document.files?.[0]?.size, true),
      'Período do documento > Data início': toLocalDate(document.period_start),
      'Período do documento > Data fim': toLocalDate(document.period_end),
      'Armazenado Fisicamente': !!document.location ? 'Sim' : 'Não',
      'Localização Física': document.location,
      'Previsão de descarte do documento físico': toLocalDate(
        document?.physical_discard_date
      ),
      'Previsão de descarte do documento eletrônico': toLocalDate(
        document?.digital_discard_date
      ),
      'Data de eliminação do Documento Físico': toLocalDate(
        document?.physical_discarded_at
      ),
      'Data de eliminação do Documento eletrônico': toLocalDate(
        document?.digital_discarded_at
      ),
      'Criado por': document.audit?.created_by?.email,
      'Editado por': document.audit?.updated_by?.email,
      'Documento contém anexo': !!document?.files?.length ? 'Sim ' : 'Não ',
    }));
    setFormattedDocs(formatted);
  }, [documents]);

  return (
    <StyledTable
      style={{ paddingRight: '18px' }}
      size='small'
      scroll={{ x: '100%' }}
      dataSource={documents}
      columns={columns(categoryNames, formattedDocs)}
      components={{
        body: { cell: CustomRow },
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} documentos`,
        total: documents.length,
      }}
    />
  );
}
