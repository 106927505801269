import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { useEffect, useRef, useState } from 'react';

const generateMaskArray = (mask) =>
  mask.split('').map((n) => (isNaN(n) || n == ' ' ? n : null));

const generateValueArray = (inputValue) =>
  inputValue.split('').filter((n) => !isNaN(n) && n !== ' ');

function* makeGeneratorFunction(values) {
  for (let i of values) {
    yield i;
  }
}

const mergeMask = (generator, mask) => {
  return mask.map((value) => (value ? value : generator.next().value || null));
};

const handleMask = (mask, value) => {
  const valueSize = value.length;

  if (Array.isArray(mask)) {
    mask.sort((x, y) => x.length - y.length);
    const filteredValues = mask.filter(
      (option) => generateValueArray(option).length >= valueSize
    );
    return filteredValues[0] || mask[mask.length - 1];
  } else return mask;
};

export const createMaskedString = (value, masks) => {
  if (!value) return;
  const valueArray = generateValueArray(value);
  const selectedMask = handleMask(masks, valueArray);
  return mergeMask(
    makeGeneratorFunction(valueArray),
    generateMaskArray(selectedMask)
  );
};

export const MaskInput = ({ value, onChange, mask, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);
  const [maskLength, setMaskLength] = useState(null);
  const inputRef = useRef(null);

  const handleInput = (value) => {
    try {
      const maskedString = createMaskedString(value, mask);
      setMaskLength(maskedString.length);
      const finalIndex = maskedString.indexOf(null); //Position of last input
      const trimmedValue = (
        finalIndex === -1 ? maskedString : maskedString.slice(0, finalIndex)
      ).join('');

      setInputValue(trimmedValue);
      onChange(generateValueArray(trimmedValue).join(''));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!value) return;
    handleInput(value);
  }, [value]);

  const removeLastChar = (event) => {
    const { selectionStart, selectionEnd } = event.target;

    if (!inputValue) return;
    const lastChar = inputValue.slice(-1);

    if (
      event.key === 'Backspace' &&
      (isNaN(lastChar) || lastChar === ' ' || inputValue.length == 1) &&
      !(selectionEnd - selectionStart)
    ) {
      setInputValue(inputValue.slice(0, -1));
    }
  };

  return (
    <ThemedTextInput
      minLength={maskLength}
      ref={inputRef}
      value={inputValue}
      onChange={(e) => handleInput(e.target.value)}
      onKeyDown={removeLastChar}
      {...rest}
    />
  );
};
