import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { Flex } from 'components/Flex';

export const ButtonNewDoc = styled(Link)`
  display: flex;
  padding: 9px;
  background-color: #d0fa94;
  color: #21211a;
  width: 13rem;
  min-width: 12rem;
  height: 2.7rem;
  margin: 10px;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  border: 0;
  text-decoration: none;

  &&:hover {
    background-color: #9cdb37;
    color: #21211a;
    transition: 0.3s;
    opacity: 0.8;
  }
`;
export const ButtonGroup = styled(Link)`
  display: flex;
  margin: 0 5px 15px auto;

  :hover {
    cursor: pointer;
    transition: 0.25s;
  }
`;
export const Routes = styled.h5`
  display: flex;
  align-items: center;
  justify-content: start;
  color: #4c4c4c;
  margin: 10px;
`;

export const FilterAmountContainer = styled.span`
  position: absolute;
  margin-top: 45px;
  right: 23%;
  color: #4e4e4e;
`;

export const CleanFilters = styled.span`
  color: #da2118;
  :hover {
    cursor: pointer;
  }
`;

export const CardContainer = styled(Flex)`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`;

export const CardBody = styled.a`
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%);
  transition: 0.4s;
  flex-direction: column;
  width: 13rem;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 10px 40px 10px;
  text-align: center;
  :hover {
    cursor: default;
  }

  @media screen and (max-width: 636px) {
    width: 7rem;
  }
`;

export const CardHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: #f1f1f1;
  width: 100%;
  background-color: ${({ bg }) => bg || 'unset'};
  border-radius: 4px 4px 0 0;

  @media screen and (max-width: 636px) {
    font-size: 1rem;
  }
`;
export const CardText = styled.div`
  display: flex;
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
  color: #212112;
  flex: 2;
  padding: 5px;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (max-width: 636px) {
    font-size: 0.5rem;
  }
`;

export const TopTable = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Separator = styled.div`
  width: 92%;
  border-bottom: 2px solid #9cdb37;
  margin: 20px 10px;
`;

export const SubTitle = styled.div`
  font-weight: 700;
  color: #4c4c4c;
  margin-left: 10px;
  font-size:17px;
`;
