import {
  FormContainer,
  PageContainer,
  RegisterContainer,
  Subtitles,
} from './styles';
import logo from 'assets/images/tamia_header_black.png';
import { Form, Input } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { MaskInput } from 'components/Input/MaskInput';
import { MaskTypes } from 'common/enums/maskTypes.enum';
import { FormRow } from 'components/Layout/FormRow';
import ThemedButton from 'components/Themed/ThemedButton';
import { useState, useEffect } from 'react';
import { UserService } from 'core/services/user.service';
import { createToast } from 'common/utilities/createToast';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { useQuery } from 'common/hooks/use-query';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { registerUser } from 'common/redux/users/thunks';
import { Link } from 'react-router-dom';
import { Routes } from 'common/enums/routes.enum';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getUserCreatedSuccess } from 'common/redux/users/selectors';
import { Flex } from 'components/Flex';
import { RiFolderInfoLine } from 'react-icons/ri';
import { AiOutlineUser } from 'react-icons/ai';
import { MdAccountBalance } from 'react-icons/md';
import { ThemedCheckbox } from 'components/Themed/ThemedCheckbox';

const formRules = {
  required: [{ required: true, message: 'Campo obrigatório' }],
  password: [
    {
      required: true,
      min: 6,
      message: 'A senha deve conter pelo menos 6 caracteres',
    },
  ],
};

const RegisterForm = () => {
  const [form] = Form.useForm();
  const [inviteInfo, setInviteInfo] = useState(null);
  const query = useQuery();
  const inviteId = query.get('id');
  const createUser = useReduxAction(registerUser);
  const userWasCreated = useReduxState(getUserCreatedSuccess);
  const [agreedTerms, setAgreedTerms] = useState(false);

  const fetchInviteInfo = async () => {
    const userService = new UserService();
    try {
      const inviteInfo = await userService.getInviteInfo(inviteId);
      setInviteInfo(inviteInfo.data);
      form.setFieldsValue(inviteInfo.data);
    } catch {
      setInviteInfo(null);
      createToast({
        type: toastTypes.ERROR,
        message: 'Erro ao carregar informações do convite',
      });
    }
  };

  useEffect(() => {
    if (!inviteId) return;
    fetchInviteInfo();
  }, [inviteId]);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const { password, password_confirm } = values;
    if (password !== password_confirm) {
      createToast({
        type: toastTypes.ERROR,
        message: 'Senhas não conferem',
      });
      return;
    }

    const payload = {
      system: null,
      ...inviteInfo,
      ...values,
      inviteId,
    };
    createUser({ payload });
  };

  return (
    <PageContainer>
      <RegisterContainer>
        <img src={logo} title='GED' />
        <FormContainer>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark={false}
            disabled={userWasCreated || !inviteInfo}
          >
            <Subtitles>
              <MdAccountBalance
                color='green'
                style={{ marginBottom: '-2px', marginRight: '10px' }}
              />
              Dados da conta:
            </Subtitles>
            <Form.Item
              name='company'
              label='Empresa'
              rules={formRules.required}
            >
              <ThemedTextInput placeholder='Digite aqui' />
            </Form.Item>
            <Form.Item
              name='cpf_cnpj'
              label='CPF/CNPJ'
              rules={formRules.required}
            >
              <MaskInput
                mask={MaskTypes.CPF_CNPJ_MASKS}
                placeholder='Digite aqui'
              />
            </Form.Item>
            <Subtitles>
              <AiOutlineUser
                color='green'
                style={{ marginBottom: '-2px', marginRight: '10px' }}
              />
              Dados do administrador da conta:
            </Subtitles>
            <Form.Item name='name' label='Nome' rules={formRules.required}>
              <ThemedTextInput placeholder='Digite aqui' />
            </Form.Item>
            <Form.Item
              name='email'
              label='Email/Login'
              disabled={!inviteInfo || !!inviteInfo?.email}
            >
              <ThemedTextInput placeholder='exemplo@email.com' />
            </Form.Item>
            <FormRow>
              <div className='input-password'>
                <Form.Item
                  label='Senha'
                  name='password'
                  rules={formRules.password}
                >
                  <Input.Password
                    style={{
                      borderRadius: '20px',
                      border: '1px solid #9CDB37',
                    }}
                    placeholder='*********'
                  />
                </Form.Item>
                <Form.Item
                  label='Confirmar senha'
                  name='password_confirm'
                  rules={formRules.password}
                >
                  <Input.Password
                    style={{
                      borderRadius: '20px',
                      border: '1px solid #9CDB37',
                    }}
                    placeholder='*********'
                  />
                </Form.Item>
              </div>
            </FormRow>
            <ThemedCheckbox
              style={{ marginBottom: 20 }}
              onChange={(e) => setAgreedTerms(e.target.checked)}
            >
              Concordo com os{' '}
              <a style={{ color: 'green' }} target='_blank' href='/termos'>
                Termos de Uso
              </a>
            </ThemedCheckbox>
            <ThemedButton
              color='#9cdb37'
              htmlType='submit'
              style={{ width: '100%' }}
              disabled={!agreedTerms}
            >
              Registrar
            </ThemedButton>
            {!inviteInfo && (
              <Flex justify='center' style={{ marginTop: 10, color: 'red' }}>
                Por favor solicite um convite válido
              </Flex>
            )}
          </Form>
        </FormContainer>
        {userWasCreated && (
          <Link style={{ marginTop: 20 }} to={Routes.LOGIN}>
            ← Retornar
          </Link>
        )}
      </RegisterContainer>
    </PageContainer>
  );
};

export default RegisterForm;
