import React, { useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Navigation } from 'pages/Landing Page/Navigation';
import { BsInstagram, BsLinkedin } from 'react-icons/bs';
import { Footer } from 'pages/Landing Page/Footer';
import ImgTamia from 'pages/Landing Page/assets/tamiaBlack.png';
import Form from './Form';
import { posts } from './Post';
import {
  BlogDate,
  Button,
  ButtonContext,
  PostContainer,
  PostContent,
  PostImage,
  PostTitle,
  SidebarContainer,
  SidebarTitle,
  SocialIcon,
  SocialIcons,
  Wrapper,
} from './style';
import { Flex } from 'components/Flex';

const PostDetails = () => {
  const { id } = useParams();
  const post = posts.find((p) => p.id === parseInt(id));
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (!post) {
    return (
      <div>
        <h2>Post não encontrado.</h2>
        <Link to='/blog'>Voltar</Link>
      </div>
    );
  }

  const { title, content, image, date } = post;
  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <Navigation />
      <PostTitle>{title}</PostTitle>
      <Wrapper>
        <PostContainer>
          <PostImage src={image} alt='Imagem do post' />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderBottom: '1px solid #9CDB37',
              marginBottom: '20px',
            }}
          >
            <BlogDate>Data da publicação: {date}</BlogDate>
          </div>
          <PostContent>{content}</PostContent>
        </PostContainer>
        <SidebarContainer>
          <SidebarTitle>
            <img
              style={{ width: '30px', margin: '10px 5px 13px 10px' }}
              src={ImgTamia}
              alt='Imagem tamia'
            />
            Inscreva-se na Newsletter
          </SidebarTitle>
          <Flex justify='center'>
            <Form />
          </Flex>
          <SidebarTitle>Nossas redes sociais</SidebarTitle>
          <SocialIcons>
            <SocialIcon
              href='https://www.linkedin.com/company/t%C3%A2mia/'
              target='_blank'
            >
              <BsLinkedin color='green' />
            </SocialIcon>
            <SocialIcon
              href='https://www.instagram.com/tamia.app/'
              target='_blank'
            >
              <BsInstagram color='green' />
            </SocialIcon>
          </SocialIcons>
        </SidebarContainer>
      </Wrapper>
      <ButtonContext>
        <Button onClick={handleBack}>← VOLTAR</Button>
      </ButtonContext>
      <Footer />
    </>
  );
};

export default PostDetails;
