import React, { useEffect, useState } from 'react';
import { Input, List, Typography } from 'antd';
import { Container, ContainerRightForm, Subtitles } from './styles';
import { Dropzone } from 'components/Dropzone/index';
import { CategorySelect } from 'components/Input/CategorySelect';
import ReactLoading from 'react-loading';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { PathIndicator } from 'components/PathIndicator';
import { Routes } from 'common/enums/routes.enum';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { createDocument, editDocument } from 'common/redux/documents/thunks';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import {
  ThemedDatePicker,
  ThemedRangePicker,
} from 'components/Themed/ThemedDatePicker';
import { ThemedCheckbox } from 'components/Themed/ThemedCheckbox';
import { formsName } from 'common/enums/forms.enum';
import { FormRow } from 'components/Layout/FormRow';
import { useWindowSize } from 'common/hooks/use-window-size';
import { FooterContainer } from 'components/Layout/FooterContainer';
import ThemedButton from 'components/Themed/ThemedButton';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getCategorySettings } from 'common/redux/permissions/selectors';
import { generateIndex } from 'common/utilities/generateIndex';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { MdOutlineClass, MdOutlineDateRange } from 'react-icons/md';
import { RiFileShredLine, RiFolderInfoLine } from 'react-icons/ri';
import { getFileLinks } from 'common/redux/cloud/selectors';
import { Flex } from 'components/Flex';
import { getDocumentLinks } from 'common/redux/cloud/thunks';
import { DocumentsService } from 'core/services/documents.service';
import styled from 'styled-components';
import { ConfirmDelete } from 'components/ConfirmDelete';

const formRules = {
  category: [
    {
      required: true,
      message: 'Selecione um valor',
    },
  ],
};

const RemoveButton = styled.span`
  color: red;
  position: relative;
  bottom: 10px;
  font-size: 10px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const text = 'Cadastre seu arquivo para ser salvo em seu repositório.';
const { TextArea } = Input;
const DocumentManager = () => {
  const [isStored, setIsStored] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [document, setDocument] = useState(null);
  const fileLinks = useReduxState(getFileLinks);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const categorySettings = useReduxState(getCategorySettings);
  const createNewDocument = useReduxAction(createDocument);
  const updateDocument = useReduxAction(editDocument);
  const getDocumentData = useReduxAction(getDocumentLinks);
  const { isMobileScreen } = useWindowSize(true, 50);
  const location = useLocation();

  const isEditing = !!document;

  const handleDocumentClick = async (documentId, key) => {
    const documentService = new DocumentsService();
    const { data } = await documentService.getDocument(documentId, key);
    const { download } = data[0];
    window.open(download);
  };

  const handleDocumentDelete = async (documentId, key) => {
    const documentService = new DocumentsService();
    const { data } = await documentService.deleteDocument(document._id, key);
    getDocumentData(document._id);
  };

  useEffect(() => {
    setDocument(location.state?.document);
  }, [location]);

  useEffect(() => {
    if (!document) return;
    const {
      categories,
      period_start,
      period_end,
      digital_discarded_at,
      physical_discarded_at,
      digital_discard_date,
      physical_discard_date,
    } = document;

    const values = {
      ...document,
      categories: categories.map((category) => category._id),
      document_period: [
        period_start ? moment(period_start) : null,
        period_end ? moment(period_end) : null,
      ],
      digital_discard_date: digital_discard_date
        ? moment(digital_discard_date)
        : null,
      physical_discard_date: physical_discard_date
        ? moment(physical_discard_date)
        : null,
      digital_discarded_at: digital_discarded_at
        ? moment(digital_discarded_at)
        : null,
      physical_discarded_at: physical_discarded_at
        ? moment(physical_discarded_at)
        : null,
      index: generateIndex(document),
    };

    form.setFieldsValue(values);
    setIsStored(!!values.location);
  }, [document?._id]);

  const categoryItems = categorySettings.map((category, idx) => (
    <FormRow>
      <Form.Item
        name={['categories', idx]}
        label={category.name}
        rules={formRules.category}
      >
        <CategorySelect category={category} />
      </Form.Item>
    </FormRow>
  ));

  const handleSubmit = async (payload) => {
    setIsLoading(true);
    try {
      if (document) {
        const editPayload = {
          params: { ...payload, _id: document?._id, id: document?.id },
          documentId: document?._id,
        };
        updateDocument(editPayload);
      } else {
        createNewDocument(payload);
      }
      setIsLoading(false);

      form.resetFields();
      if (!createAnother) {
        history.push('/home');
      } else {
        const { categories } = payload;
        form.setFieldsValue({ categories });
        setIsStored(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  const handleChange =
    (!isEditing &&
      ((values) => {
        if (values?.[0].name?.[0] !== 'files') return;
        const files = form.getFieldValue('files');
        form.setFieldsValue({ description: files?.[0]?.name || '' });
      })) ||
    null;
  return (
    <>
      <PathIndicator
        path={['Início', `${isEditing ? 'Editar' : 'Novo'} Documento`]}
        helperText={text}
      />

      <Form
        id={formsName.DOCUMENTS_FORM}
        onFinish={handleSubmit}
        onFieldsChange={handleChange}
        form={form}
        layout={!isMobileScreen && 'vertical'}
        hideRequiredMark
      >
        <Container>
          <Flex direction='column' style={{ height: 'fit-content', flex: 1 }}>
            <Form.Item name='files' style={{ flex: 1 }}>
              <Dropzone />
            </Form.Item>
            {!!document?.files?.length && (
              <List
                style={{ height: 'fit-content' }}
                header={<div>Histórico de versões</div>}
                bordered
                dataSource={fileLinks?.versions}
                renderItem={(item) => {
                  const split = item.split('/');
                  let fileName = split[split.length - 1];
                  const documentId = split[split.length - 2];

                  const date = Number(fileName.split('-')[0]);
                  fileName = fileName.replace(`${date}-`, '');
                  return (
                    <List.Item>
                      <List.Item.Meta
                        description={
                          <a
                            onClick={() =>
                              handleDocumentClick(documentId, item)
                            }
                          >
                            {fileName}
                          </a>
                        }
                        title={
                          <>
                            {new Date(date).toLocaleString('pt-BR')}{' '}
                            {fileLinks?.versions?.length > 1 && (
                              <ConfirmDelete
                                title={
                                  <>
                                    Tem certeza que deseja remover esta versão?{' '}
                                    <br />
                                  </>
                                }
                                showWarning
                                onConfirm={() =>
                                  handleDocumentDelete(documentId, item)
                                }
                              >
                                <RemoveButton>X</RemoveButton>
                              </ConfirmDelete>
                            )}
                          </>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </Flex>
          <ContainerRightForm>
            <Subtitles>
              <MdOutlineClass
                color='green'
                style={{ marginBottom: '-3px', marginRight: '10px' }}
              />
              Classes:
            </Subtitles>
            {categoryItems}
            <Subtitles>
              <RiFolderInfoLine
                color='green'
                style={{ marginBottom: '-3px', marginRight: '10px' }}
              />
              Informações complementares:
            </Subtitles>
            <FormRow>
              <Form.Item name='internal_id' label='Número interno'>
                <ThemedTextInput placeholder='Digite aqui' />
              </Form.Item>
            </FormRow>

            <FormRow>
              <Form.Item name='description' label='Descrição'>
                <TextArea
                  showCount
                  maxLength={10000}
                  placeholder='Digite aqui'
                />
              </Form.Item>
            </FormRow>
            <FormRow>
              <Form.Item name={'document_period'} label='Período do documento'>
                <ThemedRangePicker
                  format='DD/MM/YYYY'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </FormRow>

            <FormRow align='center'>
              <Form.Item
                name='location'
                label={
                  <ThemedCheckbox onClick={() => setIsStored(!isStored)}>
                    Documento Armazenado Fisicamente
                  </ThemedCheckbox>
                }
              >
                <ThemedTextInput
                  disabled={!isStored}
                  placeholder='Localização física do Documento'
                />
              </Form.Item>
            </FormRow>
            <Subtitles>
              <MdOutlineDateRange
                color='green'
                style={{ marginBottom: '-3px', marginRight: '10px' }}
              />
              Previsão de descarte:
            </Subtitles>
            <FormRow>
              <Form.Item
                name='physical_discard_date'
                label='Documento Físico em:'
              >
                <ThemedDatePicker format='DD/MM/YYYY' />
              </Form.Item>
            </FormRow>
            <FormRow>
              <Form.Item
                name='digital_discard_date'
                label='Documento Eletrônico em:'
              >
                <ThemedDatePicker format='DD/MM/YYYY' />
              </Form.Item>
            </FormRow>
            {document && (
              <>
                <Subtitles>
                  <RiFileShredLine
                    color='green'
                    style={{ marginBottom: '-3px', marginRight: '10px' }}
                  />
                  Data da eliminação:
                </Subtitles>
                <FormRow>
                  <Form.Item
                    name='physical_discarded_at'
                    label='Documento Físico em:'
                  >
                    <ThemedDatePicker format='DD/MM/YYYY' />
                  </Form.Item>
                </FormRow>
                <FormRow>
                  <Form.Item
                    name='digital_discarded_at'
                    label='Documento Eletrônico em:'
                  >
                    <ThemedDatePicker format='DD/MM/YYYY' />
                  </Form.Item>
                </FormRow>
              </>
            )}

            <FooterContainer style={{ justifyContent: 'center' }}>
              <ThemedButton
                color='#f6faf5'
                style={{ color: '#ff4747', borderColor: '#ff4747' }}
                onClick={() => history.push(Routes.HOME)}
              >
                Cancelar
              </ThemedButton>

              {!isEditing && (
                <ThemedButton
                  color='#f6faf5'
                  style={{
                    border: '1px solid #9cdb37',
                    minWidth: '150px',
                  }}
                  form={formsName.DOCUMENTS_FORM}
                  key='create-another'
                  htmlType='submit'
                  onClick={() => setCreateAnother(true)}
                >
                  Salvar e Duplicar
                </ThemedButton>
              )}
              <ThemedButton
                color='#9cdb37'
                style={{ borderColor: '#9cdb37' }}
                form={formsName.DOCUMENTS_FORM}
                key='submit'
                htmlType='submit'
                onClick={() => setCreateAnother(false)}
              >
                Salvar
              </ThemedButton>
            </FooterContainer>
          </ContainerRightForm>
        </Container>
      </Form>
    </>
  );
};

export default DocumentManager;
