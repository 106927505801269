import { combineReducers } from 'redux';
import authReducer from 'common/redux/auth/reducers';
import documentsReducer from 'common/redux/documents/reducers';
import usersReducer from 'common/redux/users/reducers';
import filtersReducer from 'common/redux/filters/reducers';
import cloudReducer from 'common/redux/cloud/reducers';
import infoReducer from 'common/redux/info/reducers';
import modalsReducer from 'common/redux/modals/reducers';
import layoutReducer from 'common/redux/layout/reducers';
import permissionsReducer from 'common/redux/permissions/reducer';
import { paymentReducer } from './payments/reducer';

const combinedReducer = combineReducers({
  auth: authReducer,
  cloud: cloudReducer,
  documents: documentsReducer,
  filters: filtersReducer,
  info: infoReducer,
  layout: layoutReducer,
  modals: modalsReducer,
  payment: paymentReducer,
  permissions: permissionsReducer,
  users: usersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
