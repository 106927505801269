import React from 'react';
import { PageContent } from 'components/Sidebar';
import { Route as ReactDOMRoute, Redirect, useHistory } from 'react-router-dom';
import { useReduxState } from 'common/hooks/use-redux-state';
import {
  getUserCompanies,
  getCurrentCompanyId,
} from 'common/redux/auth/selectors';
import { AuthService } from 'core/services/auth.service';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { logout } from 'common/redux/auth/reducers';
import { createToast, toastTypes } from 'common/utilities/createToast';

const Route = ({
  isPrivate = false,
  isShared = false,
  component: Component,
  description: Description,
  ...rest
}) => {
  const isLoggedIn = !!useReduxState(getCurrentCompanyId);
  const userCompanies = useReduxState(getUserCompanies);

  const history = useHistory();
  const signOut = useReduxAction(logout);

  if (!document.cookie.includes('X-Tamia-Token') && isLoggedIn) {
    createToast({
      type: toastTypes.WARNING,
      message: 'Sessão expirada, por favor faça o login novamente',
    });
    const authService = new AuthService();
    authService.logout();
    signOut();
  }

  const getComponent = (location) => {
    if (isShared) return <Component />;

    if (isPrivate && isLoggedIn) {
      if (location.pathname == '/empresa') {
        history.push('/home');
      }
      return (
        <PageContent
          Component={Component}
          title={Description}
          location={location}
        />
      );
    }
    if (!isLoggedIn && userCompanies.length) {
      if (location.pathname !== '/empresa') history.push('/empresa');
      return <Component />;
    } else if (isPrivate !== isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: isLoggedIn ? '/home' : '/login',
            state: { from: location },
          }}
        />
      );
    }

    return <Component />;
  };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => getComponent(location)}
    />
  );
};

export default Route;
