import { createSlice } from '@reduxjs/toolkit';
import {
  createUser,
  deleteUser,
  fetchInvites,
  fetchUsers,
  updateUser,
} from './thunks';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    loading: false,
    errors: null,
    invites: [],
    isManagingUsers: false,
    userCreatedSuccess: false
  },
  reducers: {
    setManagingUsers(state, action) {
      state.isManagingUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //GET
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //POST
      .addCase(createUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.data.userCreatedSuccess = true;
        state.loading = false;
        state.errors = null;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //PUT
      .addCase(updateUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //DELETE
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //Fetch invites
      .addCase(fetchInvites.fulfilled, (state, action) => {
        state.invites = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchInvites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvites.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      });
  },
});

export const actions = usersSlice.actions;
export const { setManagingUsers } = usersSlice.actions;

export default usersSlice.reducer;
