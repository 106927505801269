import React, { useState, useEffect } from 'react';
import { DeleteButton, EditCategories } from './style';
import { Table } from 'antd';
import { FaTrashAlt } from 'react-icons/fa';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { ExportReactCSV } from 'components/ExportReactCSV';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { PathIndicator } from 'components/PathIndicator';
import { useReduxState } from 'common/hooks/use-redux-state';
import { ConfirmDelete } from 'components/ConfirmDelete';
import { Flex } from 'components/Flex';
import { ModalTypes } from 'common/enums/modals.enum';
import { useModal } from 'common/hooks/use-modal';
import { MdEdit } from 'react-icons/md';
import { EditButton } from 'components/Modals/Lists/style';
import { AiOutlinePlus } from 'react-icons/ai';
import { ButtonGroup, StyledTable } from '../style';
import { getSelectedCategory } from 'common/redux/permissions/selectors';
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from 'common/redux/permissions/thunks';
import ThemedButton from 'components/Themed/ThemedButton';
import { maskText } from 'common/utilities/maskText';
import { TiEdit } from 'react-icons/ti';

export default function CategoriesTable() {
  const [openModal] = useModal(ModalTypes.CATEGORIES);

  const Column = Table;
  const [formattedDocs, setFormattedDocs] = useState([]);
  const [variations, setVariations] = useState([]);
  const addCategory = useReduxAction(createCategory);
  const editCategory = useReduxAction(updateCategory);
  const removeCategory = useReduxAction(deleteCategory);
  const [highestId, setHighestId] = useState(null);
  const selectedCategory = useReduxState(getSelectedCategory);

  const handleCreateCategory = (values) => {
    const payload = {
      category_type: selectedCategory._id,
      ...values,
    };
    addCategory(payload);
  };

  const handleEditCategory = (values) => {
    const payload = {
      category_type: selectedCategory._id,
      ...values,
    };

    editCategory(payload);
  };

  const text = (
    <span>
      Esse é o {selectedCategory.order}° nível na hierarquia do responsável pelo
      documento, para que as opções sejam apresentadas parametrizadas ao
      adicionar um novo documento.
    </span>
  );

  const handleDelete = async (id) => {
    await removeCategory({ id });
  };
  useEffect(() => {
    let highestId = 0;
    let categoryAlias = [];

    const formatted = selectedCategory?.data?.map((category) => {
      categoryAlias = categoryAlias.concat(category.alias_name);
      if (category.code > highestId) {
        highestId = category.code;
      }
      return {
        Código: category?.code,
        [category.category_name]: category?.name,
        Variação: category?.alias_name,
        'CPF / CNPJ': category?.document,
      };
    });

    setVariations(categoryAlias);
    setFormattedDocs(formatted || []);
    setHighestId(highestId + 1);
  }, [selectedCategory.data]);

  return (
    <>
      <PathIndicator
        path={[
          <div data-tour='listas'>Categoria</div>,
          selectedCategory.name,
        ]}
        helperText={text}
      />

      <ButtonGroup style={{ marginTop: 30, marginBottom: 30 }}>
        <EditCategories
          onClick={() =>
            openModal({
              name: ModalTypes.EDIT_CATEGORY,
              modalProps: {
                category: selectedCategory,
              },
            })
          }
        >
          <span>
            <TiEdit style={{ marginBottom: -2 }} /> Editar classe
          </span>
        </EditCategories>
        <ThemedButton
          data-tour='categorias'
          color='#d0fa94'
          style={{ boxShadow: '1px 1px #ccc', marginRight: '2rem' }}
          onClick={() =>
            openModal({
              modalProps: {
                title: `Adicionar ${selectedCategory.name}`,
                onCreate: handleCreateCategory,
                isOwner: true,
                suggestedId: highestId,
              },
            })
          }
        >
          <AiOutlinePlus style={{ marginRight: '4px' }} size={20} />
          Adicionar
        </ThemedButton>
      </ButtonGroup>
      <div style={{ padding: '10px' }}>
        <StyledTable
          size='small'
          scroll={{ x: '100%' }}
          dataSource={selectedCategory.data}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} ${selectedCategory.name} cadastrados`,
            total: selectedCategory.data?.length,
          }}
        >
          <Column
            title={
              <ThemedTooltip
                placement='top'
                showInformationIcon
                title='Número sequencial gerado pelo sistema para cada 
              cadastro e que pode ser alterado pelo usuário para personalizar 
              seus códigos de categoria.'
              >
                Código
              </ThemedTooltip>
            }
            dataIndex='code'
            key='id'
            width={60}
          />

          <Column title='Nome' width={200} dataIndex='name' key='name' />
          <Column
            title={
              <ThemedTooltip
                showInformationIcon
                placement='top'
                title='Qualquer variação do nome, abreviação ou apelido que possa ser utilizado para identificar o proprietário e facilitar o cadastro e a pesquisa'
              >
                Variação
              </ThemedTooltip>
            }
            width={200}
            dataIndex='alias_name'
            key='aliasName'
            render={(_, record) => record.alias_name?.join('; ')}
          />
          {selectedCategory.enableDocument !== false && (
            <Column
              title={
                <ThemedTooltip
                  showInformationIcon
                  placement='top'
                  title='Número do CPF ou CNPJ do proprietário cadastrado'
                >
                  CPF / CNPJ
                </ThemedTooltip>
              }
              width={120}
              dataIndex='document'
              key='document'
              render={(text) => maskText(text, '*')}
            />
          )}

          <Column
            width={40}
            title={
              <Flex justify='center'>
                Ações
                <ThemedTooltip
                  placement='topRight'
                  title='Exportar a lista para uma planilha'
                >
                  <ExportReactCSV
                    csvData={formattedDocs}
                    fileName='relatorio_proprietarios_tamia.csv'
                  />
                  {''}
                </ThemedTooltip>
              </Flex>
            }
            key='action'
            render={(_, record) => (
              <ButtonGroup
                key={record._id}
                style={{
                  justifyContent: 'center',
                  padding: '2px',
                  margin: '2px',
                }}
              >
                <EditButton
                  onClick={() =>
                    openModal({
                      modalProps: {
                        title: `Editar`,
                        currentRow: record,
                        onEdit: handleEditCategory,
                        isOwner: true,
                        variations,
                      },
                    })
                  }
                  variant='contained'
                >
                  <ThemedTooltip placement='top' title='Atualizar dados'>
                    <MdEdit size={20} />
                  </ThemedTooltip>
                </EditButton>

                <ConfirmDelete
                  placement='topLeft'
                  title={`Deseja excluir a classe "${record.name}"?`}
                  onConfirm={() => handleDelete(record._id)}
                >
                  <DeleteButton variant='contained'>
                    <ThemedTooltip placement='bottom' title='Excluir'>
                      <FaTrashAlt size={15} />
                    </ThemedTooltip>
                  </DeleteButton>
                </ConfirmDelete>
              </ButtonGroup>
            )}
          />
        </StyledTable>
      </div>
    </>
  );
}
