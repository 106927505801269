import React from 'react';
import { Input } from 'antd';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { useReduxState } from 'common/hooks/use-redux-state';
import { fetchSearch } from 'common/redux/documents/thunks';
import { setGlobalFilter, clearFilter } from 'common/redux/filters/reducers';
import styled from 'styled-components';
import {
  getFiltersAmount,
  getSearchGlobalFilter,
} from 'common/redux/filters/selectors';
import { CleanFilters, FilterAmountContainer } from '../pages/home/style';
const { Search } = Input;

const StyledSearch = styled(Search)`
  .anticon.ant-input-clear-icon,
  .ant-input-clear-icon {
    color: #f20000 !important;
  }
`;

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 55vw;
  max-width: 70vw;
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 20px 0 0 20px;
    border: 1px solid #9cdb37;
    border-right: none;
    height: 40px;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    color: #9cdb37;
    border: 1px solid #9cdb37;
    border-left: none;
    border-radius: 0 20px 20px 0;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #d9d9d9 transparent #d9d9d9 #d9d9d9;
  }
  .ant-input-affix-wrapper .ant-input-affix-wrapper-lg {
    border-color: #d9d9d9 transparent #d9d9d9 #d9d9d9;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #d9d9d9 transparent #d9d9d9 #d9d9d9;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    box-shadow: none;
    border-color: #d9d9d9 #d9d9d9 #d9d9d9 transparent;
  }
`;

export default function Searchbar({ updateOnChange, hideFilterIndicator }) {
  const searchDocuments = useReduxAction(fetchSearch);
  const setGlobalFilterValue = useReduxAction(setGlobalFilter);

  const advancedParamsAmount = useReduxState(
    getFiltersAmount('advancedFilters')
  );
  const globalFilter = useReduxState(getSearchGlobalFilter);
  const clearFilters = useReduxAction(clearFilter);
  const filterText =
    advancedParamsAmount > 1 ? 'filtros selecionados.' : 'filtro selecionado.';

  const onSearch = (globalSearch = '') => {
    setGlobalFilterValue({ filter: globalSearch });
    searchDocuments();
  };

  const onClearFilters = () => {
    clearFilters({ key: 'advancedFilters' });
    onSearch();
  };

  return (
    <StyledInput>
      <StyledSearch
        id='searchbar'
        size='large'
        placeholder='Pesquisa rápida (descrição ou classe do documento )'
        onSearch={onSearch}
        allowClear
        style={{ height: 40 }}
        defaultValue={globalFilter}
        onChange={(e) =>
          updateOnChange && setGlobalFilterValue({ filter: e.target.value })
        }
      />
      {!!advancedParamsAmount && !hideFilterIndicator && (
        <FilterAmountContainer>
          {advancedParamsAmount} {filterText}{' '}
          <CleanFilters onClick={onClearFilters}>Limpar filtro X</CleanFilters>
        </FilterAmountContainer>
      )}
    </StyledInput>
  );
}
