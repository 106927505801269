import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { bytesToMb } from 'common/utilities/bytesToMb';
import { ConfirmDelete } from 'components/ConfirmDelete';
import {
  Container,
  ButtonClear,
  Content,
  ButtonContainer,
  ContentTable,
  ContainerTable,
  SubHeader,
  SpanAttachment,
} from './styles';
import { BsCloudUpload } from 'react-icons/bs';
import { deleteDriveFileRequest } from 'common/redux/cloud/thunks';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { createToast, toastTypes } from 'common/utilities/createToast';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

const DropzoneErros = {
  TOO_LARGE: 'file-too-large',
};

export function Dropzone({ onChange, value, documentId }) {
  const deleteDriveFile = useReduxAction(deleteDriveFileRequest);
  const [document, setDocument] = useState(null);
  const location = useLocation();
  const isEditing = !!document;

  useEffect(() => {
    setDocument(location.state?.document);
  }, [location]);

  const onDropRejected = useCallback((rejectedFiles) => {
    let message = 'Arquivo não permitido';
    rejectedFiles.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === DropzoneErros.TOO_LARGE) {
          message = 'Arquivo acima do limite permitido';
        }
      });
    });
    createToast({ type: toastTypes.ERROR, message });
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ maxFiles: 1, maxSize: 209715200, onDropRejected });
  const [renderFiles, setRenderFiles] = useState([]);

  const clear = () => {
    onChange([]);
    setRenderFiles([]);
    if (documentId) {
      deleteDriveFile(documentId);
    }
  };

  const generateOptions = (files) => {
    const text = [
      'Tem certeza que deseja eliminar',
      ' esse documento eletronicamente?',
      'essa ação irá preencher o campo',
      ' "Data de Eliminação"',
    ];
    return (
      <>
        <ContainerTable>
          <ContentTable>
            <SubHeader>
              <AiOutlinePaperClip
                color='green'
                size={25}
                style={{ marginRight: '2px' }}
              />
              Dados do anexo:
            </SubHeader>
            {files.map((file) => (
              <table id='first-table'>
                <SpanAttachment>Nome:</SpanAttachment>
                <tr>
                  <td id='td-right'>{file?.path}</td>
                </tr>
                <SpanAttachment>Tipo:</SpanAttachment>
                <tr>
                  <td id='td-right'>
                    {file?.type.split('/')[1] || 'Tipo desconhecido'}
                  </td>
                </tr>
                <SpanAttachment>Tamanho:</SpanAttachment>
                <tr>
                  <td id='td-right'>{bytesToMb(file?.size, true)}</td>
                </tr>
              </table>
            ))}
            <ButtonContainer>
              {isEditing ? (
                <ConfirmDelete
                  onConfirm={clear}
                  placement='bottomLeft'
                  title={
                    <div>
                      {text.map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  }
                >
                  <ButtonClear>Eliminar</ButtonClear>
                </ConfirmDelete>
              ) : (
                <ButtonClear onClick={() => clear()}>Remover</ButtonClear>
              )}
            </ButtonContainer>
          </ContentTable>
        </ContainerTable>
      </>
    );
  };

  useEffect(() => {
    if (value?.length) {
      const fileList = generateOptions(value);
      setRenderFiles(fileList);
    }
  }, [value]);

  useEffect(() => {
    if (!acceptedFiles?.length) return;
    const files = generateOptions(acceptedFiles);
    setRenderFiles(files);
    onChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <>
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <h2>
          {isEditing
            ? ' Para substituir o anexo, arraste e solte seu arquivo aqui'
            : 'Arraste e solte seu arquivo aqui'}
        </h2>
        <BsCloudUpload size={100} />
        <div>
          <input {...getInputProps()} />
          <p>
            ou{' '}
            <strong style={{ color: '#3970fa' }}>selecione um arquivo</strong>{' '}
            de seu computador.
            <br />
            <br />
            <p style={{ fontSize: '10px' }}>
              * Tamanho máximo do arquivo: 200MB
            </p>
          </p>
        </div>
      </Container>
      <Content>{renderFiles}</Content>
    </>
  );
}
