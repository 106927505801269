// thunks/paymentThunks.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentTypes } from './types';
import { PaymentsService } from 'core/services/payment.service';

export const fetchPlans = createAsyncThunk(
  PaymentTypes.FETCH_PLANS,
  async (_, thunkApi) => {
    try {
      const paymentService = new PaymentsService();
      const response = await paymentService.getPlans();
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
