import { BaseApi } from 'core/api';

export class AuthApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`auth`);
  }
  async login(requestOptions) {
    this.setUrl(`${this.url}/login`);
    const payload = {
      username: requestOptions.user.toLowerCase(),
      password: requestOptions.password,
    };
    return this.post(payload);
  }

  async loginCompany(companyId) {
    this.setUrl(`${this.url}/login/${companyId}`);
    return this.get();
  }

  async sendResetEmail(email) {
    this.setUrl(`${this.url}/recovery/email`);

    const payload = {
      email,
    };
    return this.post(payload);
  }

  async resetPassword(newPassword, code) {
    this.setUrl(`${this.url}/recovery/update`);
    const payload = {
      password: newPassword,
      code,
    };
    return this.post(payload);
  }

  async logout() {
    this.setUrl('auth/logout');
    return this.get();
  }
}
