import { Flex } from 'components/Flex';
import styled, { css } from 'styled-components';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useState } from 'react';
import { Button, Radio, Switch, Dropdown, Menu } from 'antd';
import { DropdownMenu } from './DropdownMenu';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { authenticateCloud, disconnectCloud } from 'common/redux/cloud/thunks';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getCloudStatus } from 'common/redux/cloud/selectors';
import { IoIosArrowDown } from 'react-icons/io';
import { Clouds } from 'common/enums/cloud.enum';
import { getUserRole } from 'common/redux/auth/selectors';
import { Roles } from 'common/enums/roles.enum';

const CardContainer = styled(Flex)`
  flex-direction: column;
  width: 45rem;
  max-width: 90vw;
  height: ${({ isSelected }) => (isSelected ? 180 : 80)}px;
  margin: 15px 0;
  border: 1px solid #9cdb37;
  border-left: ${({ isSelected, connected }) =>
    `4px solid ${isSelected ? (connected ? 'green' : 'red') : 'none'}`};
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%);
  background-color: #fff;
  transition: all 0.3s;
  align-self: center;

  :hover {
    cursor: pointer;
  }
`;

const CardBody = styled(Flex)`
  height: 100px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 5px 0 20px;
  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const RepositoryLogo = styled(Flex)`
  align-items: center;
  margin: 10px 0 10px 10px;
  font-size: 1.1rem;
`;

const RepositoryStatus = styled.span`
  font-size: 1rem;
  white-space: nowrap;
  margin: 10px;
  color: ${({ connected, inDevelopment }) =>
    inDevelopment ? 'orange' : connected ? 'green' : 'red'};
`;

const OptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 35px;
  border-radius: 50%;

  border: none;
  outline: none;
  background-color: inherit;

  :hover {
    background-color: #dedede;
    cursor: pointer;
    transition: 0.4s;
  }
`;

const ConnectButton = styled(Button)`
  color: #21211a;
  background-color: #d0fa94;
  border-radius: 30px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  border-color: #d0fa94;
  :hover {
    background-color: #9cdb37;
    border-color: #d0fa94;
    color: #21211a;
  }
`;

const ArrowIcon = styled(IoIosArrowDown)`
  transform: ${({ selected }) => selected && 'rotate(-180deg)'};
  transition: transform 0.3s;
  color: #9cdb37;
`;

const RepositoryTitle = ({
  title,
  icon,
  connected,
  style,
  inDevelopment,
  selected,
}) => (
  <Flex style={style} align='center'>
    <RepositoryLogo>
      {icon} <span style={{ marginLeft: 10, marginRight: 10 }}>{title}</span> -
      <RepositoryStatus connected={connected} inDevelopment={inDevelopment}>
        {inDevelopment
          ? 'Em construção'
          : connected
          ? 'Conectado'
          : 'Não conectado'}
      </RepositoryStatus>
    </RepositoryLogo>
    <OptionsButton>
      <ArrowIcon selected={selected} />
    </OptionsButton>
  </Flex>
);

const RepositoryInfo = ({ cardInfo }) => {
  const isConnected = !!cardInfo;

  return (
    <span
      style={{ opacity: isConnected ? 1 : '.7', fontSize: '.9rem' }}
      onClick={(e) => e.stopPropagation()}
    >
      {isConnected ? (
        <>
          <span>{cardInfo.email}</span>
          <p>
            {cardInfo.parsedStorageUsage}GB/
            {cardInfo.parsedStorageLimit}GB{' '}
          </p>
        </>
      ) : (
        'Informações indisponíveis'
      )}
    </span>
  );
};

export const RepositoryCard = ({
  type,
  icon,
  title,
  inDevelopment,
  hideDisconnectButton,
}) => {
  const [selected, setSelected] = useState(false);
  const status = useReduxState(getCloudStatus);
  const authenticate = useReduxAction(authenticateCloud);
  const disconnect = useReduxAction(disconnectCloud);
  const cardInfo = status?.[type];
  const isConnected = !!cardInfo || type === Clouds.TAMIA;
  const userRole = useReduxState(getUserRole);

  const handleConnect = (event) => {
    event.stopPropagation();
    authenticate(type);
  };

  const handleDisconnect = (event) => {
    event.stopPropagation();
    disconnect(type);
  };
  return (
    <CardContainer
      isSelected={selected}
      onClick={() => setSelected(!selected)}
      connected={isConnected}
    >
      <RepositoryTitle
        style={{
          marginTop: 10,
          paddingRight: 20,
        }}
        icon={icon}
        title={title}
        connected={isConnected}
        inDevelopment={inDevelopment}
        selected={selected}
      />
      {selected && (
        <CardBody>
          <Flex align='center' justify='space-between'>
            <RepositoryInfo cardInfo={cardInfo} />
            <Flex
              justify='flex-end'
              style={{ width: 'fit-content', paddingRight: 10 }}
            >
              {userRole <= Roles.ADMIN && !hideDisconnectButton && (
                <ConnectButton
                  disabled={inDevelopment}
                  onClick={isConnected ? handleDisconnect : handleConnect}
                  type='primary'
                  size='large'
                >
                  {isConnected ? 'Desconectar' : 'Conectar'}
                </ConnectButton>
              )}
              {/* <Dropdown
                disabled={inDevelopment}
                overlay={DropdownMenu}
                trigger="click"
                onClick={(e) => e.stopPropagation()}
              >
                <OptionsButton>
                  <BiDotsVerticalRounded size={25} />
                </OptionsButton>
              </Dropdown> */}
            </Flex>
          </Flex>
        </CardBody>
      )}
    </CardContainer>
  );
};
