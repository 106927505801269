import styled from "styled-components";
import { IoMailSharp } from "react-icons/io5";

export const ButtonGroup = styled.div`
  margin-right: 25%;
`;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212112;
  background-color: #9cdb37;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  width: 1.7rem;
  height: 1.7rem;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;

export const DeleteButton = styled.button`
  background-color: #ff2200;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  border: 0;
  &&:hover {
    background-color: #d00000;
    transition: 0.3s;
  }
`;

export const DetailButton = styled.button`
  background-color: #00c0ef;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  border: 0;
  opacity: 0.6;
  cursor: not-allowed;
`;

export const ResendButton = styled(IoMailSharp)`
  :hover {
    color: #00c0ef;
    transition: 0.3s;
  }
`;
