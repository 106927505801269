import { createSelector } from '@reduxjs/toolkit';
import { Roles } from 'common/enums/roles.enum';

const s = (state) => state.auth;

export const getCurrentUser = (state) => s(state).currentUser || {};

export const getCurrentUserToken = createSelector(
  getCurrentUser,
  (user) => user?.token || null
);

export const getUserCompanies = (state) => s(state).userCompanies || [];

export const getCurrentCompanyId = createSelector(
  getCurrentUser,
  (user) => user?.selectedCompanyId || null
);

export const getCurrentCompany = createSelector(
  getUserCompanies,
  getCurrentCompanyId,
  (companies, companyId) =>
    companies?.find((c) => c.companyId == companyId) || {}
);

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (user) => user?._id || null
);

export const getUserEmail = createSelector(
  getCurrentUser,
  (user) => user?.email || null
);

export const getUserContactInfo = createSelector(
  getCurrentUser,
  (user) => user?.contactInfo || null
);

export const getUserPhoneNumber = createSelector(
  getUserContactInfo,
  (user) => user?.phone || null
);

export const getUserCellphoneNumber = createSelector(
  getUserContactInfo,
  (user) => user?.cellphone || null
);

export const getUserRole = createSelector(
  getCurrentCompany,
  (company) => company?.roleId.toString() || Roles.USER
);

export const getUserGroupId = createSelector(
  getCurrentCompany,
  (company) => company.groupId
);
export const getUserIsOwner = createSelector(
  getUserRole,
  (roleId) => roleId == Roles.OWNER || false
);

export const getUserIsOperator = createSelector(
  getUserRole,
  (roleId) => roleId == Roles.USER || false
);

export const getUserIsAdmin = createSelector(
  getUserRole,
  (roleId) => [Roles.ADMIN, Roles.OWNER].includes(roleId) || false
);

export const getRoleStatus = createSelector(
  getUserIsOwner,
  getUserIsAdmin,
  getUserIsOperator,
  (isOwner, isAdmin, isOperator) => ({
    isOwner,
    isAdmin: isAdmin || isOwner,
    isOperator,
  })
);

export const getShowTutorial = createSelector(
  getCurrentUser,
  (user) => user?.showTutorial || false
);

export const isAuthLoading = (state) => s(state).loading || false;
