import { useReduxAction } from "common/hooks/use-redux-action";
import { setModalState } from "common/redux/modals/reducers";

export const useModal = (defaultName) => {
  const setCurrentModal = useReduxAction(setModalState);

  const open = (props = { name: null, modalProps: {} }) => {
    const { name, modalProps } = props;
    setCurrentModal({ name: name || defaultName, modalProps });
  };

  const close = () => {
    setCurrentModal({ name: null });
  };

  return [open, close];
};
