import React from 'react';
import Header from '../Header';
import { Sidebar } from './sidebar';
import { Flex } from 'components/Flex';
import { useWindowSize } from 'common/hooks/use-window-size.js';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getSidebarState } from 'common/redux/layout/selectors';
import { Drawer } from 'antd';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { toggleSidebar } from 'common/redux/layout/reducers';

export const PageContent = ({ Component, location }) => {
  const { isSmallScreen } = useWindowSize();
  const sidebarSize = isSmallScreen ? '20px' : '260px';
  const isSidebarOpen = useReduxState(getSidebarState);
  const toggleSidebarState = useReduxAction(toggleSidebar);

  return (
    <Flex direction='column' style={{ maxWidth: '100vw' }}>
      <Header />
      <Flex>
        <Drawer
          open={isSidebarOpen}
          width='100vw'
          placement='left'
          title='Menu'
          bodyStyle={{ padding: 0 }}
          onClose={() => toggleSidebarState()}
        >
          <Sidebar location={location} isSmallScreen={isSmallScreen} />
        </Drawer>
        <Sidebar
          location={location}
          isSmallScreen={isSmallScreen}
          style={{ display: isSmallScreen ? 'none' : 'block' }}
        />

        <main
          style={{
            maxWidth: `calc(100vw - ${sidebarSize})`,
            padding: '20px 5px',
            flexGrow: 1,
            margin: '0 auto',
            backgroundColor: '#f6faf5',
          }}
        >
          {Component && <Component />}
        </main>
      </Flex>
    </Flex>
  );
};
