export const Roles = {
  OWNER: '1',
  ADMIN: '2',
  MANAGER: '3',
  USER: '4',
};

export const RolesText = {
  1: 'Proprietário',
  2: 'Administrador',
  3: 'Operacional',
  4: 'Consultivo',
};
