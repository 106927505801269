import React, { useEffect, useState } from 'react';
import { Header, Text, TextHeader } from './styles';
import { Collapse } from 'antd';
import Searchbar from 'components/Searchbar';
const { Panel } = Collapse;
import { RiQuestionnaireLine } from 'react-icons/ri';
// import { VideoModal } from 'components/Modals/Gifs';
//import cloudVideo from 'assets/videos/video.mp4';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getSearchGlobalFilter } from 'common/redux/filters/selectors';
import { Flex } from 'components/Flex';
import FaqEye from './assets/faq-eye.png';

const faq = [
  {
    title: 'Alterar senha do sistema',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>1° Passo: Sair do sistema; </li>
        <li>2° Passo: Clicar em "Esqueci minha senha";</li>
        <li>3° Passo: Informar o email de acesso ao Tâmia;</li>
        <li>
          4° Passo: O Tâmia enviará um email informando um link de acesso,
          clicar no link;
        </li>
        <li>5° Passo: Digitar a nova senha de acesso ao sistema</li>
      </ol>
    ),
  },
  {
    title: 'Adicionar um novo usuário no sistema.',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: No menu lateral, acessar "Configurações" {'>'} " Usuários"
        </li>
        <li>2° Passo: Clicar no botão "Adicionar";</li>
        <li>
          3° Passo: Preencher o formulário de convite e depois clicar no botão
          "Convidar";
        </li>
        <li>
          Nota: Atenção ao preenchimento de perfil e Permissão das Listas
          Indexadoras;
        </li>
      </ol>
    ),
  },
  {
    title: 'Excluir um usuário do sistema.',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: No menFaqEye 2° Passo: Na linha do usuário que deseja
          excluir, na coluna de "Ações", selecionar a opção que tem um ícone de
          lixeira;
        </li>
        <li>
          Nota: Essa ação não pode ser revertida, então deve-se ter muita
          atenção ao remover um usuário;
        </li>
      </ol>
    ),
  },
  {
    title: 'Adicionar um item a categoria.',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: No menu lateral, acessar "Categoria" {'>'} depois
          selecionar a Lista desejada;
        </li>
        <li>2° Passo: Clicar no botão "Adicionar";</li>
        <li>
          3° Passo: Preencher o formulário com os dados do item que será
          adicionado a lista selecionada e depois clicar no botão "Salvar";
        </li>
      </ol>
    ),
  },
  {
    title: 'Alterar meus dados no sistema?',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          Basta acessar no menu lateral a opção "Configurações" {'>'} " Dados da
          conta" ou clicar no email que encontra-se no cabeçalho do sistema e
          selecionar a opção "Dados da conta";
        </li>
        <li>Depois só realizar a alteração necessária;</li>
        <li>
          Nota: Essa ação só poderá ser efetuado pelo usuário
          proprietário/Administrador da conta.
        </li>
      </ol>
    ),
  },
  {
    title: 'Entrar em contato com o suporte',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: No menu lateral, acessar "Configurações" {'>'} "
          Armazenamento";
        </li>
        <li>2° Passo: Selecionar o drive desejado;</li>
        <li>
          3° Passo: Clicar no card referente ao Drive que será conectado e em
          seguida clicar no botão "Conectar";
        </li>
        <li>
          Nota: Atenção a essa ação pois a conexão com drive externo não depende
          dos serviços de armazenamento do Tâmia;
        </li>
      </ol>
    ),
  },
  {
    title: 'Utilizar um backup extra em nuvem própria',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          Basta acessar clicar em "Ajuda" que encontra-se no cabeçalho do
          sistema e selecionar a opção "Mensagem ao suporte";
        </li>
        <li>A mensagem enviada irá direto para o email do suporte do Tâmia;</li>
        <li>
          Nota: Quando enviar e-mail ao suporte, favor verificar o email em que
          foi acessado o sistema pois será utilizado como e-mail resposta por
          nossa equipe de suporte;
        </li>
      </ol>
    ),
  },
  {
    title: 'Baixar um arquivo para meu computador.',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: Na tela inicial, clicar na opção "
          <img style={{ width: '20px' }} src={FaqEye} alt='' />" na coluna de
          "Ações" na linha referente ao documento desejado;
        </li>
        <li>
          2° Passo: Quando abrir o menu lateral, clicar no botão "Download" para
          baixar o arquivo selecionar;
        </li>
        <li>
          Nota: Utilizamos o maior data center do mundo, mesmo assim , o tempo
          de download dependerá do tamanho do arquivo anexado;
        </li>
      </ol>
    ),
  },
  {
    title: 'Visualizar um arquivo anexado',
    description: (
      <ol style={{ listStyleType: 'none' }}>
        <li>
          1° Passo: Na tela inicial, clicar na opção "
          <img style={{ width: '20px' }} src={FaqEye} alt='' />" na coluna de
          "Ações" na linha referente ao documento desejado;
        </li>
        <li>
          2° Passo: Quando abrir o menu lateral, clicar no botão "Visualizar" para
          ver o arquivo direto em seu navegador;
        </li>
        <li>
          Nota: Utilizamos o maior data center do mundo, mesmo assim , o tempo
          de visualização do arquivo no navegador dependerá do tamanho do arquivo anexado;
        </li>
      </ol>
    ),
  },
];

const SupportCenter = () => {
  const search = useReduxState(getSearchGlobalFilter);
  const [questions, setQuestions] = useState(faq);

  useEffect(() => {
    if (search) {
      const filtered = faq.filter((question) => {
        const text = question.title + question.description;
        const regex = new RegExp(search.split(' ').join('|'), 'gi');

        return text.match(regex);
      });
      setQuestions(filtered);
    } else {
      setQuestions(faq);
    }
  }, [search]);

  const sortedQuestions = questions.sort((a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });

  return (
    <>
      <Header>
        <TextHeader>
          Perguntas Frequentes{' '}
          <RiQuestionnaireLine
            style={{ marginLeft: '1rem', marginBottom: '-0.2rem' }}
          />
        </TextHeader>
      </Header>
      <Flex
        data-tour='FAQ'
        align='center'
        justify='center'
        style={{ marginBottom: '2rem' }}
      >
        <Searchbar updateOnChange hideFilterIndicator />
      </Flex>
      <Collapse accordion>
        {sortedQuestions.map((question, idx) => (
          <Panel header={question.title} key={idx}>
            <Text>{question.description} </Text>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};
export default SupportCenter;
