import { Button } from 'antd';
import styled from 'styled-components';
import { Colors } from 'common/theme/colors';
import { css } from 'styled-components';

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AntdButton = styled(Button)`
  height: ${({ height }) => height || '40px'};
  width: ${({ width }) => width};
  display: flex;
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: ${({ align }) => align || 'center'};
  border-radius: ${({ radius }) => radius || '30px'};
  font-weight: ${({ weight }) => weight || 500};
  font-size: ${({ size }) => size};
  ${({ color }) =>
    color &&
    css`
      color: ${Colors.TAMIA_BLACK};
      background-color: ${({ color }) => color || Colors.PRIMARY_COLOR};
      border-color: ${({ color }) => color || Colors.PRIMARY_COLOR};

      :hover,
      :focus {
        color: ${Colors.TAMIA_BLACK};
        background-color: ${({ color }) => color || Colors.PRIMARY_COLOR};
        opacity: 0.8;
        border-color: ${({ color }) => color || Colors.PRIMARY_COLOR};
      }
    `};
`;

const ThemedButton = ({ children, target, href, ...props }) => (
  <AntdButton {...props}>
    {href ? (
      <Link target={target} href={href}>
        {children}
      </Link>
    ) : (
      children
    )}
  </AntdButton>
);

export default ThemedButton;
