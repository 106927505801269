import React from 'react';
import { ContentTable } from './styles';
import { FiZoomIn } from 'react-icons/fi';
import { ThemedPopover } from 'components/Themed/ThemedPopover';
import { bytesToMb } from 'common/utilities/bytesToMb';
import { toLocalDate, toLocalDateTime } from 'common/utilities/toLocaleDate';

export default function BasicTextFields({ document }) {
  const categoryItems = document.categories.map((category) => (
    <tr key={category.category_name}>
      <td class='td-left'>{category.category_name}</td>
      <td class='td-right'>{category.name}</td>
    </tr>
  ));

  return (
    <>
      <ContentTable>
        <table>
          <h3 style={{ fontWeight: 700, margin: '10px 0', paddingLeft: '7px' }}>
            Classes:
          </h3>
          {categoryItems}
          <h3 style={{ fontWeight: 700, margin: '10px 0', paddingLeft: '7px' }}>
            Informações complementares:
          </h3>
          <tr>
            <td class='td-left'>Número interno</td>
            <td class='td-right'>{document?.internal_id || '-'}</td>
          </tr>
          <tr>
            <td class='td-left'>Descrição</td>
            <td class='td-right'>{document.description} </td>

            <ThemedPopover
              title={['Descrição']}
              content={<strong>{document.description}</strong>}
            >
              <FiZoomIn
                style={{
                  color: '#9cdb37',
                  marginBottom: '-8px',
                  cursor: 'pointer',
                  height: '20px',
                  width: '20px',
                }}
              />
            </ThemedPopover>
          </tr>
          <tr>
            <td class='td-left'>Período do documento</td>
            <td class='td-right'>
              {toLocalDate(document.period_start) || '-'} -{' '}
              {toLocalDate(document.period_end) || '-'}
            </td>
          </tr>
          <tr>
            <td class='td-left'>Armazenado fisicamente </td>
            <td class='td-right'>{!!document.location ? 'Sim' : 'Não'}</td>
          </tr>
          <tr>
            <td class='td-left'>Localização física</td>
            <td class='td-right'>{document.location || '-'}</td>
          </tr>
          <h3 style={{ fontWeight: 700, margin: '10px 0', paddingLeft: '7px' }}>
            Previsão de descarte:
          </h3>
          <tr>
            <td class='td-left'>Documento físico</td>
            <td class='td-right'>
              {toLocalDate(document?.physical_discard_date) || '-'}
            </td>
          </tr>
          <tr>
            <td class='td-left'>Arquivo eletrônico</td>
            <td class='td-right'>
              {toLocalDate(document?.digital_discard_date) || '-'}
            </td>
          </tr>
          <h3 style={{ fontWeight: 700, margin: '10px 0', paddingLeft: '7px' }}>
            Data da eliminação:
          </h3>
          <tr>
            <td class='td-left'>Documento físico</td>
            <td class='td-right'>
              {toLocalDate(document?.physical_discarded_at) || '-'}
            </td>
          </tr>
          <tr>
            <td class='td-left'>Documento eletrônico</td>
            <td class='td-right'>
              {toLocalDate(document?.digital_discarded_at) || '-'}
            </td>
          </tr>
          <h3 style={{ fontWeight: 700, margin: '10px 0', paddingLeft: '7px' }}>
            Auditoria:
          </h3>
          <tr>
            <td class='td-left'>Criado por</td>
            <td
              class='td-right'
              title={`Criado por ${
                document.audit?.created_by?.email
              } em ${toLocalDateTime(
                document.created_at || document.audit?.created_at
              )}`}
            >
              {document.audit?.created_by?.email || '-'}
            </td>
          </tr>
          <tr>
            <td class='td-left'>Data de Cadastro</td>
            <td class='td-right'>
              {toLocalDateTime(
                document.created_at || document.audit?.created_at
              )}
            </td>
          </tr>
          <tr>
            <td class='td-left'>Última edição por</td>
            <td class='td-right'>{document.audit?.updated_by?.email || '-'}</td>
          </tr>
          <tr>
            <td class='td-left'>Data e hora da última edição</td>
            <td class='td-right'>
              {toLocalDateTime(
                document.updated_at || document.audit?.updated_at
              )}
            </td>
          </tr>
          <>
            <tr>
              <td class='td-left'>Tamanho do Arquivo</td>
              <td class='td-right'>
                {bytesToMb(document.files?.[0]?.size, true) || '-'}
              </td>
            </tr>
            <tr>
              <td class='td-left'>Tipo do arquivo</td>
              <td class='td-right'>
                {document.files?.[0]?.type.split('/')[1] || '-'}
              </td>
            </tr>
          </>
        </table>
      </ContentTable>
    </>
  );
}
