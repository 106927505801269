import { Input } from 'antd';
import styled from 'styled-components';

export const ThemedTextArea = styled(Input.TextArea)`
  font-style: normal;
  resize: none;
  border-color: #9cdb37 !important;
  border-radius: 20px !important;

  ::placeholder {
    color: #a2a2a2;
  }
`;
