import { BaseApi } from 'core/api';

export class SearchApi extends BaseApi {
  constructor(system, userId) {
    super(system, userId);
    this.system = system;
    this.userId = userId;
    this.setUrl(`documents/search`);
  }
  async searchDocuments(globalSearch, advancedSearchParams) {
    advancedSearchParams = {
      ...advancedSearchParams,
      query: globalSearch,
    };

    const params = [];

    Object.entries(advancedSearchParams).forEach(([key, value]) => {
      if (value) params.push(`${key}=${JSON.stringify(value)}`);
    });

    const advancedSearchQuery = params.join('&');

    this.setUrl(`${this.url}?${advancedSearchQuery}`);
    return await this.get();
  }
}
