import React, { useState } from 'react';
import { InputContainer } from '../Login/style';
import { AiOutlineUser, AiOutlineMail } from 'react-icons/ai';
import { Form } from 'antd';
import { ThemedRegisterInput } from 'components/Themed/ThemedRegisterInput';
import { Flex } from 'components/Flex';
import ThemedButton from 'components/Themed/ThemedButton';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { registerUserNewsletter } from 'common/redux/users/thunks';

export const NewsletterForm = () => {
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const registerNewsletter = useReduxAction(registerUserNewsletter);

  const handleSubmit = async () => {
    console.log('Dados do formulário:', form.getFieldsValue());

    const values = form.getFieldsValue();

    const payload = {
      system: null,
      ...values,
    };
    try {
      await registerNewsletter({ payload });
      setFormSubmitted(true);
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
    }
  };

  return (
    <>
      <Form form={form} className='custom-form' onFinish={handleSubmit}>
        <Flex
          direction='column'
          justify='center'
          align='center'
          style={{ maxWidth: 370 }}
        >
          <InputContainer>
            <AiOutlineUser size={20} style={{ color: 'green' }} />
            <Form.Item name='name' noStyle>
              <ThemedRegisterInput placeholder='nome' />
            </Form.Item>
          </InputContainer>
          <InputContainer>
            <AiOutlineMail size={20} style={{ color: 'green' }} />
            <Form.Item name='email' noStyle>
              <ThemedRegisterInput placeholder='seu@seuemail.com.br' />
            </Form.Item>
          </InputContainer>

          <Flex justify='center'>
            <ThemedButton
              style={{ margin: '10px 0 30px 0' }}
              width='100%'
              color='#9CDB37 !important'
              htmlType='submit'
              height='45px'
              size='18px'
              weight='600'
              disabled={formSubmitted}
            >
              {formSubmitted ? 'Solicitação enviada' : 'Inscrever'}
            </ThemedButton>
          </Flex>
        </Flex>
      </Form>
    </>
  );
};

export default NewsletterForm;
