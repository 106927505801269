import styled from 'styled-components';

export const Circle = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: ${({ bg }) => bg};
  box-shadow: inset -10px -10px 10px ${({ bg }) => bg}, inset 3px 3px 5px #fff;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 30px 10px 10px 5px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: left;
  margin: 5px;
`;
export const LegendText = styled.span`
  margin-left: 10px;
`;
