import { Roles, RolesText } from 'common/enums/roles.enum';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getUserRole } from 'common/redux/auth/selectors';
import { Select } from './Select';

const roleTypes = [
  {
    id: Roles.OWNER,
    name: RolesText[Roles.OWNER],
    disabled: true,
  },
  {
    id: Roles.ADMIN,
    name: RolesText[Roles.ADMIN],
  },
  {
    id: Roles.MANAGER,
    name: RolesText[Roles.MANAGER],
  },
  {
    id: Roles.USER,
    name: RolesText[Roles.USER],
  },
];

export const RoleSelect = ({ customEmptyMessage, ...rest }) => {
  const currentUserRole = useReduxState(getUserRole);
  const filteredRoles = roleTypes.filter((role) => role.id >= currentUserRole);
  return (
    <Select
      selectOptions={filteredRoles}
      customEmptyMessage={customEmptyMessage}
      {...rest}
    />
  );
};
