import { Select } from './Select';
import { FileTypes } from 'common/enums/fileTypes.enum';

export const FileTypeSelect = ({ ...rest }) => {
  const options = Object.values(FileTypes).map((type) => ({
    id: type,
    name: '.' + type,
  }));

  return (
    <Select
      mode='multiple'
      showSearch
      allowClear
      style={{
        width: '100%',
      }}
      placeholder='Selecione...'
      selectOptions={options}
      {...rest}
    />
  );
};
