import { Flex } from 'components/Flex';
import styled from 'styled-components';
import Img from '../Landing Page/assets/background_hero3.png';

export const PageContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-image: url(${Img});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  height: 100%;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  margin-top: 5rem;
  text-align: center;
  width: 30%;
  min-width: 600px;
  h1 {
    font-size: 30px;
    font-weight: 700;
    font-family: Gunar Thin;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
  }

  @media (max-width: 600px) {
    min-width: 350px;

    h1 {
      font-size: 20px;
      font-weight: 700;
    }
    h3 {
      font-weight: 600;
      font-size: 14px;
      margin: 10px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export const RegisterContainer = styled(Flex)`
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  width: 30%;
  min-width: 600px;
  min-height: 80%;
  background-color: #ddfcb1;
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 10%);
  padding: 20px 50px;

  @media (max-width: 600px) {
    min-width: 350px;
  }

  img {
    width: 12rem;
    height: 3rem;
  }
`;

export const FormContainer = styled(Flex)`
  margin-top: 40px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1370px) {
    margin-top: 20px;
  }

  & > * {
    width: 100%;
  }
`;
