import React, { useEffect, useState } from 'react';

import { Flex } from 'components/Flex';
import styled from 'styled-components';
import { toLocalDate, toLocalDateTime } from 'common/utilities/toLocaleDate';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getCategoriesById } from 'common/redux/permissions/selectors';
import { Filters } from 'components/Filters';
import { getFiltersAmount } from 'common/redux/filters/selectors';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { fetchAuditData } from 'common/redux/info/thunks';
import { getAuditData } from 'common/redux/info/selectors';
import { clearFilter } from 'common/redux/filters/reducers';
import { CleanFilters } from 'pages/home/style';

const typeMapper = {
  DOCUMENT_CREATE: 'criou o documento',
  DOCUMENT_UPDATE: 'atualizou o documento',
  DOCUMENT_DELETE: 'removeu o documento',
  INVITE_CREATE: 'convidou um usuário',
  INVITE_DELETE: 'removeu o convite',
  USER_CREATE: 'criou o usuário',
  USER_UPDATE: 'atualizou o usuário',
  USER_DELETE: 'removeu o usuário',
  GROUP_CREATE: 'criou o grupo',
  GROUP_UPDATE: 'atualizou o grupo',
  GROUP_DELETE: 'removeu o grupo',
};

const keyMapper = {
  description: 'Descrição',
  period_start: 'Data início do período do documento',
  period_end: 'Data fim do período do documento',
  categories: 'Categorias',
  physical_discard_date: 'Previsão de descarte do documento Físico',
  digital_discard_date: 'Previsão de descarte do documento eletrônico',
  location: 'Localização Física',
  physical_discarded_at: 'Data de eliminação do Documento Físico',
  digital_discarded_at: 'Data de eliminação do Documento eletrônico',
  internal_id: 'ID interno',
  email: 'Email',
  company: 'Nome da empresa',
  groupId: 'ID do grupo',
  roleId: 'ID da função',
};

const DOCUMENT_TYPES = [
  'DOCUMENT_CREATE',
  'DOCUMENT_UPDATE',
  'DOCUMENT_DELETE',
];
const INVITE_TYPES = ['INVITE_CREATE', 'INVITE_DELETE'];
const USER_TYPES = ['USER_CREATE', 'USER_UPDATE', 'USER_DELETE'];
const GROUP_TYPES = ['GROUP_CREATE', 'GROUP_UPDATE', 'GROUP_DELETE'];

const CardContainer = styled(Flex)`
  flex-direction: column;
  width: 45rem;
  max-width: 90vw;
  height: fit-content;
  margin: 8px 0;
  border: 1px solid #9cdb37;
  border-left: ${({ isSelected }) =>
    `4px solid ${isSelected ? 'green' : 'none'}`};
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%);
  background-color: #fff;
  transition: all 0.3s;
  align-self: center;
  padding: 8px;

  :hover {
    cursor: pointer;
  }
`;

const CardBody = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 5px 0 20px;
  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const FilterAmountContainer = styled.span`
  font-size: 13px;
  color: #4e4e4e;
`;

const AuditCard = ({ log, changes }) => {
  const createdAt = toLocalDateTime(new Date(log.createdAt));
  const createdBy = log.createdBy.email;
  const [selected, setSelected] = useState(false);
  const id = log.values.before?.id || log.values.before?._id;
  return (
    <CardContainer isSelected={selected} onClick={() => setSelected(!selected)}>
      {createdAt} - O usuário {createdBy} {typeMapper[log.type]}{' '}
      {id && `com o ID ${id}`}
      {selected && (
        <CardBody>
          {changes.map((change, idx) => (
            <p>
              <span style={{ color: '#e2ba29' }}>
                {(idx + 1).toString().padStart(2, '0')} -{' '}
              </span>{' '}
              {change}
              <br />
            </p>
          ))}
        </CardBody>
      )}
    </CardContainer>
  );
};

const removeInternalFields = (diff) => {
  delete diff['audit'];
  delete diff['newFile'];
  delete diff['document_period'];
  delete diff['__v'];
  delete diff['_id'];
  delete diff['system'];
  delete diff['id'];
  delete diff['companyId'];
};

const Audit = () => {
  const categoriesById = useReduxState(getCategoriesById);
  const audit = useReduxState(getAuditData);
  const clearFilters = useReduxAction(clearFilter);
  const filtersAmount = useReduxState(getFiltersAmount('auditFilters'));

  const fetchAudit = useReduxAction(fetchAuditData);

  const onClearFilters = () => {
    clearFilters({ key: 'auditFilters' });
    fetchAudit();
  };

  const getAfterDiff = ({ before, after }) => {
    const diff = {};
    const changes = [];
    if (after) {
      Object.entries(after).forEach(([key, value]) => {
        if (JSON.stringify(before?.[key]) != JSON.stringify(value)) {
          let beforeParsed = before?.[key];
          let afterParsed = value;
          if (key === 'categories') {
            beforeParsed = beforeParsed
              ?.map((v) => categoriesById[v]?.name)
              .join(', ');
            afterParsed = afterParsed
              ?.map((v) => categoriesById[v]?.name)
              .join(', ');
          }
          if (key.includes('period') || key.includes('discard')) {
            beforeParsed = beforeParsed
              ? toLocalDate(new Date(beforeParsed))
              : null;
            afterParsed = afterParsed
              ? toLocalDate(new Date(afterParsed))
              : null;
          }
          diff[key] = { before: beforeParsed, after: afterParsed };
        }
      });

      removeInternalFields(diff);
    }
    Object.entries(diff).forEach(([key, value]) => {
      let text = `O campo "${keyMapper[key]}" `;
      const isFile = key === 'files';
      if (isFile) {
        text = `O arquivo "${
          value.after?.[0].path || value.before?.[0].path
        }" `;
      }
      const { before, after } = value;

      if (before && after) {
        if (isFile) {
          text += `foi adicionado`;
        } else {
          text += `foi alterado de ${before} para ${after}`;
        }
      }

      if (before && !after) {
        text += `foi removido`;
      }

      if (after && !before) {
        text += `foi adicionado ${!isFile ? `com o valor ${after}` : ''}`;
      }

      changes.push(text);
    });

    return { diff, changes };
  };

  const logMapper = (values, type) => {
    const { before, after } = values;
    return getAfterDiff(values);
    if (DOCUMENT_TYPES.includes(type)) {
      // console.log(`~  paramsMappers: `, values);
    }
  };

  useEffect(() => {
    fetchAudit();
  }, []);

  const filterText =
    !!filtersAmount &&
    `${filtersAmount} ${
      filtersAmount > 1 ? 'filtros selecionados. ' : 'filtro selecionado. '
    }`;

  return (
    <Flex direction='column' justify='flex-start'>
      <Flex direction='column' style={{ margin: 15, marginBottom: 40 }}>
        <Filters filterKey={'auditFilters'} />
        {!!filtersAmount && (
          <FilterAmountContainer>
            {filterText}
            <CleanFilters onClick={onClearFilters}>
              Limpar filtros X
            </CleanFilters>
          </FilterAmountContainer>
        )}
      </Flex>
      {!!audit.length &&
        audit.map((log) => {
          const { changes } = logMapper(log.values, log.type);
          return <AuditCard log={log} changes={changes} />;
        })}
    </Flex>
  );
};

export default Audit;
