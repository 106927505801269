import ApplicationStateInjector from 'core/application-state-injector';
import { SearchApi } from 'core/api/search';

export class SearchService extends ApplicationStateInjector {
  constructor() {
    super();
    this.searchApi = new SearchApi(this.system, this.userId);
  }

  searchDocuments(globalFilter, advancedParams) {
    return this.searchApi.searchDocuments(globalFilter, advancedParams);
  }
}
