import { BaseApi } from 'core/api';

export class SystemInfoApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`auth/info/${this.system}`);
  }
  async getSystemInfo() {
    return await this.get();
  }

  async updateSystemInfo(requestParams) {
    return await this.put(requestParams);
  }

  async fetchInitialData() {
    this.setUrl('');
    return this.get();
  }

  async fetchAudit(params) {
    this.setUrl(`info/audit`);
    return this.post(params);
  }
}
