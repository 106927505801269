export const CategoryTypes = {
  FETCH_CATEGORIES: 'categories/fetchCategories',
  UPDATE_CATEGORY_SETTINGS: 'categories/updateCategorySettings',
  CREATE_CATEGORY: 'categories/createCategory',
  UPDATE_CATEGORY: 'categories/updateCategory',
  DELETE_CATEGORY: 'categories/deleteCategory',
};

export const GroupTypes = {
  FETCH_GROUPS: 'groups/fetchGroups',
  CREATE_GROUP: 'groups/createGroup',
  UPDATE_GROUP: 'groups/updateGroup',
  DELETE_GROUP: 'groups/deleteGroup',
};

export const PermissionTypes = {
  FETCH_PERMISSIONS: 'permissions/fetchPermissions',
};
