import { createSlice } from '@reduxjs/toolkit';
import { fetchDocuments, fetchSearch, fetchDocumentInfo, exportDocuments } from './thunks';

const documentsSlice = createSlice({
  name: 'documents',
  initialState: { data: [], loading: false, errors: null },
  reducers: {
    addDocument(state, action) {
      state.data = [action.payload, ...state.data];
    },

    updateDocument(state, action) {
      const arrayCopy = state.data.slice(0);
      arrayCopy[action.payload.matchIdx] = action.payload.value;
      state.data = arrayCopy;
    },
  },
  extraReducers: (builder) => {
    builder
      //Fetch
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      .addCase(fetchDocumentInfo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchDocumentInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocumentInfo.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //SEARCH
      .addCase(fetchSearch.fulfilled, (state, action) => {
        state.data = action.payload.documents;
        state.loading = false;
        state.errors = null;
      })
      .addCase(fetchSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearch.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })

      //EXPORT
      .addCase(exportDocuments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(exportDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportDocuments.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      });
  },
});

export const { addDocument, updateDocument } = documentsSlice.actions;
export const actions = documentsSlice.actions;

export default documentsSlice.reducer;
