import { Roles, RolesText } from 'common/enums/roles.enum';
import { useReduxState } from 'common/hooks/use-redux-state';
import { getUserGroupId, getUserRole } from 'common/redux/auth/selectors';
import { Select } from './Select';
import { getGroups } from 'common/redux/permissions/selectors';

export const GroupSelect = ({ customEmptyMessage, ...rest }) => {
  const groups = useReduxState(getGroups);
  const groupOptions = groups.map((group) => ({
    id: group.id,
    name: group.name,
  }));
  return (
    <Select
      selectOptions={groupOptions}
      customEmptyMessage={customEmptyMessage}
      {...rest}
    />
  );
};
