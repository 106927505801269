import { Collapse } from 'antd';
import { Navigation } from './Navigation';
import styled from 'styled-components';
import './style.css';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import { useEffect } from 'react';

export const faqData = [
  {
    header: 'O que é o Tâmia?',
    body: `É um software de Gerenciamento Eletrônico de Documentos com acesso
    fácil e simples, disponibilizado via web. É um Software as a
    Service (SaaS), sendo assim, você não precisa ter preocupações
    como instalar, manter ou atualizar o sistema e pode acessar de
    qualquer lugar.`,
  },
  {
    header: 'Por que devo usar o Tâmia?',
    body: `O Tâmia vai contribuir com a organização e com o acesso aos
    documentos da empresa, proporcionando agilidade no processo
    decisório e aumentando a produtividade.`,
  },
  {
    header: 'Para qual porte de empresa é o Tâmia?',
    body: ` O Tâmia é para todos! Atende a pessoas físicas, assim como
    empresas de pequeno e médio porte e também a grandes corporações.`,
  },
  {
    header: 'Como é o acesso ao Tâmia?',
    body: `O acesso é feito através de qualquer navegador de internet, em
    qualquer dispositivo e lugar.`,
  },
  {
    header: 'Como é feita a implantação?',
    body: ` O Tâmia veio para descomplicar. Sua interface é bem intuitiva,
      mesmo assim oferecemos implantação online por videoconferência.`,
  },
  {
    header: 'Quantos usuários podem utilizar?',
    body: ` A quantidade de usuários que terão acesso ao sistema será definida na contratação.`,
  },
  {
    header: 'Quais os tipos de arquivos posso salvar no Tâmia?',
    body: `Todos os formatos são aceitos (arquivos de imagem, texto, planilhas, entre outros).`,
  },
  {
    header:
      'É possível controlar os prazos de guarda e descarte dos meus documentos?',
    body: ` Sim, você pode incluir os prazos da sua Tabela de Descarte Documental no Tâmia.`,
  },
  {
    header: 'Como é feita a classificação dos documentos?',
    body: ` O Tâmia oferece uma funcionalidade inteligente que possibilita a construção de um Plano de Classificação de Documentos de forma natural. Contudo, se você já tiver um Plano próprio, ele poderá ser utilizado. `,
  },
  {
    header: 'Onde ficam salvos os documentos?',
    body: `Os seus documentos ficarão salvos no maior datacenter do mundo, tendo toda e segurança garantida pela AWS. Mas você também poderá optar por salvar no seu próprio Google Drive.
    `,
  },
  {
    header: 'Qualquer usuário pode ver tudo?',
    body: `Os usuários poderão ter acesso completo ou com restrições, de acordo com o perfil definido para cada um.`,
  },
  {
    header: 'Consigo extrair relatórios com a lista de documentos ?',
    body: `Sim, é possível extrair, de forma simples, relatórios com toda a lista de dados inseridos no Tâmia.`,
  },
  {
    header: 'Quanto custa o Tâmia?',
    body: `Nosso preço é calculado de acordo com a demanda e dependerá de fatores como o porte da empresa e o escopo do projeto. Não perca tempo, fale com um dos nossos consultores e veja que você não precisa pagar muito por isso.`,
  },
  {
    header: 'Como faço para contratar o Tâmia?',
    body: `Basta agendar uma apresentação com nossos consultores e definir as bases do projeto. Gerenciar seus documentos nunca foi tão fácil e seguro.
    `,
  },
];

export const FAQ = () => {
  const Dropdown = styled(Collapse)``;
  const { Panel } = Dropdown;
  const DropdownPanel = styled(Panel)`
    padding: 10px 0;
    border-bottom: 1px solid var(--tamia-hard-green) !important;

    p {
      font-weight: 100;
      font-size: 0.81rem;
    }
    .ant-collapse-arrow svg {
      color: var(--tamia-hard-green);
    }

    .ant-collapse-header {
      padding: 12px 40px 12px 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0 10px 20px 0px !important;
    }

    @media (min-width: 1024px) {
      .ant-collapse-content-box {
        padding: 0 280px 20px 0px !important;
      }
    }
  `;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, []);
  return (
    <>
      <Navigation />

      <section id='faq' style={{marginTop: 25}}>
        <div className='wrapper'>
          <header>
            <h1>Perguntas frequentes</h1>
          </header>
          <Dropdown ghost accordion expandIconPosition='end'>
            {faqData.map((data, idx) => (
              <DropdownPanel
                header={
                  <strong style={{ fontWeight: 500 }}>{data.header}</strong>
                }
                key={idx}
              >
                <p>{data.body}</p>
              </DropdownPanel>
            ))}
          </Dropdown>
          <div className='more-faq-container'>
            <Link to='/' id='more-faq'>
              Voltar
            </Link>
          </div>
        </div>
      </section>
      <Footer />
      <a id='backToTopButton' href='faq#home'>
        <svg
          width='40'
          height='40'
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='20' cy='20' r='20' fill='#9cdb37' />
          <path
            d='M20 27V13'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M13 20L20 13L27 20'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    </>
  );
};
