import { Input } from "antd";
import { MaskInput } from "components/Input/MaskInput";
import styled from "styled-components";
import { ThemedTextInput } from "./ThemedTextInput";

export const ThemedRegisterInput = styled(ThemedTextInput)`
  width: 100%;
  font-style: normal;
  border: none;
  background-color: inherit;
  border:  #b5b5b5 !important;

  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const ThemedRegisterInputMask = styled(MaskInput)`
  width: 100%;
  font-style: normal;
  border: none;
  background-color: inherit !important;

  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

