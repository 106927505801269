export const AuthTypes = {
  ATTEMPT_LOGIN: 'auth/attemptLogin',
  LOGIN_COMPANY: 'auth/loginCompany',

  SEND_RESET_EMAIL: 'auth/sendResetEmail',

  RESET_PASSWORD: 'auth/resetPassword',

  LOAD_APIS: 'auth/loadApis',

  BIND_PUSHER_EVENTS: 'pusher/bindPusherEvents',
};
