import React, { useEffect, useState } from 'react';
import RegisterUserModal from 'pages/usuarios/userManager';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import { ButtonConfigTable } from 'components/Buttons';
import {
  ButtonGroup,
  DeleteButton,
  Routes,
} from 'components/Tables/Categories/style';
import { Table } from 'antd';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa';
import { useReduxState } from 'common/hooks/use-redux-state';
import {
  getInvites,
  getIsManagingUsers,
  getUsers,
} from 'common/redux/users/selectors';
import { useReduxAction } from 'common/hooks/use-redux-action';
import { PathIndicator } from 'components/PathIndicator';

import { Roles, RolesText } from 'common/enums/roles.enum';
import { ConfirmDelete } from 'components/ConfirmDelete';
import {
  getCurrentCompanyId,
  getCurrentUserId,
  getUserRole,
} from 'common/redux/auth/selectors';
import { EditButton, ResendButton } from './style';
import { StyledTable } from 'components/Tables/style';
import { maskText } from 'common/utilities/maskText';
import { MdEdit } from 'react-icons/md';
import { setManagingUsers } from 'common/redux/users/reducers';
import GroupsManager from './GroupsManager';
import { deleteGroup, fetchGroups } from 'common/redux/permissions/thunks';
import { getGroups } from 'common/redux/permissions/selectors';

export default function GroupsTable() {
  const Column = Table;
  const text = <span>Usuários que tem acesso ao sistema </span>;
  const [tableData, setTableData] = useState([]);
  const users = useReduxState(getUsers);
  const invites = useReduxState(getInvites);
  const userRole = useReduxState(getUserRole);
  const currentUserId = useReduxState(getCurrentUserId);
  const companyId = useReduxState(getCurrentCompanyId);
  const isManagingUsers = useReduxState(getIsManagingUsers);
  const [managerData, setManagerData] = useState();
  const groups = useReduxState(getGroups);

  const removeGroup = useReduxAction(deleteGroup);

  const handleDelete = (id) => {
    removeGroup({ id });
  };

  const canDeleteRow = (record) =>
    record.roleId != Roles.OWNER &&
    record._id != currentUserId &&
    userRole !== Roles.USER;

  useEffect(() => {
    setTableData([
      ...invites.map((invite) => ({
        ...invite,
        isInvite: true,
        name: 'Convite Pendente',
      })),
      ...users,
    ]);
  }, [JSON.stringify(invites), JSON.stringify(users)]);

  return managerData ? (
    <GroupsManager currentRow={managerData} setManagerData={setManagerData} />
  ) : (
    <>
      <PathIndicator path={['Configurações', 'Controle de acesso']} helperText={text} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {userRole <= Roles.ADMIN && (
          <ButtonConfigTable onClick={() => setManagerData({})}>
            <AiOutlinePlus style={{ marginRight: '4px' }} size={20} /> Adicionar
          </ButtonConfigTable>
        )}
      </div>
      <div data-tour='roles'>
        <StyledTable
          style={{ padding: '15px' }}
          size='small'
          dataSource={groups}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} Perfis encontrados`,
            total: groups.length,
          }}
        >
          <Column title='Id' width={120} dataIndex='id' key='id' />
          <Column title='Nome' width={120} dataIndex='name' key='name' />

          {userRole <= Roles.ADMIN && (
            <Column
              width={50}
              title='Ações'
              align='center'
              key={'action' + Math.random()} //TODO: find better way to update table row
              render={(_, record) => (
                <ButtonGroup style={{ justifyContent: 'center' }}>
                  <ThemedTooltip placement='topRight' title='Atualizar controle de acesso'>
                    <EditButton>
                      <MdEdit
                        size={20}
                        onClick={() => setManagerData(record)}
                      />
                    </EditButton>
                  </ThemedTooltip>
                  {canDeleteRow(record) && (
                    <ConfirmDelete
                      placement='topLeft'
                      title={`Deseja excluir o controle de acesso "${record.name}"?`}
                      onConfirm={() => handleDelete(record._id)}
                    >
                      <DeleteButton variant='contained'>
                        <ThemedTooltip placement='bottom' title='Excluir'>
                          <FaTrashAlt size={15} />
                        </ThemedTooltip>
                      </DeleteButton>
                    </ConfirmDelete>
                  )}
                </ButtonGroup>
              )}
            />
          )}
        </StyledTable>
      </div>
    </>
  );
}
