import styled from "styled-components";

export const Header = styled.div`
  margin:2rem;
  font-size:1.75rem;
  font-weight:bold;
  border-bottom:1px solid #9cdb37;

`;
export const FilterAmountContainer = styled.span`
  position: absolute;
  margin-top: 45px;
  right: 23%;
  color: #4e4e4e;
`;

export const CleanFilters = styled.span`
  color: #da2118;
  :hover {
    cursor: pointer;
  }
`
export const Text =styled.p`
  margin-left:3rem;
  font-size:0.8rem;
  border-left: 2px solid #9cdb37;
  padding-left:1rem;
`

export const TextHeader =styled.p`
 color:#333333;
 font-size:1.8rem;
 margin:1rem;
`