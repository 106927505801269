import { getUrlOrigin } from 'common/utilities/url';
import { BaseApi } from 'core/api';

export class CloudApi extends BaseApi {
  constructor(system, userId, activeClouds) {
    super(system);
    this.system = system;
    this.userId = userId;
    this.setUrl(`cloud`);

    // TODO: change to dynamic handling when more cloud options are available
    this.setRequestHeaders({ cloud: activeClouds, system, userId });
  }
  async authenticateGoogleDrive() {
    this.setUrl(`auth/${this.system}/drive`);
    window.location.href = `${this.baseUrl}${
      this.url
    }?return_url=${getUrlOrigin()}`;
  }

  async revokeGoogleDrive() {
    this.setUrl(`auth/logout/google`);
    return this.get();
  }
  async getDriveDocumentLinks(documentId) {
    this.setUrl(`drive/file/${documentId}`);
    return await this.get();
  }

  async deleteDriveFile(documentId) {
    this.setUrl(`${this.url}/${documentId}`);
    return await this.deleteOne({
      headers: { driveToken: this.driveToken, userId: this.userId },
    });
  }

  async getGoogleDriveStatus() {
    this.setUrl('info/drive');
    return this.get({ headers: { cloud: ['googleDrive'] } });
  }

  async getDocumentLinks(documentId) {
    this.setUrl(`documents/${documentId}`);
    return this.get();
  }
}
