import styled from 'styled-components';

export const BlogContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: start;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 80px;
  gap: 20px;
  min-height: 100vh;
`;

export const TitlePage = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 150px 0 10px 50px;
  font-size: 40px;
  color: #212112;
  font-weight: 800;
  font-family: 'Gunar Thin';
  @media screen and (max-width: 1060px) {
    margin: 150px 0 20px;
    font-size: 35px;
  }
`;

export const SubTitlePage = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 10px 0 10px 50px;
  color: #9cdb37;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
    margin: 10px 50px;
    font-size: 17px;
  }
`;

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 200px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

export const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

export const BlogTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #737373;
  margin: 5px 10px 20px 10px;
  max-width: 300px;
`;

export const BlogDate = styled.span`
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
`;

export const BlogContent = styled.p`
  font-size: 16px;
  color: #555;
  margin: 15px;
  max-width: 300px;
`;

export const Wrapper = styled.div`
  margin: 50px 50px;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;

  @media screen and (max-width: 1060px) {
    flex-direction: column;
  }
`;

export const PostContainer = styled.div`
  flex: 0 0 75%;
`;

export const PostTitle = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 150px 50px 0px 50px;
  font-size: 30px;
  font-weight: 800;
  font-family: 'Gunar Thin';
  @media screen and (max-width: 1060px) {
    font-size: 20px;
  }
`;

export const PostImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin: 20px 5px;
`;

export const PostContent = styled.p`
  margin: 50px 0;
  font-size: 16px;
  padding: 10px;
`;

export const SidebarContainer = styled.div`
  flex: 0 0 25%;
  background-color: #d0fa94;
  padding: 20px;
  margin: 20px;
  height: 25rem;
  width: 10rem;

  @media screen and (max-width: 1060px) {
    display: none;
  }
`;

export const SidebarTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const SocialIcon = styled.a`
  color: #000;
  font-size: 20px;
`;
export const Text = styled.p`
  text-align: justify;
`;
export const SubTitle = styled.h2``;
export const Emphasis = styled.p`
  font-weight: 700;
  text-decoration: underline;
`;
export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21211a;
  font-size: 0.8rem;
  border: none;
  width: 6.25rem;
  margin: 0.62rem;
  min-width: 6.25rem;
  font-weight: 700;
  padding: 10px;
  color: #d0fa94;
  border-radius: 10px;
`;
export const ButtonContext = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;
