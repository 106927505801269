import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ bg }) => bg || 'unset'};
`;
export const Logo = styled.img`
  width: 50%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ bg }) => bg || 'unset'};
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: ${({ fg }) => fg || 'black'};
`;

export const Subtitle = styled.h2`
  width: 100%;
  text-align: center;
  padding: 5px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: ${({ size }) => size};
  color: ${({ fg }) => fg || 'black'};
`;

export const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  color: ${({ fg }) => fg || 'black'};
  padding: 10px;
`;

export const Separator = styled.div`
  width: 100%;
  padding: ${({ pd }) => pd || '1.5em'};
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Elastic = styled.div`
  * {
    width: 100%;
    padding: 0.5em;
  }
`;

export const AbsoluteCenter = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  * {
    flex: 1;
    align-items: center;
  }
`;

export const Image = ({ source, title, ...rest }) => {
  return (
    <Logo
      alt={title}
      src={
        source ||
        'https://via.placeholder.com/300x100/000000/FF9100/?text=INTEGRARQ'
      }
      {...rest}
    />
  );
};

export const ImageElastic = styled.div`
  img {
    max-width: 100%;
    height: 200px;
  }
`;
