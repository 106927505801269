import React, { useState } from 'react';
import { TextArea, Text, AntModal } from './style';
import { FeedbackService } from 'core/services/feedback.service';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { useReduxState } from 'common/hooks/use-redux-state';
import { createToast } from 'common/utilities/createToast';
import { getCurrentUser } from 'common/redux/auth/selectors';
import Logo from '../../../pages/Landing Page/assets/logoLoginBlack.png';
import { getSystemInfo } from 'common/redux/info/selectors';

export default ({ title, placeholder, okText, name, icon }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const currentUser = useReduxState(getCurrentUser);
  const companyInfo = useReduxState(getSystemInfo);

  const handleFeedbackSend = async () => {
    const feedbackService = new FeedbackService();

    const payload = {
      feedback: feedbackText,
      email: currentUser.email,
      username: currentUser.name,
      company: companyInfo.company.name,
    };
    try {
      if (feedbackText.length < 5) {
        throw new Error('Sua mensagem deve conter ao menos 5 caracteres');
      }
      await feedbackService.postFeedbackApi(payload);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Mensagem enviada com sucesso.',
      });
      setIsModalVisible(false);
      setFeedbackText('');
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div onClick={showModal}>
        {icon}
        {name}
      </div>
      <AntModal
        title={
          <img
            style={{ height: '3rem', width: '12rem' }}
            src={Logo}
            alt='Tâmia'
          />
        }
        visible={isModalVisible}
        okText={okText}
        cancelText='Cancelar'
        onOk={handleFeedbackSend}
        onCancel={handleCancel}
      >
        <Text>{title}</Text>
        <TextArea
          placeholder={placeholder}
          id='story'
          name='story'
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
      </AntModal>
    </>
  );
};
