import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { GlobalStyle } from './styles/global';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { persistor, store } from 'common/redux/store';
import { ApplicationStore } from 'core/application-store';
import { ModalRoot } from 'components/Modals/ModalRoot';
import { PusherConnection } from 'core/pusher/pusher';

const App = () => {
  const [locale, setLocale] = useState(ptBR);

  useEffect(() => {
    const data = ApplicationStore.shared.getUserData();

    return () => {
      PusherConnection.shared.unsubscribe(`channel-account-${data?.companyId}`);
      PusherConnection.shared.unsubscribe(`channel-user-${data?.userId}`);
    };
  }, [
    ApplicationStore.shared.isReady(),
    ApplicationStore.shared.getUserData(),
  ]);

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: '#9cdb37',
      },
    });
    ApplicationStore.shared.set(store);
    let customLocale = { ...ptBR };
    customLocale.Table.filterReset = 'Limpar';
    setLocale(customLocale);
  }, []);
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider locale={locale}>
            <BrowserRouter>
              <ModalRoot />
              <Routes />
            </BrowserRouter>
          </ConfigProvider>
        </PersistGate>
      </Provider>
      <GlobalStyle />
    </>
  );
};

export default App;
