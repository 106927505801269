export const DocumentsTypes = {
  GET_DOCUMENTS_REQUEST: "documents/getDocuments",

  GET_DOCUMENT_REQUEST: "documents/getDocument",

  CREATE_DOCUMENT_REQUEST: "documents/createDocument",

  EDIT_DOCUMENT_REQUEST: "documents/editDocument",

  DELETE_DOCUMENT_REQUEST: "documents/deleteDocument",

  EXPORT_DOCUMENTS_REQUEST:"documents/export",

  SEARCH_DOCUMENTS_REQUEST: "search/getSearch",
};
