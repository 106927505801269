import { createAsyncThunk } from '@reduxjs/toolkit';
import { Clouds } from 'common/enums/cloud.enum';
import { toastTypes } from 'common/enums/toastTypes.enum';
import { createToast } from 'common/utilities/createToast';
import { DocumentsService } from 'core/services/documents.service';
import { SearchService } from 'core/services/search.service';
import { DocumentsTypes } from './types';

export const fetchDocuments = createAsyncThunk(
  DocumentsTypes.GET_DOCUMENTS_REQUEST,
  async () => {
    try {
      const documentsService = new DocumentsService();
      const response = await documentsService.getDocuments();
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
    }
  }
);

export const fetchDocumentInfo = createAsyncThunk(
  DocumentsTypes.GET_DOCUMENT_REQUEST,
  async (documentId) => {
    try {
      const documentsService = new DocumentsService();
      const response = await documentsService.getDocument(documentId);
      return response.data;
    } catch (error) {
      createToast({ type: toastTypes.ERROR, message: error.message });
    }
  }
);

export const createDocument = createAsyncThunk(
  DocumentsTypes.CREATE_DOCUMENT_REQUEST,
  async (payload, thunkApi) => {
    const documentsService = new DocumentsService();

    try {
      await documentsService.createCloudDocument(payload);
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Documento salvo com sucesso!',
      });

      // const updateResponse = await thunkApi.dispatch(fetchSearch());
      // return updateResponse.payload;
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: error.message || 'Erro ao salvar documento',
      });
      thunkApi.rejectWithValue(error);
    }
  }
);

export const editDocument = createAsyncThunk(
  DocumentsTypes.EDIT_DOCUMENT_REQUEST,
  async (payload, thunkApi) => {
    const documentsService = new DocumentsService();
    const cloudState = thunkApi.getState().cloud;
    const { params } = payload;

    try {
      await documentsService.updateCloudDocument(params);
      // const updateResponse = await thunkApi.dispatch(fetchSearch());
      createToast({
        type: toastTypes.SUCCESS,
        message: 'Documento atualizado com sucesso!',
      });
      // return updateResponse.payload;
    } catch (error) {
      createToast({
        type: toastTypes.ERROR,
        message: error.message || 'Erro ao atualizar documento',
      });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  DocumentsTypes.DELETE_DOCUMENT_REQUEST,
  async (documentId, thunkApi) => {
    const documentsService = new DocumentsService();
    await documentsService.deleteDocument(documentId);
    createToast({
      type: toastTypes.SUCCESS,
      message: 'Documento excluído com sucesso!',
    });
    const updateResponse = await thunkApi.dispatch(fetchSearch());
    return updateResponse.payload;
  }
);

export const fetchSearch = createAsyncThunk(
  DocumentsTypes.SEARCH_DOCUMENTS_REQUEST,
  async (ignoreFilters = false, thunkApi) => {
    const searchService = new SearchService();
    let filters = {};
    if (!ignoreFilters) {
      filters = thunkApi.getState().filters;
    }
    const { globalFilter, advancedFilters } = filters;
    const response = await searchService.searchDocuments(
      globalFilter,
      advancedFilters
    );
    return response.data;
  }
);

export const exportDocuments = createAsyncThunk(
  DocumentsTypes.EXPORT_DOCUMENTS_REQUEST,
  async (documentIds, _) => {
    const documentsService = new DocumentsService();
    const response = await documentsService.exportDocuments(documentIds);
    console.log(`~  response: `, response)
    return response.data;
  }
);
