import { createAsyncThunk } from '@reduxjs/toolkit';
import { Clouds } from 'common/enums/cloud.enum';
import { CloudService } from 'core/services/cloud.service';
import { CloudTypes } from './types';

export const authenticateCloud = createAsyncThunk(
  CloudTypes.AUTHENTICATE_CLOUD,
  async () => {
    const cloudService = new CloudService();

    // TODO: change to dynamic handling when more cloud options are available
    const cloud = Clouds.GOOGLE;

    let response;

    if (cloud === Clouds.GOOGLE) {
      cloudService.authenticateGoogleDrive();
    }

    return {};
  }
);

export const fetchCloudStatus = createAsyncThunk(
  CloudTypes.FETCH_CLOUD_STATUS,
  async (_, thunkApi) => {
    const cloudService = new CloudService();

    // TODO: change to dynamic handling when more cloud options are available
    const cloud = Clouds.GOOGLE;

    let googleCloudStatus = null;
    let activeClouds = [];

    try {
      googleCloudStatus = await cloudService.getGoogleDriveStatus();
      if (googleCloudStatus.data) {
        activeClouds.push(Clouds.GOOGLE);
      }
    } catch (error) {
      // console.log(error);
      googleCloudStatus = null;
    }

    return { googleDrive: googleCloudStatus?.data, activeClouds };
  }
);

export const getDriveDocumentLinks = createAsyncThunk(
  CloudTypes.FETCH_DRIVE_DOCUMENT_LINKS,
  async (documentId) => {
    const cloudService = new CloudService();
    const { data: files } = await cloudService.getDriveDocumentLinks(
      documentId
    );
    const latestFile = files[0];

    return latestFile;
  }
);

export const getDocumentLinks = createAsyncThunk(
  CloudTypes.FETCH_DOCUMENT_LINKS,
  async (documentId) => {
    const cloudService = new CloudService();
    const { data: files } = await cloudService.getDocumentLinks(documentId);
    const latestFile = files[0];

    return latestFile;
  }
);

export const deleteDriveFileRequest = createAsyncThunk(
  CloudTypes.DELETE_DRIVE_FILE,
  async (documentId, thunkApi) => {
    try {
      const cloudService = new CloudService();
      const response = await cloudService.deleteDriveFile(documentId);
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const disconnectCloud = createAsyncThunk(
  CloudTypes.DISCONNECT_CLOUD,
  async (cloud, thunkApi) => {
    const cloudService = new CloudService();

    let response;

    if (cloud === Clouds.GOOGLE) {
      await cloudService.revokeGoogleDrive();
    }

    thunkApi.dispatch(fetchCloudStatus());

    return null;
  }
);
