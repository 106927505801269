import { useReduxState } from 'common/hooks/use-redux-state';
import { getUsers } from 'common/redux/users/selectors';
import { useState, useEffect } from 'react';
import { Select } from './Select';

export const CreatedBySelect = ({ ...rest }) => {
  const [users, setUsers] = useState([]);
  const getCreatedByUsers = useReduxState(getUsers);

  useEffect(() => {
    const fetchedUsers = getCreatedByUsers.map((user) => ({
      id: user.email,
      name: user.name,
    }));
    setUsers(fetchedUsers);
  }, [getCreatedByUsers]);

  return (
    <Select
      mode='multiple'
      placeholder='Selecione...'
      selectOptions={users}
      style={{
        width: '100%',
      }}
      allowClear={false}
      {...rest}
    />
  );
};
