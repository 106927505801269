import { createSelector } from '@reduxjs/toolkit';

const s = (state) => state.permissions;
const categoryState = (state) => s(state)?.categories || {};
const groupState = (state) => s(state)?.groups || {};

export const getCategorySettings = (state) =>
  categoryState(state).settings || [];

export const getCategories = (state) => categoryState(state).data || null;

export const getMergedCategories = (state) =>
  (
    categoryState(state).data && Object.values(categoryState(state).data)
  ).flat() || [];

export const getCategoryNames = (state) => {
  const categories = categoryState(state).settings || [];
  return categories.map((category) => category.name);
};

export const getCategoriesById = createSelector(
  getMergedCategories,
  (categories) => {
    const categoriesById = {};
    categories.forEach((category) => (categoriesById[category._id] = category));
    return categoriesById;
  }
);

// export const getCategoriesById = (state) => {
//   const categories = categoryState(state).data || [];
//
// };

export const getSelectedCategory = (state) => {
  const selectedCategoryId = categoryState(state).selectedCategory;
  const selectedCategory = categoryState(state).settings.find(
    (category) => category._id === selectedCategoryId
  );
  const categoryData = categoryState(state).data?.[selectedCategory?._id];

  return { ...selectedCategory, data: categoryData };
};

export const getGroups = (state) => groupState(state).data;
