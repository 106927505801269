import { createSlice } from '@reduxjs/toolkit';
import {
  authenticateCloud,
  fetchCloudStatus,
  getDocumentLinks,
  getDriveDocumentLinks,
} from './thunks';

const cloudSlice = createSlice({
  name: 'cloud',
  initialState: {
    status: {
      googleDrive: null,
    },
    driveFileLinks: {
      view: null,
      download: null,
    },
  },
  reducers: {
    updateDriveToken(state, action) {
      state.drive.token = action.payload;
    },
    updateDriveUser(state, action) {
      state.drive.info = action.payload;
    },
    clearDriveFileLinks(state, action) {
      state.fileLinks = {
        view: null,
        download: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //Auth
      .addCase(authenticateCloud.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
      })
      .addCase(authenticateCloud.pending, (state) => {
        state.loading = true;
      })
      .addCase(authenticateCloud.rejected, (state, action) => {
        state.errors = action.error;
        state.loading = false;
      })
      //Fetch Links
      .addCase(getDocumentLinks.fulfilled, (state, action) => {
        state.fileLinks = action.payload;
        state.loading = false;
        state.errors = null;
      })
      .addCase(getDocumentLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentLinks.rejected, (state, action) => {
        state.fileLinks = { view: null, download: null };
        state.errors = action.error;
        state.loading = false;
      })

      //Cloud status
      .addCase(fetchCloudStatus.fulfilled, (state, action) => {
        state.status = action.payload;
        state.loading = false;
        state.erros = null;
      })
      .addCase(fetchCloudStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCloudStatus.rejected, (state, action) => {
        state.status = action.payload;
        state.errors = action.error;
        state.loading = false;
      });
  },
});

export const { updateDriveToken, updateDriveUser, clearDriveFileLinks } =
  cloudSlice.actions;

export const actions = cloudSlice.actions;

export default cloudSlice.reducer;
