import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Popconfirm } from 'antd';
import { ThemedTextInput } from 'components/Themed/ThemedTextInput';
import { TagsSelect } from 'components/Input/TagsSelect';
import { MaskInput } from 'components/Input/MaskInput';
import { FaTrash } from 'react-icons/fa';
import { ThemedModal } from 'components/Themed/ThemedModal';
import { ThemedTooltip } from 'components/Themed/ThemedTooltip';
import { ThemedNumberInput } from 'components/Themed/ThemedNumberInput';
import ThemedButton from 'components/Themed/ThemedButton';
import { Flex } from 'components/Flex';
import { GroupSelect } from 'components/Input/GroupSelect';

const formRules = {
  group: [
    {
      required: true,
      message: 'É necessário inserir pelo menos um controle de acesso',
    },
  ],
  name: [
    {
      required: true,
      message: 'Por favor insira um nome',
    },
  ],
};

const textTooltips = 'Você pode adicionar mais de uma variação';

const CategoriesModal = ({
  currentRow,
  onCreate,
  onEdit,
  isOwner,
  variations,
  ...props
}) => {
  const isEditing = !!currentRow;
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const checkVariationDuplicates = () => {
    const newAliases = form.getFieldValue('alias_name');
    if (!newAliases) return false;
    const otherVariations =
      variations?.filter((v) => !new Set(currentRow.alias_name).has(v)) || [];

    const hasDuplicate = newAliases.some(
      (alias) =>
        !!otherVariations.find((variation) =>
          new RegExp(`^${variation}$`, 'i').test(alias)
        )
    );

    setShowConfirmation(hasDuplicate);
  };

  const handleClose = () => {
    props.onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    const payload = form.getFieldsValue();
    form.validateFields().then(() => {
      if (currentRow) {
        const id = currentRow._id;
        onEdit({ ...payload, _id: id });
      } else {
        onCreate(payload);
      }
      form.resetFields();
      handleClose();
    });
  };

  return (
    <ThemedModal
      footer={[
        <>
          <Flex justify='end'>
            <ThemedButton
              color='transparent'
              style={{ color: '#ff4747', borderColor: '#ff4747' }}
              onClick={handleClose}
            >
              Cancelar
            </ThemedButton>
            {showConfirmation ? (
              <Popconfirm
                title='Uma das variações utilizada já está presente em outra classe. Tem certeza que deseja salvar?'
                okText='Salvar'
                onConfirm={handleSubmit}
              >
                <ThemedButton color='#9cdb37'>Salvar</ThemedButton>
              </Popconfirm>
            ) : (
              <ThemedButton
                color='#9cdb37'
                form={props.title + currentRow?._id}
                key='submit'
                htmlType='submit'
              >
                Salvar
              </ThemedButton>
            )}
          </Flex>
        </>,
      ]}
      {...props}
    >
      <div style={{ marginBottom: 20 }}>
        <Form
          id={props.title + currentRow?._id}
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            code: props.suggestedId,
            ...currentRow,
            alias_name: currentRow?.alias_name || [],
          }}
          onValuesChange={checkVariationDuplicates}
          layout='vertical'
        >
          <Form.Item name='code' label='ID'>
            <ThemedNumberInput
              placeholder={'Digite aqui'}
              type='number'
              controls={false}
            />
          </Form.Item>

          <Form.Item name='name' label='Nome' rules={formRules.name}>
            <ThemedTextInput placeholder={'Digite aqui'} />
          </Form.Item>
          <Form.Item
            name='alias_name'
            label={
              <ThemedTooltip
                placement='top'
                showInformationIcon
                title={textTooltips}
              >
                Variações
              </ThemedTooltip>
            }
          >
            <TagsSelect
              menuItemSelectedIcon={<FaTrash size={12} />}
              placeholder={'Digite aqui'}
            />
          </Form.Item>

          {isOwner && (
            <Form.Item
              name='document'
              label='CPF/CNPJ'
              rules={formRules.cpfCnpj}
            >
              <MaskInput
                placeholder={'Digite aqui'}
                mask={['111.111.111-11', '11.111.111/1111-11']}
              />
            </Form.Item>
          )}

          {!isEditing && (
            <Form.Item
              name='groups'
              rules={formRules.group}
              label={
                <ThemedTooltip
                  placement='top'
                  showInformationIcon
                  title='Controle de acesso onde a categoria será automaticamente adicionada'
                >
                  Controle de acesso
                </ThemedTooltip>
              }
            >
              <GroupSelect mode='tags' placeholder={'Selecione'} />
            </Form.Item>
          )}
        </Form>
      </div>
    </ThemedModal>
  );
};

export default CategoriesModal;
