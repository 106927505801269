import { BaseApi } from 'core/api';

export class UserApi extends BaseApi {
  constructor(system) {
    super(system);
    this.system = system;
    this.setUrl(`users`);
  }

  async getUsers() {
    this.setUrl(`users/${this.system}`);
    return this.get();
  }

  async registerUser(requestParams) {
    const payload = {
      system: this.system,
      ...requestParams,
    };
    return this.post(payload);
  }

  async editUser(requestParams, userId) {
    const payload = {
      ...requestParams,
      system: this.system,
    };
    this.setUrl(`${this.url}/${userId}`);
    return this.put(payload);
  }

  async deleteUser(userId) {
    this.setUrl(`${this.url}/${userId}`);
    return this.delete();
  }

  async getInviteInfo(inviteId) {
    this.setUrl(`${this.url}/invites/${inviteId}`);
    return this.get();
  }

  async inviteUser(requestParams) {
    this.setUrl(`${this.url}/${this.system}/invites`);
    return this.post(requestParams);
  }

  async getInvites() {
    this.setUrl(`${this.url}/${this.system}/invites`);
    return this.get();
  }

  async deleteInvite(inviteId) {
    this.setUrl(`${this.url}/invites/${inviteId}`);
    return this.delete();
  }

  async resendInvite(inviteId) {
    this.setUrl(`${this.url}/invites/resend/${inviteId}`);
    return this.get();
  }

  async askDemonstration(requestParams) {
    this.setUrl(`${this.url}/invites/demonstration`);
    return this.post(requestParams);
  }
  async registerClient(requestParams) {
    this.setUrl(`${this.url}/mail/form`);
    return this.post(requestParams);
  }
  async registerUserNewsletter(requestParams) {
    this.setUrl(`${this.url}/mail/newsletter`);

    return this.post(requestParams);
  }
}
